import { AsyncActionStatus } from "../../shared/abstractStore";
import { useAsyncCallback } from "react-use-async-callback";
import { useAccountService } from "./useAccountService";

/**
 * Callback to Start the external login process for a provider.
 */
export function useStartExternalLoginCallback(): [(provider: string, returnUrl?: string) => Promise<void>, AsyncActionStatus] {
    const accountService = useAccountService();

    const [action, { isExecuting, errors }] = useAsyncCallback(async (provider: string, returnUrl?: string): Promise<void> => {
        accountService.startExternalLogin(provider, returnUrl);
    }, [accountService]);

    return [action, { isExecuting, errors }];
}
