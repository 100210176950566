import { AsyncActionStatus } from "../../shared/abstractStore";
import { useAsyncCallback } from "react-use-async-callback";
import { useAccountService } from "./useAccountService";

/**
 * Callback to change the email of another user as an administrator.
 */
export function useChangeAccountEmailCallback(): [(currentEmail: string, newEmail: string) => Promise<boolean>, AsyncActionStatus] {
    const accountService = useAccountService();

    const [action, { isExecuting, errors }] = useAsyncCallback(async (currentEmail: string, newEmail: string) => {
        return await accountService.changeAccountEmail({ currentEmail: currentEmail,  newEmail: newEmail });
    }, [accountService]);

    return [action, { isExecuting, errors }];
}
