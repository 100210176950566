import moment from "moment";
import { useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Badge, Col, FormGroup, Input, Label, ListGroupItem, Row } from "reactstrap";
import { useEditMemberChildrenTabViewModel } from "../../../../api/main/members/viewModels/useEditMemberChildrenTabViewModel";
import { Child } from "../../../../api/main/models/Child";
import { dayOfWeekDisplayName } from "../../../../api/main/models/constants/DayOfWeek";
import { PaymentState } from "../../../../api/main/models/constants/PaymentState";
import { AlertOnErrors } from "../../../../shared/alertOnErrors";
import { getAgeFromDateOfBirth } from "../../../../utilities/getAgeFromDateOfBirth";
import { CardsOrTable } from "../../../shared/cardsOrTable/CardsOrTable";
import { ISODateTimeInput } from "../../../shared/isoDateTimeInput/ISODateTimeInput";
import { LoadingIndicator } from "../../../shared/loadingIndicator/LoadingIndicator";
import { NoResultsFound } from "../../../shared/noResultsFound/NoResultsFound";
import { getPaymentStateBadge } from "../../../shared/Utilities/getPaymentStateBadge";

export interface ChildComponentProps {
    child: Child | null | undefined,
    childrenCount: number | null | undefined,
    childIndex: number | null | undefined,
}

export const ChildComponent = (props: ChildComponentProps) => {
    const {
        child,
        childrenCount,
        childIndex
    } = props;
    const { t } = useTranslation();

    // Load the data (ScheduledClassesChild)
    const {
        data: {
            items: _allItems,
            classLocations,
            terms,
            classTypes,
            payments
        },
        errors: loadErrors, isLoading,
    } = useEditMemberChildrenTabViewModel(child?.id);

    // Combine the loaded data so that each item includes its related details
    const allItems = useMemo(() => _allItems?.map(item => {
        const location = classLocations?.find(it => it.id === item.scheduledClass.classLocationId);
        const term = terms?.find(it => it.id === item.scheduledClass.termId);
        const classType = classTypes?.find(it => it.id === item.scheduledClass.classTypeId);
        const payment = payments?.find(it => it.scheduledClassId === item.scheduledClassId);

        return {
            ...item,
            location,
            term,
            classType,
            payment
        };
    }), [_allItems, classLocations, terms, classTypes, payments]);

    // Sorting
    const items = useMemo(() => {
        let ret = [...allItems ?? []];

        // Sort by the Term Name then by the JoinedClassDate
        ret = ret.sort((a, b) => {
            if (a.term?.name === b.term?.name) {
                return moment(a.joinedClassDate).isBefore(moment(b.joinedClassDate)) ? 1 : -1;
            } else {
                return (a.term?.name ?? '') > (b.term?.name ?? '') ? 1 : -1;
            }
        });

        return ret;
    }, [allItems]);

    // Render the UI
    //
    return (
        <>
            <AlertOnErrors errors={loadErrors} />

            <ListGroupItem>
                <ConditionalFragment showIf={!!childrenCount && childrenCount <= 1}>
                    <h4>{t('editMember.childrenTab.childDetails.heading', 'Bear cub details')}</h4>
                </ConditionalFragment>

                <ConditionalFragment showIf={!!childrenCount && childrenCount > 1}>
                    <h4>{t('editMember.childrenTab.childDetailsWithIncrementingNumber.heading', 'Bear cub {{number}} details', { number: childIndex })}</h4>
                </ConditionalFragment>

                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="firstName">{t('editMember.childrenTab.firstName', 'First name')}</Label>
                            <Input name="firstName" type="text" value={child?.firstName} disabled />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="lastName">{t('editMember.childrenTab.lastName', 'Last name')}</Label>
                            <Input name="lastName" type="text" value={child?.lastName} disabled />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="dateOfBirth">{t('editMember.childrenTab.dateOfBirth', 'Date of birth')}</Label>
                            <> </>
                            <Badge>
                                {t('common.ageForDateOfBirth', 'Age {{years}} years {{months}} months', getAgeFromDateOfBirth(child?.dateOfBirth))}
                            </Badge>
                            <ISODateTimeInput name="dateOfBirth" type="date" value={child?.dateOfBirth} disabled />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="registeredDate">{t('editMember.childrenTab.registeredDate', 'Date of registration')}</Label>
                            <ISODateTimeInput name="registeredDate" type="date" value={child?.registeredDate} disabled />
                        </FormGroup>
                    </Col>
                </Row>

                <h4>{t('editMember.childrenTab.classes.heading', 'Classes')}</h4>
                <ConditionalFragment showIf={allItems && !!allItems.length}>
                    <CardsOrTable
                        viewMode={"table"}
                        items={items}
                        tableHeadings={[
                            t('editMember.childrenTab.term.heading', 'Term'),
                            t('editMember.childrenTab.activity.heading', 'Activity'),
                            t('editMember.childrenTab.name.heading', 'Location'),
                            t('editMember.childrenTab.dayOfWeek.heading', 'Day of week'),
                            t('editMember.childrenTab.startTime.heading', 'Start time'),
                            t('editMember.childrenTab.endTime.heading', 'End time'),
                            t('editMember.childrenTab.waitingList.heading', 'Waiting list'),
                            t('editMember.childrenTab.joinedClass.heading', 'Joined class'),
                            t('editMember.childrenTab.firstLesson.heading', 'First lesson'),
                            t('editMember.childrenTab.lastLesson.heading', 'Last lesson'),
                            t('editMember.childrenTab.paymentState.heading', 'Payment state'),
                            t('editMember.childrenTab.totalAmount.heading', 'Total amount'),
                            t('editMember.childrenTab.paidAmount.heading', 'Paid amount'),
                        ]}
                        columns={[
                            // Term
                            (item, view) => {
                                return item.term?.name;
                            },

                            // Activity
                            (item, view) => {
                                return item.classType?.name;
                            },

                            // Location
                            (item, view) => {
                                return item.location?.name;
                            },

                            // Day of week
                            (item, view) => {
                                return dayOfWeekDisplayName(item?.scheduledClass?.dayOfWeek, t);
                            },

                            // Start time
                            (item, view) => {
                                return t('editChild.classesTab.timespan.start', '{{startTimeHours, 00}}:{{startTimeMinutes, 00}}', { startTimeHours: item.scheduledClass?.startTimeHours, startTimeMinutes: item.scheduledClass?.startTimeMinutes, });
                            },

                            // End time
                            (item, view) => {
                                return t('editChild.classesTab.timespan.end', '{{endTimeHours, 00}}:{{endTimeMinutes, 00}}', { endTimeHours: item.scheduledClass?.endTimeHours, endTimeMinutes: item.scheduledClass?.endTimeMinutes, });
                            },

                            // Is on WaitingList
                            (item, view) => {
                                return item.isOnWaitingList ? "Yes" : "No";
                            },

                            // Joined class date
                            (item, view) => {
                                return item.isOnWaitingList ? t('common.notApplicable', 'N/A') : t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.joinedClassDate) });
                            },

                            // First lesson date
                            (item, view) => {
                                return item.isOnWaitingList ? t('common.notApplicable', 'N/A') : t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.firstLessonDate) });
                            },

                            // Last lesson date
                            (item, view) => {
                                return item.isOnWaitingList ? t('common.notApplicable', 'N/A') : item.lastLessonDate ? t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.lastLessonDate) }) : '';
                            },

                            // Payment State
                            (item, view) => {
                                return getPaymentStateBadge(item?.paymentState as PaymentState, t);
                            },

                            // Total Amount
                            (item, view) => {
                                return t('common.euroCurrency', '€{{value}}', { value: item.payment?.totalAmountGross.toFixed(2) });
                            },

                            // Paid Amount
                            (item, view) => {
                                return t('common.euroCurrency', '€{{value}}', { value: item.payment?.paidAmountGross.toFixed(2) });
                            },

                            // Active
                            (item, view) => {
                                return !item.archived && <Badge color="success">{t('common.active', 'Active')}</Badge>;
                            },
                        ]}
                    />
                </ConditionalFragment>

                <ConditionalFragment showIf={allItems && !allItems.length}>
                    <NoResultsFound />
                </ConditionalFragment>

                <ConditionalFragment showIf={isLoading}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
            </ListGroupItem>
        </>
    );
};