import en from './en/translation.json';
//import fr from './fr/translation.json';

/**
 * Returns all locale translation resources for use by i18next .
 * 
 * This approach is great for apps with limited languages or text as it does away with any need to serve the translations from the backend (more PWA offline friendly).
 * If the number of translations in memory becomes a concern for your app, use i18next-http-backend or i18next-resoruces-to-backend instead of this approach.
 * 
 * NOTE FOR SPECIAL CHARACTERS if you are having problems with special characters in any of the files not showing when running, it is probably down to the file encoding 
 * of the json file.  To fix open the .json file in Windows Notepad, go to Save As... and select the file encoding (e.g. UTF-8) on save and overwrite the file.
 */
export const localeResources = {
    en: { translation: en },
    //fr: { translation: fr },
};
