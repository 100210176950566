import './htmlDisplay.scss';
import * as React from 'react';
import { processOEmbedElements } from '../fetch-oembed';

export interface HtmlDisplayProps {
    html: string,
    textOnly?: boolean,
}

/**
 * Component that shows Html created by the user in HtmlEditor.
 * @param props
 */
export const HtmlDisplay = (props: HtmlDisplayProps) => {
    const { html, textOnly } = props;

    const elementRef = React.useRef<HTMLDivElement>(null);

    // Initialize any oembed content.
    React.useEffect(() => {
        setTimeout(() => {
            if (!elementRef.current) {
                return;
            }

            processOEmbedElements(elementRef.current);
        }, 100);
    }, [html]);

    return (
        <div ref={elementRef} className={`html-display ${textOnly? 'html-display-text-only': ''}`} dangerouslySetInnerHTML={{ __html: html ?? '' }}>
        </div>
        );
};