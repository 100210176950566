import { useEffect, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Col, FormGroup, Input, Nav, NavItem, NavLink, Row, } from "reactstrap";
import { useConsumeMemberChildrenViewModel } from "../../../api/main/members/viewModels/useConsumeMemberChildrenViewModel";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { useReplaceSearchParamsEffect, useSearchParams } from "../../../shared/useURLSearchParams";
import { Banner } from "../../shared/banner/Banner";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../../shared/stickyToolbar/StickyToolbar";
import { AbsencesTab } from "./absencesTab/AbsencesTab";
import { ClassesTab } from "./classesTab/classesTab";
import { CourtesyClassCreditsTab } from "./courtesyClassCredits/CourtesyClassCreditsTab";
import { WaitingListsTab } from "./waitingListsTab/waitingListsTab";
import moment from "moment";
import { useCurrentTerm } from "../../../api/main/terms/useCurrentTerm";

/**
 * Component for Members to view a Child details
 */
export const MembersChildTab = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined; }>();

    // Load the current term
    const {
        data: {
            model: currentTerm
        }, isLoading: isLoadingCurrentTerm, errors: currentTermLoadErrors
    } = useCurrentTerm();

    // Selected term, defaulted to the first term in terms
    const [selectedTermId, setSelectedTermId] = useState<string>('');

    // Update selectedTermId when currentTerm loads
    useEffect(() => {
        if (currentTerm?.id) {
            setSelectedTermId(currentTerm.id);
        }
    }, [currentTerm]);

    // Load the data
    const {
        data: {
            model: storeModel,
            terms: _terms
        },
        isLoading: _isLoading, errors: loadErrors,
    } = useConsumeMemberChildrenViewModel(id);
    const isLoading = _isLoading || isLoadingCurrentTerm;
    const terms = useMemo(() => {
        if (!_terms) {
            return;
        };

        return _terms.filter(term => moment(term.endDate) >= moment());
    }, [_terms]);

    // Tab navigation
    // Manage the tab being displayed.  If we have tab= on the query string, default to it, otherwise default to the main tab.
    const { tab: tabParam } = useSearchParams();
    // Manage the tab being displayed.
    const [activeTab, setActiveTab] = useState<string>(tabParam as any || 'main');

    // Keep the URL up to date with the currentTab
    useReplaceSearchParamsEffect({ tab: activeTab === 'main' ? '' : activeTab });

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>{t('common.fullName', '{{firstName}} {{lastName}}', { firstName: storeModel?.firstName, lastName: storeModel?.lastName })}</h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors
                    errors={[
                        loadErrors, currentTermLoadErrors
                    ]}
                />

                <Row>
                    <Col >
                        {/* Active tab navigation */}
                        <Nav tabs style={{ marginBottom: '1rem', paddingBottom: '.5rem' }}>
                            <NavItem>
                                <NavLink active={activeTab === "classes"} onClick={() => setActiveTab('classes')}>
                                    {t('membersChildTab.nav.myClasses', "My Classes")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === "waitingLists"} onClick={() => setActiveTab('waitingLists')}>
                                    {t('membersChildTab.nav.waitlists', "Waitlists")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === "courtesyClassCredits"} onClick={() => setActiveTab('courtesyClassCredits')}>
                                    {t('membersChildTab.nav.courtesyClassCredits', "Courtesy Classes / Credits")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 'childAbsences'} onClick={() => setActiveTab('childAbsences')}>
                                    {t('membersChildTab.nav.absences', "Absences")}
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <Row>
                            <Col></Col>

                            <Col xs={12} >
                                <FormGroup>
                                    <Input type="select" value={selectedTermId ?? ''} onChange={e => setSelectedTermId(e.currentTarget.value)}>
                                        {terms?.map(term => (
                                            <option value={term.id}>{term.name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>

                            <Col></Col>
                        </Row>

                        {/* Active tab */}
                        {
                            activeTab === 'classes' ? (
                                <ClassesTab
                                    model={storeModel}
                                    selectedTermId={selectedTermId}
                                    terms={terms}
                                />
                            ) : activeTab === 'waitingLists' ? (
                                <WaitingListsTab
                                    model={storeModel}
                                />
                            ) : activeTab === 'courtesyClassCredits' ? (
                                <CourtesyClassCreditsTab
                                    model={storeModel}
                                    selectedTermId={selectedTermId}
                                    terms={terms}
                                />
                            ) : activeTab === 'childAbsences' ? (
                                <AbsencesTab
                                    model={storeModel}
                                    selectedTermId={selectedTermId}
                                    terms={terms}
                                />
                            ) : null
                        }
                    </Col>
                </Row>
            </MainContainer>
        </>
    );
};