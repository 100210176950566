import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadOptions, AsyncLoadResult } from '../../../../shared/abstractStore';
import { PaymentCheckoutSupportingDataQuery, PaymentCheckoutSupportingDataQueryVariables } from '../../generated/graphql';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { Guid } from 'guid-string';
import { profileFieldsFragment } from '../../models/Profile';
import { scheduledClassChildFieldsFragment } from '../../models/ScheduledClassChild';

/**
 * Get all model date required for the EditTerm component from the api.
 * @param options
 */
export function usePaymentCheckoutSupportingData(memberId: string | undefined | null, childId: string | undefined | null, scheduledClassId: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<PaymentCheckoutSupportingDataQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, }] = useLazyQuery<PaymentCheckoutSupportingDataQuery, PaymentCheckoutSupportingDataQueryVariables>(
        gql`
        query paymentCheckoutSupportingDataQuery ($memberId: ID!, $childId: ID!, $scheduledClassId: ID!) {
            model: profile (id: $memberId) {
                ...profileFields
            }

            scheduledClassChildren (childId: $childId, scheduledClassId: $scheduledClassId, isOnWaitingList: false) {
                ...scheduledClassChildFields

                realFirstLessonDate
                realLastLessonDate
            }
        }

        ${profileFieldsFragment}
        ${scheduledClassChildFieldsFragment}
        `,
        {

            variables: {
                memberId: memberId ?? Guid.empty,
                childId: childId ?? Guid.empty,
                scheduledClassId: scheduledClassId ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}
