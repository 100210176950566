import { RouteEntry } from "../../shared/routes";
import { ChangeEmail } from "./ChangeEmail";
import { ChangePassword } from "./ChangePassword";
import { Profile } from "./Profile";
import { ProfileDetails } from "./ProfileDetails";

export const profileRoutes: Array<RouteEntry> = [
    {
        path: '/profile', element: <Profile />, authorize: true,
        childRoutes: [
            { path: 'details', element: <ProfileDetails />, authorize: true },
            { path: 'change-email', element: <ChangeEmail />, authorize: true },
            { path: 'change-password', element: <ChangePassword />, authorize: true },
        ],
    },
//];

//export const profileTabRoutes: Array<RouteEntry> = [
//    { path: '/profile', element: <Navigate to="/profile/details" />, authorize: true },
    //{ path: '/profile/details', element: <ProfileDetails />, authorize: true },
    //{ path: '/profile/changeEmail', element: <ChangeEmail />, authorize: true },
    //{ path: '/profile/changePassword', element: <ChangePassword />, authorize: true },
];

