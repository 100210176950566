import * as React from 'react';
import { atom, useRecoilState } from 'recoil';
import { Profile } from '../../api/main/models/Profile';

export interface CurrentUserProfileCache {
    isLoading: boolean,
    isAuthenticated: boolean,
    profile: Profile | null,
}

export const currentUserProfileCacheState = atom<CurrentUserProfileCache>({
    key: 'currentUserProfileCacheState',
    default: { isAuthenticated: false, profile: null, isLoading: false },
    //effects_UNSTABLE: [persistAtom],
});

/**
 * Hook that provides access to the current user's profile cached in global state.
 */
export function useCurrentUserProfilesCache(): [CurrentUserProfileCache, React.Dispatch<React.SetStateAction<CurrentUserProfileCache>>] {
    const [value, setValue] = useRecoilState(currentUserProfileCacheState);
    return [value, setValue];
}