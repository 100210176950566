import { AsyncActionStatus } from "../../shared/abstractStore";
import { useAsyncCallback } from "react-use-async-callback";
import { useAccountService } from "./useAccountService";

/**
 * Callback to change the password of the current user..
 */
export function useChangePasswordCallback(): [(currentPassword: string, newPassword: string) => Promise<boolean>, AsyncActionStatus] {
    const accountService = useAccountService();

    const [action, { isExecuting, errors }] = useAsyncCallback(async (currentPassword: string, newPassword: string) => {
        return await accountService.changePassword({ currentPassword: currentPassword, newPassword: newPassword  });
    }, [accountService]);

    return [action, { isExecuting, errors }];
}
