import '../OEmbedProvider';
import { OEmbedProvider } from '../OEmbedProvider';

/**
 * YouTube oembed provider.
 */
export const VimeoProvider: OEmbedProvider = {
    name: 'vimeo',
    type: 'video',
    format: 'json',
    api: 'https://vimeo.com/api/oembed.json',
    urls: [
        "www\\.vimeo\\.com\\/groups\\/.*\\/videos\\/.*",
        "www\\.vimeo\\.com\\/.*",
        "vimeo\\.com\\/groups\\/.*\\/videos\\/.*",
        "vimeo\\.com\\/.*"
    ],
};
