import '../OEmbedProvider';
import { OEmbedProvider } from '../OEmbedProvider';

/**
 * Twitter oembed provider.
 */
export const TwitterProvider: OEmbedProvider = {
    name: 'youtube',
    type: 'video',
    format: 'json',
    api: 'https://TODO',
    urls: [
        "TODO",
    ],
};
