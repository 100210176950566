import '../OEmbedProvider';
import { OEmbedProvider } from '../OEmbedProvider';

/**
 * DailyMotion oembed provider.
 */
export const DailyMotionProvider: OEmbedProvider = {
    name: 'dailymotion',
    type: 'video',
    format: 'jsonp',
    api: 'https://www.dailymotion.com/services/oembed',
    urls: [
        "dailymotion\\.com/.+",
    ],
};
