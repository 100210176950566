import gql from "graphql-tag";
import { RoleGroupFieldsFragment } from "../generated/graphql";

export const roleGroupFieldsFragment = gql`
    fragment roleGroupFields on RoleGroup {
        id
        name
    }
`;


export type RoleGroup = Omit<RoleGroupFieldsFragment, '__typename'>;