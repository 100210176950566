import { useTranslation } from 'react-i18next';
import { MainContainer } from '../../shared/mainContainer/MainContainer';
import { Banner } from '../../shared/banner/Banner';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { ConditionalFragment } from 'react-conditionalfragment';
import { LoadingIndicator } from '../../shared/loadingIndicator/LoadingIndicator';
import { Alert, Button, Col, Row } from 'reactstrap';
import { StickyToolbar } from '../../shared/stickyToolbar/StickyToolbar';
import { usePaymentCheckoutViewModel } from '../../../api/main/payments/viewModel/usePaymentCheckoutViewModel';
import { PaymentCheckoutDetails } from './PaymentCheckoutDetails';
import { PaymentState } from '../../../api/main/models/constants/PaymentState';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useAsyncCallback } from 'react-use-async-callback';
import { generatePdfAsBlob } from '../../../utilities/generatePdfAsBlob';
import { PaymentCheckoutReceiptPDF } from './PaymentCheckoutReceiptPDF';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileSaver from 'file-saver';
import { usePaymentCheckoutSupportingData } from '../../../api/main/payments/viewModel/usePaymentCheckoutSupportingData';

/**
 * Receipt for a payment that previously went through the checkout.
 * @returns
 */
export const PaymentCheckoutReceipt = () => {
    const { id } = useParams<{ id: string | undefined; }>();
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Load the payment.
    const {
        data: modelData,
        isLoading: _isLoading, errors: loadErrors,
    } = usePaymentCheckoutViewModel(id);
    const model = modelData?.model;

    // Load the supporting data - Member details for PDF receipts
    const {
        data: {
            model: member,
            scheduledClassChildren
        }, isLoading: isLoadingSupportingData, errors: supportingDataLoadErrors
    } = usePaymentCheckoutSupportingData(model?.memberId, model?.childId, model?.scheduledClassId);
    const isLoading = _isLoading || isLoadingSupportingData;
  
    const scheduledClassChild = useMemo(() => {
        if (!scheduledClassChildren) {
            return null;
        };

        return scheduledClassChildren[0];
    }, [scheduledClassChildren]);

    // Generate a PDF as a blob.
    const generatePdf = useCallback(async (): Promise<Blob> => {
        let blob = await generatePdfAsBlob(PaymentCheckoutReceiptPDF({
            model: model,
            member: member,
            termStartDate: t('common.dateLong', '{{date, Do MMMM YYYY}}', { date: moment(scheduledClassChild ? scheduledClassChild.realFirstLessonDate : model?.term?.startDate) }),
            termEndDate: t('common.dateLong', '{{date, Do MMMM YYYY}}', { date: moment(scheduledClassChild ? scheduledClassChild.realLastLessonDate : model?.term?.endDate) }),
            giftVouchers: model?.giftVouchers,
        }));

        return blob;
    }, [model, member, t, scheduledClassChild]);

    // Generate the PDF and open it in a new window for printing.
    const [generateAndOpenPdf, { isExecuting: isGeneratingPdfForOpen }] = useAsyncCallback(async () => {
        let blob = await generatePdf();
        let url = URL.createObjectURL(blob);
        window.open(url);
    }, [generatePdf]);

    // Generate the PDF and download it.
    const [generateAndDownloadPdf, { isExecuting: isGeneratingPdfForDownload }] = useAsyncCallback(async () => {
        let blob = await generatePdf();
        

        FileSaver.saveAs(blob, `Happy Cubs ${(model && model?.term?.name) || ''} receipt.pdf`);
    }, [generatePdf, model]);

    // UI
    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('paymentCheckoutReceipt.title.paid', 'Receipt')}
                            </h1>
                            <h3>{model?.name}</h3>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, supportingDataLoadErrors]} />

                <PaymentCheckoutDetails data={modelData} scheduledClassChildId={scheduledClassChild?.id} />

                {
                    !model ? null
                        : model?.paymentState === PaymentState.Paid ? (
                            <Alert color="success">
                                {t('paymentCheckoutReceipt.paid.alert.text', 'You paid this on {{date, DD/MM/YYYY}}.', { date: moment(model?.paidDate) })}
                            </Alert>
                        ) : (
                            <Alert color="danger">
                                {t('paymentCheckoutReceipt.outstanding.alert.text', 'This payment is still outstanding.')}
                            </Alert>
                        )
                }

                <Row>
                    <Col></Col>
                    <Col xs="auto">
                        <ButtonAsync color="primary" outline onClick={generateAndOpenPdf} isExecuting={isGeneratingPdfForOpen}
                            executingChildren={<><FontAwesomeIcon icon="spinner" spin /> {t('common.generatingPdf', 'Generating PDF...')}</>}>
                            <FontAwesomeIcon icon="print" /> {t('common.print', 'Print')}
                        </ButtonAsync>
                        <> </>
                        <ButtonAsync color="primary" outline onClick={generateAndDownloadPdf} isExecuting={isGeneratingPdfForDownload}
                            executingChildren={<><FontAwesomeIcon icon="spinner" spin /> {t('common.generatingPdf', 'Generating PDF...')}</>}>
                            <FontAwesomeIcon icon="file-download" /> {t('common.downloadPdf', 'Download PDF')}
                        </ButtonAsync>

                        <> </>
                        <Button color="primary" onClick={() => navigate(-1)}>
                            {t('common.back', 'Back')}
                        </Button>
                    </Col>
                </Row>
            </MainContainer>
        </>
    );
};