import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { FormGroup, Label, ModalBody, ModalHeader, ModalFooter, Button, Spinner, CardTitle, Input } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { queryMessageDefaultValues } from "../../api/main/models/QueryMessage";
import { useCurrentUserProfile } from "../../api/main/profiles/useCurrentUserProfile";
import { useSaveQueryMessageAndEmailAdminMutation } from "../../api/main/queryMessages/useSaveQueryMessageAndEmailAdminMutation";
import { useQueryMessageTypes } from "../../api/main/queryMessageTypes/useQueryMessageTypes";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useChanges } from "../../shared/useChanges";

export interface MessageModalProps {
    toggle: () => void,
    refreshMessages?: () => Promise<void>;
    isChangeClass?: boolean;
}

/**
 * ComponentModal to allow members to send QueryMessages
 */
export const MessageModal = (props: MessageModalProps) => {
    const {
        toggle,
        refreshMessages,
        isChangeClass = false
    } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Load the current user to grab the MemberId (model.id)
    const {
        data: {
            model: userModel
        }, isLoading: _isLoading, errors: loadErrors
    } = useCurrentUserProfile();

    // Load the queryMessageTypes
    const {
        data: {
            items: queryMessageTypes
        }, isLoading: isLoadingSupportingData, errors: supportingDataLoadErrors
    } = useQueryMessageTypes();
    const isLoading = _isLoading || isLoadingSupportingData;

    // Model (QueryMessage)
    const { model, change, changes } = useChanges({} as any, { ...queryMessageDefaultValues(), memberId: userModel?.id, submittedDate: moment(), });
    const [saveQueryMessageAndEmailAdmin] = useSaveQueryMessageAndEmailAdminMutation();

    useMemo(() => {
        if (!queryMessageTypes || !isChangeClass) return;

        const queryMessageTypeId = queryMessageTypes?.find(it => it.name === 'Change class')?.id;

        const message = `We are looking to change class.`
        
        change({ queryMessageTypeId, contentsHtml: message });
    }, [queryMessageTypes, isChangeClass, change]);

    // QueryMessage Validation
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            subject: () => !model?.queryMessageTypeId ? t('messageModal.errors.subjectRequired', 'Subject is required') : '',
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Save the QueryMessage
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        // Set the changes
        changes.isReplyFromStaff = false;

        // Save the main model
        await saveQueryMessageAndEmailAdmin(model.id, { ...changes }, true);

        // Close the modal
        toggle();
        navigate(`/my/messages`);

        // Refresh the messages list
        if (refreshMessages) {
            refreshMessages();
        }
    }, [model, changes,]);

    // Render the UI
    //
    return (
        <>
            <AlertOnErrors
                errors={[
                    loadErrors,
                    supportingDataLoadErrors,
                    saveFormErrors
                ]}
            />

            <ModalHeader toggle={toggle}>
                <CardTitle tag="h1">
                    {t('messageModal.modal.message', 'Message')}
                </CardTitle>
            </ModalHeader>

            <ModalBody>
                <FormGroup>
                    <Label htmlFor="queryMessageType">{t('common.subject', 'Subject')}</Label>
                    <ValidatedInput name="queryMessageType" type="select" value={model?.queryMessageTypeId ?? ''} onChange={(e) => change({ queryMessageTypeId: e.currentTarget.value })} onBlur={e => validate('subject')} validationErrors={validationErrors['subject']}>
                        <option value="">{t('common.pleaseSelectQueryMessageType', '(Please select a subject)')}</option>
                        {queryMessageTypes?.map(queryMessageType => (
                            <option value={queryMessageType.id} >{queryMessageType.name}</option>
                        ))}
                    </ValidatedInput>
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="contentsHtml" style={{
                        fontSize: '2rem', color: '#fada00', margin: '1rem 0'
                    }}>{t('common.yourMessage', 'Your message')}</Label>
                    <Input type="textarea" rows={8} value={model?.contentsHtml ?? ''} onChange={(e) => change({ contentsHtml: e.currentTarget.value })} />
                </FormGroup>
            </ModalBody>

            <ModalFooter>
                <ConditionalFragment showIf={!isLoading}>
                    <div className="text-center">
                        <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                            executingChildren={<><Spinner size="sm" /> {t('common.sendingMessage', 'Sending message...')}</>}>
                            <FontAwesomeIcon icon="save" />
                            <> </>
                            {t('messageModal.sendMessage.button', 'Send message')}
                        </ButtonAsync>
                        <> </>
                        <Button color="primary" outline onClick={e => change({ contentsHtml: '' })}>
                            {t('common.clear', 'Clear')}
                        </Button>
                        <> </>
                        <Button color="primary" outline onClick={e => toggle()}>
                            {t('common.close', 'Close')}
                        </Button>
                    </div>
                </ConditionalFragment>
            </ModalFooter>
        </>
    );
};