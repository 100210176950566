import i18n from 'i18next';
import { PasswordValidation } from "./PasswordValidation";

/**
 * Generates a plain text description of the password rules for passwordValidation.
 * @param passwordValidation
 */
export function generatePasswordRulesDescrption(passwordValidation: PasswordValidation): string {
    const { valid, errors: passwordErrors } = passwordValidation.check('');
    if (valid) {
        return '';
    }

    return i18n.t('generatePasswordRulesDescription.passwordRequirements', 'Your password {{requirements}}.', { requirements: passwordErrors.join(', ')});
}
