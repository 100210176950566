import { ReactNode, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthorizeRouteElementWrapper } from '../../../shared/authorizeRoute';
import { RouteEntry } from '../../../shared/routes';

export interface AppRoutesProps {
    routes: Array<RouteEntry>,
}

/**
 * Routes for the app.
 * @param props
 */
export const AppRoutes = (props: AppRoutesProps) => {
    const { routes } = props;

    var elements = useMemo(() => createRoutes(routes), [routes]);

    return (
        <Routes>
            {elements}
        </Routes>
        );
};

/**
 * Helper function for AppRoutes that creates the Routes and Route components needed and can be used in a recursive way.
 * @param routes
 */
function createRoutes(routes: Array<RouteEntry>): ReactNode {
    const ret = routes.map((route, index) => {
        let element = route.element;

        // If the route requires authorization, wrap the element in a wrapper that checks authorisation.
        if (route.authorize || route.requireRole) {
            element = (<AuthorizeRouteElementWrapper element={route.element} requireRole={route.requireRole} />);
        }

        // Render the route config.
        return (
            <Route key={index} path={route.path} element={element}>
                {
                    !!route.childRoutes ? createRoutes(route.childRoutes) : null
                }
            </Route>
        )
    }
    );

    return ret;
}