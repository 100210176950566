import '../OEmbedProvider';
import { OEmbedProvider } from '../OEmbedProvider';

/**
 * Instagram oembed provider.
 */
export const InstagramProvider: OEmbedProvider = {
    name: 'instagram',
    type: 'photo',
    format: 'jsonp',
    api: 'https://api.instagram.com/oembed',
    urls: [
        "instagr\\.?am(\\.com)?/.+",
    ],
};
