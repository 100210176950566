import { useSidebarState } from '../../../../globalState/sidebarState/useSidebarState';
import { useEffect } from 'react';

/**
 * Component that shows the sidebar because its recommended for further navigation.
 */
export const SidebarRequired = () => {
    // Auto toggle the state of the sidebar when we render/unrender.
    const [, setSidebarState] = useSidebarState();
    useEffect(() => {
        setSidebarState(prevState => ({ ...prevState, visible: true, suggested: true, }));
        return () => setSidebarState(prevState => ({ ...prevState, visible: false, suggested: false, }));
    }, [setSidebarState])

    // Needs no on screen rendering.
    return (
        <></>
    );
};
