import { Button, Row, Col, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle, Alert } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { LoadingIndicator } from '../../shared/loadingIndicator/LoadingIndicator';
import { Waypoint } from 'react-waypoint';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../../shared/searchInput/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../../shared/mainContainer/MainContainer';
import { NoResultsFound } from '../../shared/noResultsFound/NoResultsFound';
import { StickyToolbar } from '../../shared/stickyToolbar/StickyToolbar';
import { useNavigate } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../../shared/banner/Banner';
import { useToggleStateArray } from 'use-toggle-state';
import { CardsOrTable } from '../../shared/cardsOrTable/CardsOrTable';
import { usePreferredListViewMode } from '../../../globalState/preferredListViewMode/usePreferredListViewMode';
import { CardsOrTableToggle } from '../../shared/cardsOrTable/CardsOrTableToggle';
import { useEffect, useMemo, useState } from 'react';
import { usePayments } from '../../../api/main/payments/usePayments';
import { PaymentState, paymentStateDisplayName } from '../../../api/main/models/constants/PaymentState';
import { usePaymentsListSupportingData } from '../../../api/main/payments/viewModel/usePaymentsListSupportingData';
import moment from 'moment';
import { getPaymentStateBadge } from '../../shared/Utilities/getPaymentStateBadge';
import { Payment } from '../../../api/main/models/Payment';
import { useSendPaymentAvailableEmailMutation } from '../../../api/main/payments/useSendPaymentAvailableEmailMutation';
import { useAsyncCallback } from 'react-use-async-callback';
import { Profile } from '../../../api/main/models/Profile';
import { useLocalStorage } from '../../../shared/useLocalStorage';
import { ClassScheduleFilterState } from '../../scheduledClass/schedule/ClassSchedule';
import { useCurrentTerm } from '../../../api/main/terms/useCurrentTerm';
import { TermSelector } from '../../shared/termSelector/TermSelector';

export interface PaymentsListProps {
    title?: string,
    filter?: (item: Payment) => boolean,
    sort?: (items: Array<any>) => Array<any>, // Don't want to get too complex with the types here.
    showPaymentDate?: boolean,
}

/**
 * List of Payments.
 */
export const PaymentsList = (props: PaymentsListProps) => {
    const {
        title,
        filter: filterProp,
        sort: sortProp,
        showPaymentDate = false,
    } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();

    // Load data.
    const { data: { items: _allItems, }, isLoading: isLoadingData, errors: loadingErrors, fetchMore, hasMore } = usePayments({ pageSize: undefined, });
    const {
        data: {
            children,
            profiles,
            terms,
        },
        isLoading: isLoadingSupportingData,
        errors: loadSupportingDataErrors,
    } = usePaymentsListSupportingData();
    // Load the current term
    const {
        data: {
            model: currentTerm
        }, isLoading: isLoadingCurrentTerm, errors: currentTermLoadErrors
    } = useCurrentTerm();
    const isLoading = isLoadingData || isLoadingCurrentTerm || isLoadingSupportingData;

    // Combine the data into a single list of models.
    const allItems = useMemo(() => _allItems?.map(item => {
        return {
            ...item,
            child: children?.find(it => it.id === item.childId),
            member: profiles?.find(it => it.id === item.memberId),
            term: terms?.find(it => it.id === item.termId),
        };
    }), [_allItems, children, profiles, terms,]);


    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();

    // Use the preferred view mode for lists.
    const [viewMode] = usePreferredListViewMode();

    // Search.
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');

    // Keep the URL up to date with the search text.
    useReplaceSearchParamsEffect({ search: search });

    // Allow filtering (persisted to local storage between sessions).
    const [filterState, setFilterState] = useLocalStorage<ClassScheduleFilterState>('classSchedule.filterState', {});

    // Filter by the search.
    const items = useMemo(() => {
        let ret = (allItems ?? []);

        // Apply the filter passed via props if any.
        if (filterProp) {
            ret = ret.filter(filterProp);
        }

        if (filterState.termId) {
            ret = ret?.filter(item => item.termId === filterState.termId);
        }

        // Apply the search.
        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            // Just filtering by all string values that arent ids to start with. Most likely wont need them all for search.
            ret = ret.filter(item =>
                item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.child?.firstName, lastName: item.child?.lastName, }).toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.member?.firstName, lastName: item.member?.lastName, }).toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.term?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || paymentStateDisplayName(item.paymentState, t).toLocaleLowerCase().indexOf(lowerSearch) >= 0
            );
        }

        // Apply the sort passed via props if any.
        if (sortProp) {
            ret = sortProp(ret);
        }

        return ret;
    }, [allItems, search, t, filterProp, sortProp, filterState]);

    // Callback to send an email about the payment.
    const [emailStatusMessage, setEmailStatusMessage] = useState<string | undefined>();
    const [sendPaymentAvailableEmailMutation, { errors: sendPaymentAvailableEmailErrorsMutation }] = useSendPaymentAvailableEmailMutation();
    const [sendPaymentAvailableEmail, { errors: sendPaymentAvailableEmailErrors }] = useAsyncCallback(async (payment: Payment & { member?: Profile; }) => {
        await sendPaymentAvailableEmailMutation(payment.id);
        setEmailStatusMessage(t('paymentsList.emailSentMessageWithName', 'Email has been sent to {{firstName}} {{lastName}} ({{email}}).', { firstName: payment.member?.firstName, lastName: payment.member?.lastName, email: payment?.member?.primaryEmail }));
    }, [sendPaymentAvailableEmailMutation, setEmailStatusMessage]);

    // Set the default filter state to the current term.
    useEffect(() => {
        if (!filterState.termId) {
            setFilterState({ termId: currentTerm?.id });
        }
    }, [filterState, currentTerm?.id, setFilterState]);

    // Render the UI.
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {title ?? t('paymentsList.title', 'Payments')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col xs="auto">
                            <CardsOrTableToggle />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="auto">
                            <TermSelector selectedTermId={filterState.termId} setFilterState={({ termId: value }) => setFilterState({ termId: value })} restrictToTermsWithClasses={true} showPreviousTerms={true} />
                        </Col>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>
            <MainContainer fluid>
                <AlertOnErrors errors={[
                    loadingErrors, loadSupportingDataErrors, currentTermLoadErrors,
                    sendPaymentAvailableEmailErrors, sendPaymentAvailableEmailErrorsMutation,
                ]} />

                {/* Show a success message when an email notifciation is sent out about a payment */}
                <ConditionalFragment showIf={!!emailStatusMessage}>
                    <Alert color="success">
                        {emailStatusMessage}
                    </Alert>
                </ConditionalFragment>

                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        null, /* Handles the card specific function for which we don't want a table column. */
                        t('paymentsList.name.heading', 'Payment'),
                        t('paymentsList.child.heading', 'Cub'),
                        t('paymentsList.member.heading', 'Mama / Papa bear'),
                        t('paymentsList.Term.heading', 'Term'),
                        t('paymentsList.price.heading', 'Price'),
                        t('paymentsList.state.heading', 'State'),
                        (showPaymentDate ? t('paymentsList.paymentDate.heading', 'Payment date') : null),
                    ]}
                    columns={[
                        // We handle the card layout as a specific column ignored from the table.
                        (item, view) => {
                            // This column doesn't show in the table.
                            if (view !== 'cards') {
                                return null;
                            }
                            return (
                                <>
                                    <CardTitle tag="h5">
                                        {item.name}
                                    </CardTitle>
                                    <Row>
                                        <Col style={{ fontWeight: 'bold' }}>
                                            {t('common.euroMoney.value', '€{{value, 0.00}}', { value: item.totalAmountGross.toFixed(2), })}
                                        </Col>
                                        <Col xs="auto">
                                            {getPaymentStateBadge(item.paymentState as PaymentState, t)}
                                        </Col>
                                    </Row>
                                </>
                            );
                        },

                        // The remaining columns are for table mode only.

                        // Name
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.name;
                        },

                        // Child
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.child?.firstName, lastName: item.child?.lastName, });
                        },

                        // Member
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.member?.firstName, lastName: item.member?.lastName, });
                        },

                        // Term
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.term?.name ?? '';
                        },

                        // Price
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('common.euroMoney.value', '€{{value, 0.00}}', { value: item.totalAmountGross.toFixed(2), });
                        },

                        // State
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return getPaymentStateBadge(item.paymentState as PaymentState, t);
                        },


                        // Paid date
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            // If we are not showing this column, return null.
                            if (!showPaymentDate) {
                                return null;
                            }

                            if (!item.paidDate) {
                                return '';
                            }

                            return t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.paidDate), });
                        },

                    ]}

                    buttons={(item) => (
                        <ButtonGroup>
                            {
                                item.paymentState === PaymentState.Paid ? (
                                    <LinkContainer to={`/administration/children-management/payment-receipt/${item.id}`}>
                                        <Button color="primary">
                                            <FontAwesomeIcon icon="eye" />
                                            <> </>
                                            {t('paymentsList.viewReceipt', 'View receipt')}
                                        </Button>
                                    </LinkContainer>
                                ) : (
                                    <LinkContainer to={`/administration/children-management/payment-breakdown/${item.id}`}>
                                        <Button color="primary">
                                            <FontAwesomeIcon icon="eye" />
                                            <> </>
                                            {t('paymentsList.viewBreakdown', 'View breakdown')}
                                        </Button>
                                    </LinkContainer>
                                )
                            }

                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" caret>
                                    <span className="visually-hidden">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <ConditionalFragment showIf={!!item?.stripePaymentIntentId}>
                                        <DropdownItem onClick={() => window.open(`https://dashboard.stripe.com/payments/${item?.stripePaymentIntentId}`)}>
                                            <FontAwesomeIcon icon="credit-card" />
                                            <> </>
                                            {t('paymentsList.ViewOnStripe', 'View on Stripe')}
                                        </DropdownItem>
                                    </ConditionalFragment>
                                    <ConditionalFragment showIf={item.paymentState !== PaymentState.Paid}>
                                        <DropdownItem onClick={() => sendPaymentAvailableEmail(item)} >
                                            <FontAwesomeIcon icon="envelope" />
                                            <> </>
                                            {t('paymentsList.resendPaymentLink', 'Resend payment link')}
                                        </DropdownItem>
                                        <LinkContainer to={`delete/${item.id}`}>
                                            <DropdownItem className="text-danger">
                                                <FontAwesomeIcon icon="trash" />
                                                <> {t('common.delete', 'Delete')}</>
                                            </DropdownItem>
                                        </LinkContainer>
                                    </ConditionalFragment>

                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    )}
                    onItemClick={item => {
                        if (item.paymentState === PaymentState.Paid) {
                            navigate(`/administration/children-management/payment-receipt/${item.id}`);
                        } else {
                            navigate(`/administration/children-management/payment-breakdown/${item.id}`);
                        }
                    }}
                />

                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>
                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
            </MainContainer>
        </>
    );
};