import '../OEmbedProvider';
import { OEmbedProvider } from '../OEmbedProvider';

/**
 * YouTube oembed provider.
 */
export const GoogleMapsProvider: OEmbedProvider = {
    name: 'googleMaps',
    type: 'rich',
    format: 'json',
    api: 'https://TODO',
    urls: [
        "TODO",
    ],
};
