import './appNavbarBrand.scss';

import { useNavigate } from 'react-router-dom';
import { NavbarBrand } from 'reactstrap';

/**
 * Navbar brand image for the app. 
 */
export const AppNavbarBrand = () => {
    const navigate = useNavigate();

    return (
        <NavbarBrand className="app-navbar-brand" onClick={() => navigate('/')}>
        </NavbarBrand>
        );
};