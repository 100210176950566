import { gql, useMutation } from "@apollo/client";
import { useAsyncCallback } from "react-use-async-callback";
import { AsyncActionStatus } from "../../../shared/abstractStore";
import { ManuallyMarkAsPaidMutation, ManuallyMarkAsPaidMutationVariables, ManuallyMarkAsPaidResult } from '../generated/graphql';

export function useManuallyMarkAsPaidMutation(): [(childId: string, scheduledClassId: string) => Promise<ManuallyMarkAsPaidResult>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<ManuallyMarkAsPaidMutation, ManuallyMarkAsPaidMutationVariables>(
        gql`
            mutation manuallyMarkAsPaidMutation ($childId: ID!, $scheduledClassId: ID!) {
                manuallyMarkAsPaid(childId: $childId, scheduledClassId: $scheduledClassId) {
                    success
                }
            }
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (childId: string, scheduledClassId: string) => {
        const result = await mutationAction({ variables: { childId, scheduledClassId } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }

        return result.data?.manuallyMarkAsPaid as any;
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}