import * as React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { Guid } from 'guid-string';
import { BlobReferenceUploadAdapterPlugin } from './BlobReferenceUploadAdapter';
import './HtmlEditor.scss';

export interface HtmlEditorProps {
    value?: string,
    onChange?: (value: string) => void,
    onBlur?: () => void,

    size?: 'sm' | 'md' | 'lg' | 'xl',
}

/**
 * Html editor component that abstracts the editor actually being used from the code using it where its unimportant.
 * 
 * This version uses CKEditor 5 internally.
 * 
 * @param props
 */
export const HtmlEditor = (props: HtmlEditorProps) => {
    const {
        value,
        onChange,
        onBlur,
        size = 'md',
    } = props;

    // Calculate a key that only changes when the value is changed external to the editor itself as we need to update the key
    // of the componentto force a re-render whenever that does happen as CKEditor doesn't cope with it otherwise which means
    // we would otherwise not update when we've loaded data from the database and would therefore keep showing as blank.
    const [key, setKey] = React.useState<string>(() => Guid.newGuid());
    const trackedValue = React.useRef<string>(value ?? '');
    React.useEffect(() => {
        if (value !== trackedValue.current) {
            // value has been changed external to the changes made by the user in the editor.
            setKey(Guid.newGuid());
        }
    }, [value, trackedValue, setKey]);

    return (
        <div className={`document-editor document-editor-size-${size}`}>
            <div className="document-editor__editable-container">
                <div className="document-editor__editable">
                    <CKEditor
                        key={key}
                        editor={DecoupledEditor}
                        config={{ extraPlugins: [BlobReferenceUploadAdapterPlugin] }}
                        data={value}
                        onChange={(event: any, editor: any) => {
                            const data = editor.getData();

                            // Keep a track of changes that were performed by the editor itself (so we can check for external changes).
                            trackedValue.current = data;

                            if (onChange) {
                                onChange(data);
                            }
                        }}
                        onBlur={(event: any, editor: any) => {
                            if (onBlur) {
                                onBlur();
                            }
                        }}
                        onReady={(editor: any) => {
                            if (!editor) {
                                return;
                            }

                            // Insert the toolbar before the editable area.
                            editor.ui?.getEditableElement().parentElement.insertBefore(
                                editor.ui?.view.toolbar.element,
                                editor.ui?.getEditableElement()
                            );

                        }}
            />
                </div>
            </div>
        </div>
        );
};