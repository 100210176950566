import * as React from 'react';
import { useLazyQuery } from "@apollo/client";
import { gql } from 'graphql-tag';
import { Guid } from "guid-string";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { PaymentCheckoutViewModelQuery, PaymentCheckoutViewModelQueryVariables } from '../../generated/graphql';
import { paymentFieldsFragment } from "../../models/Payment";
import { AsyncLoadOptions, AsyncLoadResult } from '../../../../shared/abstractStore';
import { childFieldsFragment } from '../../models/Child';
import { termFieldsFragment } from '../../models/Term';
import { scheduledClassFieldsFragment } from '../../models/ScheduledClass';
import { paymentDiscountFieldsFragment } from '../../models/PaymentDiscount';
import { childDiscountFieldsFragment } from '../../models/ChildDiscount';
import { generalDiscountFieldsFragment } from '../../models/GeneralDiscount';
import { giftVoucherFieldsFragment } from '../../models/GiftVoucher';
import { classLocationFieldsFragment } from '../../models/ClassLocation';
import { classTypeFieldsFragment } from '../../models/ClassType';
import { classStageFieldsFragment } from '../../models/ClassStage';
import { classSubStageFieldsFragment } from '../../models/ClassSubStage';

/**
 * Load a dertails of a Payment required for the PaymentCheckout views from the api.
 */
export function usePaymentCheckoutViewModel(id: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<PaymentCheckoutViewModelQuery> {
    const [load, { data, loading, error, refetch }] = useLazyQuery<PaymentCheckoutViewModelQuery, PaymentCheckoutViewModelQueryVariables>(
        gql`
        query paymentCheckoutViewModelQuery ($id: ID!) {
            model: paymentForAnonymousUser (id: $id) {
                ...paymentFields

                child: childForAnonymousUser {
                    ...childFields
                }

                term: termForAnonymousUser {
                    ...termFields
                }

                scheduledClass: scheduledClassForAnonymousUser {
                    ...scheduledClassFields

                    classLocation: classLocationForAnonymousUser {
                        ...classLocationFields
                    }

                    classType: classTypeForAnonymousUser {
                        ...classTypeFields
                    }

                    currentClassStage {
                        ...classStageFields
                    }

                    currentClassSubStage {
                        ...classSubStageFields
                    }

                    nextClassStage {
                        ...classStageFields
                    }

                    nextClassSubStage {
                        ...classSubStageFields
                    }
                }

                paymentDiscounts: paymentDiscountsForAnonymousUser {
                    ...paymentDiscountFields

                    childDiscount: childDiscountForAnonymousUser {
                        ...childDiscountFields
                    }

                    generalDiscount: generalDiscountForAnonymousUser {
                        ...generalDiscountFields
                    }
                }

                giftVouchers: giftVouchersForAnonymousUser (paymentId: $id) {
                    ...giftVoucherFields
                }
            }
        }

        ${paymentFieldsFragment}
        ${childFieldsFragment}
        ${termFieldsFragment}
        ${scheduledClassFieldsFragment}
        ${paymentDiscountFieldsFragment}
        ${childDiscountFieldsFragment}
        ${generalDiscountFieldsFragment}
        ${giftVoucherFieldsFragment}
        ${classLocationFieldsFragment}
        ${classTypeFieldsFragment}
        ${classStageFieldsFragment}
        ${classSubStageFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}