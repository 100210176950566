import * as React from 'react';
import { Alert, AlertProps, Row, Col, Badge } from 'reactstrap';
import { userFriendlyErrorMessage, FriendlyErrorMessage } from '../userFriendlyErrorMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConditionalFragment } from 'react-conditionalfragment';


export interface AlertOnErrorsProps extends AlertProps {
    errors?: any,
    children?: any,
    simple?: boolean,
}

/**
 * Alert that shows an <Alert color="danger"> if error is set.
 * @param props
 */
export const AlertOnErrors = (props: AlertOnErrorsProps) => {
    const { errors, color, simple, ...rest } = props;

    const [openErrors, setOpenErrors] = React.useState<Array<string>>([]);
    const isOpen = React.useCallback((key: string) => !!openErrors.find(it => it === key), [openErrors]);
    const toggleOpen = React.useCallback((key: string) => {
        setOpenErrors(prevState => {
            if (prevState.find(it => it === key)) {
                return prevState.filter(it => it !== key);
            } else {
                return [...prevState, key];
            }
        });
    }, [setOpenErrors]);

    // Get the friendly version of each error.
    const friendlyErrors = React.useMemo(() => {
        // Flatten up to three levels of errors (wrapping in our own array first) and removing any
        // empty items (e.g. null, undefined, '').
        let flatErrors = [errors].flat(4).filter(item => item ? true : false);

        // Make friendly versions of all the error messages.
        const ret: Array<FriendlyErrorMessage> = [flatErrors.map(error => userFriendlyErrorMessage(error))].flat(2);
        return ret;
    }, [errors])

    // Group errors with the same message together.
    const errorGroups = React.useMemo(() => {
        let groups: Array<{ message: string, errors: Array<FriendlyErrorMessage> }> = [];

        for (const error of friendlyErrors) {
            let group = groups.find(item => item.message === error.message);
            if (!group) {
                group = { message: error.message, errors: [] };
                groups.push(group);
            }

            group.errors.push(error);
        }

        return groups;
    }, [friendlyErrors]);
    
    // If we have no errors, return nothing.
    if (!friendlyErrors.length) {
        return null;
    }

    // If we are in simple mode, return a list of friendly messages with no grouping or options to expand for more details.
    if (!!simple) {
        return (
            <Alert color={color || 'danger'} {...rest}>
                {props.children}

                {
                    friendlyErrors.map((item, index) => (
                        <div key={index}>
                            {item.message}
                        </div>
                    ))
                }
            </Alert>
        );
    }

    // If we are not in simple mode (the default) then provide an interactive list of errors with grouping and the ability to expand for more
    // details if the user wants to.
    return (
        <Alert color={color || 'danger'} {...rest}>
            {props.children}
            {
                errorGroups.map(item => (
                    <div key={item.message}>
                        <Row className="no-gutter">
                            <Col xs="auto">
                                <div onClick={() => toggleOpen(item.message)} style={{ cursor: 'pointer' }}>
                                    <FontAwesomeIcon icon={isOpen(item.message) ? 'caret-down' : 'caret-right'} />
                                    <> {
                                        item.errors.length > 1 ? (
                                            <Badge color="danger" pill>
                                                {item.errors.length}
                                            </Badge>
                                        ) : (
                                                <Badge color="danger" pill>
                                                    <FontAwesomeIcon icon="times-circle" />                
                                                </Badge>
                                            )
                                    }</>
                                    <ConditionalFragment showIf={item.errors.length > 1}>
                                        <> </>
                                    </ConditionalFragment>
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    {item.message}
                                </div>
                                <ConditionalFragment showIf={isOpen(item.message)}>
                                    {
                                        item.errors.map((error, index) => (
                                            <div key={index}>
                                                <ConditionalFragment showIf={!!error?.details}>
                                                    <div>
                                                        {error.details}
                                                    </div>
                                                </ConditionalFragment>
                                                <code lang="json" style={{}}>
                                                    {JSON.stringify(error?.error, null, 4)}
                                                </code>
                                            </div>
                                            ))
                                    }
                                </ConditionalFragment>
                            </Col>
                        </Row>
                    </div>
                ))
            }
        </Alert>
    );
};
