import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Alert, Col, Row } from "reactstrap";
import { useCurrentUserProfile } from "../../api/main/profiles/useCurrentUserProfile";
import { HorizontalRule } from "../shared/horizontalRule/HorizontalRule";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import './membersWelcomeScreen.scss';
import moment from "moment";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { ConditionalFragment } from "react-conditionalfragment";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { useJoinScheduledClassFromLocalStorageMutation } from "../../api/main/scheduledClassChildren/useJoinScheduledClassFromLocalStorageMutation";

export interface PotentialClass {
    scheduledClassId: string;
    childDOB: string;
    memberEmail: string;
    isWaitingList: boolean;
    expires: string;
}

/**
 * WelcomeScreen for Members, this page has no UI links and will only be hit on login.
 */
export const MembersWelcomeScreen = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Load the currentUser
    const {
        data: {
            model: memberProfile
        }, isLoading: _isLoading, errors: loadErrors
    } = useCurrentUserProfile();

    // Joining
    const [save, { errors: saveErrors }] = useJoinScheduledClassFromLocalStorageMutation();
    const isLoading = _isLoading;

    // Warning
    const initialMessage = t('membersWelcomeScreen.potentialClass.message', 'It looks like you attempted to join a class before logging in, we are attempting to reserve your place in the class and you will be redirected shortly.');
    const [warningMessage, setWarningMessage] = useState<string | undefined | null>(undefined);

    // useEffect to get potentialClass from local storage
    useEffect(() => {
        if (!memberProfile) return;

        const potentialClassData = localStorage.getItem("potentialClass");

        // Parse the data
        if (potentialClassData) {

            const potentialClass = JSON.parse(potentialClassData) as PotentialClass;

            // Check if the potential class has expired
            const expires = moment(potentialClass.expires);
            if (expires.isBefore(moment())) {
                localStorage.removeItem("potentialClass");
            } else {
                setWarningMessage(initialMessage);
            }

            async function joinClass() {
                try {
                    const result: { message?: string | undefined | null, returnURL?: string | undefined | null; } = await save(potentialClass.scheduledClassId, potentialClass.childDOB, memberProfile?.id ?? '', potentialClass.isWaitingList);

                    if (result) {
                        if (!result.returnURL) {
                            setWarningMessage(result.message ?? t('membersWelcomeScreen.errorJoiningClass', 'Error joining class. Please try again.'));
                        } else {
                            navigate(result.returnURL);
                        }
                    }

                    localStorage.removeItem("potentialClass");
                } catch (e) {
                    setWarningMessage(t('membersWelcomeScreen.errorJoiningClass', 'Error joining class. Please try again.'));
                }
            }
            joinClass();
        } else {
            setWarningMessage(undefined);
        }


    }, [memberProfile, t, save, navigate, initialMessage]);

    // Redner the UI
    //
    return (
        <>
            <MainContainer >
                <AlertOnErrors errors={[loadErrors, saveErrors]} />

                <ConditionalFragment showIf={warningMessage !== undefined}>
                    <Alert color="warning">
                        <Row>
                            <Col>
                                {warningMessage}
                            </Col>

                            <Col xs="auto">
                                <ConditionalFragment showIf={!!isLoading}>
                                    <LoadingIndicator />
                                </ConditionalFragment>
                            </Col>
                        </Row>
                    </Alert>
                </ConditionalFragment>

                <Row className="members-welcome-screen">
                    <Row className="members-welcome-screen-header">
                        <h1 className="members-welcome-screen-header-title">{t('membersWelcomeScreen.title.p1', 'Hello. Welcome to')}</h1>
                        <h1 className="members-welcome-screen-header-title">{t('membersWelcomeScreen.title.p2', 'Your Happy Hub')}</h1>
                        <p className="members-welcome-screen-header-subtitle">{t('membersWelcomeScreen.subtitle', 'Use the tabs above to navigate Your Happy Hub')}</p>
                    </Row>

                    <Row>
                        <p>{t('membersWelcomeScreen.clickThe.p1', 'Click the ')} <strong>{t('membersWelcomeScreen.myBearCubs', 'My Bear Cubs')}</strong> {t('membersWelcomeScreen.tabToCubsParagraph', 'tab to view, add, edit your child\'s profile, and book & manage your classes.')}</p>
                    </Row>

                    <Row>
                        <HorizontalRule />
                    </Row>

                    <Row>
                        <p>{t('membersWelcomeScreen.clickThe.p2', 'Click the')} <strong>{t('membersWelcomeScreen.payments', 'Payments')}</strong> {t('membersWelcomeScreen.tabToPaymentsParagraph', 'tab to check your booking history, outstanding payments, class credits, and discounts.')}</p>
                    </Row>

                    <Row>
                        <HorizontalRule />
                    </Row>

                    <Row>
                        <p>{t('membersWelcomeScreen.needSupportParagraph', 'Need support or have questions? Click the')} <strong>{t('membersWelcomeScreen.messages', 'Messages')}</strong> {t('membersWelcomeScreen.tabToMessagesParagraph', 'tab to send us a message. Our team will reply soon.')}</p>
                    </Row>

                    <Row>
                        <HorizontalRule />
                    </Row>

                    <Row>
                        <p>{t('membersWelcomeScreen.updateYourParagraph', 'Update your account details or sign out of your account on the')} <strong>{t('membersWelcomeScreen.profileIcon', 'Profile Icon')}</strong> (<FontAwesomeIcon icon="user" />)</p>
                    </Row>
                </Row>
            </MainContainer>
        </>
    );
};