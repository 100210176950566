import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useSidebarState } from "../../../../globalState/sidebarState/useSidebarState";
import "./sidebarToggle.scss";

export interface SidebarToggleProps {
    className?: string,
}

/**
 * Button to toggle the sidebar.
 */
export const SidebarToggle = (props: SidebarToggleProps) => {
    const {
        className,
    } = props;

    const [, setSidebarState] = useSidebarState();
    const { t } = useTranslation();

    return (
        <>
            {/* Button to toggle sidebar */}
            <div className={`sidebar-toggle ${className ?? ''}`} onClick={() => setSidebarState(prevState => ({ ...prevState, visible: !prevState.visible }))}>
                <FontAwesomeIcon icon="bars" />
                <span className="visually-hidden">
                    {t('sidebarToggle.screenReaderText', 'Toggle sidebar')}
                </span>
            </div>
        </>

        );
};