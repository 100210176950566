import * as React from "react";
import { ExternalAuthenticationScheme } from "./AccountService";
import { AsyncLoadResult, AsyncLoadOptions } from "../../shared/abstractStore";
import { useAsyncCallback } from "react-use-async-callback";
import { useAccountService } from "./useAccountService";

/**
 * Use the store to return a list of external authentication schemes for the app.
 */
export function useExternalAuthenticationSchemes(options: AsyncLoadOptions = {}): AsyncLoadResult<{ externalAuthenticationSchemes: Array<ExternalAuthenticationScheme> }> {
    const [externalAuthenticationSchemes, setExternalAuthenticationSchemes] = React.useState<Array<ExternalAuthenticationScheme>>([]);

    const accountService = useAccountService();

    const [refresh, { isExecuting: isLoading, errors }] = useAsyncCallback(async () => {
        let result = await accountService.getExternalAuthenticationSchemes();
        setExternalAuthenticationSchemes(result);
    }, [accountService, setExternalAuthenticationSchemes]);

    React.useEffect(() => {
        if (!options.lazy) {
            refresh();
        }
    }, [refresh, options.lazy]);

    // TODO handle options.pollInterval.

    return {
        data: { externalAuthenticationSchemes: externalAuthenticationSchemes },
        refresh: refresh,
        isLoading: isLoading,
        errors: errors
    };
}
