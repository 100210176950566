import * as React from "react";
import { Form, Alert, FormGroup, Label, Spinner, FormText } from "reactstrap";
import { useChanges } from "../../shared/useChanges";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { ChangePassword as ChangePasswordModel } from '../../api/account/models/ChangePassword';
import { useTranslation } from "react-i18next";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useChangePasswordCallback } from "../../api/account";
import { usePasswordValidation } from "../../shared/passwordValidation";
import { FormButtons } from "../shared/formButtons/FormButtons";
import { MainContainer } from "../shared/mainContainer/MainContainer";

/**
 * Change the logged in user's password.
 */
export const ChangePassword = () => {
    const { t } = useTranslation();
    const [hasChangedPassword, setHasChangedPassword] = React.useState<boolean>(false);
    const [changePassword, { isExecuting: isChangingPassword, errors: changePasswordErrors }] = useChangePasswordCallback();
    const { checkPassword, passwordRequirements } = usePasswordValidation();

    const { model, change } = useChanges<ChangePasswordModel>({ currentPassword: '', newPassword: '', confirmPassword: '' });
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        if (!model) {
            return;
        }

        const rules = {
            currentPassword: () => !model.currentPassword ? t('changePassword.currentPasswordRequred', 'Current password is required'): '',
            newPassword: () => !model.newPassword ? t('changePassword.newPasswordRequired', 'Password is required')
                : checkPassword(model.newPassword).errorDescription,
            confirmPassword: () => model.confirmPassword !== model.newPassword ? t('changePassword.passwordsDoNotMatch', 'The password and confirmation password do not match') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);
 
    // Render the UI.
    //

    return (
        <MainContainer>
            <Form onSubmit={async e => {
                e.preventDefault();
                if (!validate()) {
                    return;
                }

                const ok = await changePassword(model.currentPassword, model.newPassword);
                setHasChangedPassword(ok);
            }}>
                <AlertOnErrors simple errors={changePasswordErrors} />

                {
                    hasChangedPassword ? (
                        <Alert color="success">
                            {t('changePassword.passwordHasBeenChanged', 'Your password has been changed.')}
                        </Alert>
                    ) : null
                }

                <FormGroup>
                    <Label htmlFor="currentPassword">{t('changePassword.currentPassword', 'Current password')}</Label>
                    <ValidatedInput type="password" name="currentPassword" autoComplete="current-password" value={model.currentPassword} onChange={e => change({ currentPassword: e.currentTarget.value })} onBlur={e => validate('currentPassword')} validationErrors={validationErrors['currentPassword']} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="newPassword">{t('changePassword.newPassword', 'New password')}</Label>
                    <ValidatedInput type="password" name="newPassword" autoComplete="new-password" value={model.newPassword} onChange={e => change({ newPassword: e.currentTarget.value })} onBlur={e => validate('newPassword')} validationErrors={validationErrors['newPassword']} />
                    <FormText>
                        {passwordRequirements}
                    </FormText>
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="confirmPassword">{t('changePassword.confirmPassword', 'Confirm password')}</Label>
                    <ValidatedInput type="password" name="confirmPassword" autoComplete="new-password" value={model.confirmPassword} onChange={e => change({ confirmPassword: e.currentTarget.value })} onBlur={e => validate('confirmPassword')} validationErrors={validationErrors['confirmPassword']} />
                </FormGroup>
                <FormButtons>
                    <ButtonAsync type="submit" color="primary" isExecuting={isChangingPassword}
                        executingChildren={<><Spinner size="sm" /> {t('changePassword.updatingPassword', 'Updating password...')}</>}>
                        {t('changePassword.updatePassword', 'Update password')}
                    </ButtonAsync>
                </FormButtons>
            </Form>
        </MainContainer>
    );
};
