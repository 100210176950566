import { OEmbedProvider } from "./OEmbedProvider";
import { OEmbedResult } from "./OEmbedResult";
import { DailyMotionProvider } from "./providers/DailyMotionProvider";
import { FacebookProvider } from "./providers/FacebookProvider";
import { FlickrProvider } from "./providers/FlickrProvider";
import { GoogleMapsProvider } from "./providers/GoogleMapsProvider";
import { InstagramProvider } from "./providers/InstagramProvider";
import { SpotifyProvider } from "./providers/SpotifyProvider";
import { TwitterProvider } from "./providers/TwitterProvider";
import { VimeoProvider } from "./providers/VimeoProvider";
import { YouTubeProvider } from "./providers/YouTubeProvider";
import fetchJsonp from "fetch-jsonp";

/**
 * Implementation of an oembed client that only depends on fetch() and fetch-jsonp (for JSONP support).
 */
export class FetchOmbed {
    private _providers: Array<OEmbedProvider>;

    constructor() {
        this._providers = [
            DailyMotionProvider,
            FacebookProvider,
            FlickrProvider,
            GoogleMapsProvider,
            InstagramProvider,
            SpotifyProvider,
            TwitterProvider,
            VimeoProvider,
            YouTubeProvider,
        ];
    }

    /**
     * Get the best provider for handling url.
     * @param url
     */
    getProvider(url: string): OEmbedProvider | null {
        for (const provider of this._providers) {
            for (const pattern of provider.urls) {
                var regExp = new RegExp(pattern);
                if (url.match(regExp)) {
                    return provider;
                }
            }
        }

        return null;
    }

    /**
     * Return the OEmbedResult for url.
     * @param url
     */
    async getOEmbedData(url: string): Promise<OEmbedResult | null> {
        // Find the right provider to use.
        const provider = this.getProvider(url);
        if (!provider) {
            console.error(`getOEmbedData could not find a registered provider to handle url: ${url}`);
            return null;
        }

        try {
            if (provider.format === 'jsonp') {
                // Special handling of JSONP which uses a script tab to return the json wrapped in a javascript callback function.
                const response = await fetchJsonp(`${provider.api}?format=json&url=${escape(url)}`);
                const result = await response.json();
                return result as OEmbedResult;
            } else {
                const response = await fetch(`${provider.api}?format=${escape(provider.format)}&url=${escape(url)}` /*, { mode: 'no-cors' } */);
                const result = await response.json();
                return result as OEmbedResult;
            }
        } catch (error) {
            console.error(`getOEmbedData failed when contacting provider: ${error}`);
            return null;
        }
    }


}