import './formButtons.scss';

import * as React from 'react';

export interface FormButtonsProps {
    top?: boolean,
    className?: string,
    noPadding?: boolean,
    children: React.ReactNode
}

/**
 * Container for buttons (e.g. sumbit) at the bottom of a form. 
 */
export const FormButtons = (props: FormButtonsProps) => {
    const { top, className, children, noPadding = false } = props;

    return (
        <div className={`form-buttons ${!!top? 'form-buttons-top': 'form-buttons-bottom'} ${noPadding? 'form-buttons-no-padding': ''} ${className ?? ''}`}>
            {children}
        </div>
        );
};