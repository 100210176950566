import { Nav, NavProps } from 'reactstrap';
import './headerNav.scss';

export interface HeaderNavProps extends NavProps {
}

/**
 * Display of Navigation components in the Navbar.
 */
export const HeaderNav = (props: HeaderNavProps) => {
    const {
        className,
        ...rest
    } = props;

    return (
        <Nav className={`header-nav ${className ?? ''}`} {...rest} />
    );
};
