import { FetchOmbed } from "./FetchOEmbed";

/**
 * Scan element and its children looking for oembed elements.  If we find any, then we load the oembed data we have providers for within them.
 * @param element
 */
export async function processOEmbedElements(element: Element): Promise<void> {
    // Find all the oembed elements.
    const elements: Array<Element> = [
        ...(element.tagName.toLowerCase() === 'oembed'? [element]: []),
        ...element.getElementsByTagName('oembed')
    ];

    // If we didn't find any elements to process, return doing nothing.
    if (!elements.length) {
        return;
    }

    // Process each oembed element we find.
    const service = new FetchOmbed();
    for (const theElement of elements) {
        // Need a URL to work with.
        const url = theElement.getAttribute('url');
        if (!url) {
            continue;
        }

        // Load the data using our trusted oembed providers.
        const data = await service.getOEmbedData(url);
        if (!data || !data.html) {
            continue;
        }

        // Update the innerHTML of the element based on the result.
        theElement.innerHTML = data.html;
    }
}