import * as React from 'react';
import { PasswordCheckResults, PasswordValidation } from './PasswordValidation';
import { generatePasswordRulesDescrption } from './generatePasswordRulesDescription';

export interface PasswordValidationResult {
    checkPassword: (password: string) => PasswordCheckResults,
    passwordRequirements: string,
}

/**
 * Hook for password validation.
 */
export function usePasswordValidation(): PasswordValidationResult {
    let passwordValidation = React.useMemo(() => new PasswordValidation(), []);
    let checkPassword = React.useCallback((password: string) => {
        return passwordValidation.check(password);
    }, [passwordValidation]);
    let passwordRequirements = React.useMemo(() => {
        return generatePasswordRulesDescrption(passwordValidation);
    }, [passwordValidation])

    return {
        checkPassword,
        passwordRequirements
    };
}