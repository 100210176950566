import moment from "moment";
import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { ChildAttendance } from "../../api/main/models/ChildAttendance";
import { useStaffClassAvailablityListViewModel } from "../../api/main/staffClassAvailability/viewModels/useStaffClassAvailablityListViewModel";
import { useCurrentTerm } from "../../api/main/terms/useCurrentTerm";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { getScheduledClassSummary } from "../scheduledClass/getScheduledClassSummary";
import { Banner } from "../shared/banner/Banner";
import { CardsOrTable } from "../shared/cardsOrTable/CardsOrTable";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";

type LessonDateCount = {
    date: string; // The date in 'YYYY-MM-DD' format
    count: number; // The count of lessonDates for that date
};

export const StaffClassAvailabilityList = () => {
    const { t } = useTranslation();

    // Load the current term
    const {

        data: {
            model: currentTerm
        }, isLoading: _isLoading, errors: loadTermErrors
    } = useCurrentTerm(); // WIll want to pass this to the custom hook to only load relevant classes

    // Load the data
    const {
        data: {
            scheduledClasses,
            scheduledClassChildren,
            classLocations,
            classStages,
            classSubStages,
            childAttendances
        }, isLoading: isLoadingData, errors: loadErrors
    } = useStaffClassAvailablityListViewModel(currentTerm?.id);
    const isLoading = _isLoading || isLoadingData;

    // Combing the data into a single model
    const _items = useMemo(() => scheduledClasses?.filter(it => it.termId === currentTerm?.id)?.map(scheduledClass => {
        const classLocation = classLocations?.find(cl => cl.id === scheduledClass.classLocationId);
        const classStage = classStages?.find(cs => cs.id === scheduledClass.currentClassStageId);
        const classSubStage = classSubStages?.find(css => css.id === scheduledClass.currentClassSubStageId);

        const numberOfPeopleInClass = scheduledClassChildren?.filter(scc => scc.scheduledClassId === scheduledClass.id && !scc.isOnWaitingList).length;
        const spaceInClass = scheduledClass.maximumClassSize - numberOfPeopleInClass;
        const myChildAttendances = childAttendances?.filter(ca => ca.scheduledClassId === scheduledClass.id && ca.isCourtesyClassBooking);

        return {
            ...scheduledClass,
            classLocation,
            classStage,
            classSubStage,

            numberOfPeopleInClass,
            spaceInClass,
            myChildAttendances
        };

    }), [scheduledClasses, classLocations, classStages, classSubStages, childAttendances, scheduledClassChildren, currentTerm]);

    const items = useMemo(() => {
        if (!_items) return [];

        let ret = _items;

        // Filter out the deferral classes, these are for admin use only.
        ret = ret.filter(it => it.classLocation?.name !== 'Deferrals - DO NOT REMOVE');

        // Filter for classes with spaceInClass.
        ret = ret?.filter(it => it.spaceInClass > 0);

        // Return
        return ret;
    }, [_items]);


    // Get all dates for courtesy class bookings
    const courtesyClassBookingDates = useCallback((courtestClassBookings: Array<ChildAttendance>) => {
        if (!courtestClassBookings) return [];

        // Return a list of dates for bookings, but should include the count for each date appearing and only return that date once
        // even if it appears multiple times.
        const result = courtestClassBookings.reduce((acc, booking) => {
            const date = moment(booking.lessonDate);
            const dateString = date.toISOString().split('T')[0]; // Convert date to string format 'YYYY-MM-DD'

            if (!acc.has(dateString)) {
                acc.set(dateString, 0);
            }

            acc.set(dateString, acc.get(dateString) + 1);

            return acc;
        }, new Map());

        // Convert the Map to an array of objects with date and count
        const dateCounts: Array<LessonDateCount> = Array.from(result, ([date, count]) => ({ date, count }));

        return dateCounts;

    }, []);

    // Render the UI
    //
    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>{t('staffClassAvailabilityList.title', 'Class availability')}</h1>
                            <h4>
                                {t('staffClassAvailabilityList.subtitle', 'Shows only classes with available spaces')}
                            </h4>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadTermErrors, loadErrors]} />

                <CardsOrTable
                    viewMode={"table"}
                    items={items}
                    tableHeadings={[
                        t('staffClassAvailabilityList.tableHeadings.class', 'Class'),
                        t('staffClassAvailabilityList.tableHeadings.numberOfPeopleInClass', 'Number of people in class'),
                        t('staffClassAvailabilityList.tableHeadings.spaceInClass', 'Space in class'),
                        t('staffClassAvailabilityList.tableHeadings.courtesyClassBookings', 'Courtesy class bookings')
                    ]}
                    columns={[
                        // Class Details
                        (item) => {
                            return getScheduledClassSummary(item, { classLocation: item.classLocation, classStage: item.classStage, classSubStage: item.classSubStage });
                        },

                        // Number of people in class
                        (item) => {
                            return item.numberOfPeopleInClass;
                        },

                        // Space in class
                        (item) => {
                            return item.spaceInClass;
                        },

                        // Courtesy class bookings
                        (item) => {
                            return courtesyClassBookingDates(item.myChildAttendances).map((lessonDateCount) => <p>{t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(lessonDateCount.date) })} x {lessonDateCount.count}</p>);
                        }
                    ]}
                />
            </MainContainer>
        </>
    );
};