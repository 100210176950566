import '../OEmbedProvider';
import { OEmbedProvider } from '../OEmbedProvider';

/**
 * Flickr oembed provider.
 */
export const FlickrProvider: OEmbedProvider = {
    name: 'flickr',
    type: 'photo',
    format: 'jsonp',
    api: 'https://www.flickr.com/services/oembed',
    urls: [
        "flickr\\.com/photos/.+",
    ],
};
