import "./cardHeaderCollapse.scss";
import { CardHeader, CardHeaderProps, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface CardHeaderCollapseProps extends CardHeaderProps {
    isOpen: boolean,
    toggle: () => void,
}

/**
 * CardHeader that knows the card can collapse into its header.
 */
export const CardHeaderCollapse = (props: CardHeaderCollapseProps) => {
    const {
        isOpen,
        toggle,
        className,
        children,
        ...rest
    } = props;

    return (
        <CardHeader
            className={`card-header-collapse card-header-collapse-${isOpen ? 'open' : 'closed'} ${className ?? ''}`}
            onClick={() => toggle()}
            {...rest}
        >
            <Row>
                <Col>
                    {children}
                </Col>
                <Col xs="auto">
                    <FontAwesomeIcon icon={isOpen ? 'caret-up' : 'caret-down'} />
                </Col>
            </Row>
        </CardHeader>
    );
};