import i18n from 'i18next';
import { PasswordOptions, defaultPasswordOptions } from './PasswordOptions';

/**
 * Enforce and describe PasswordOptions.
 */
export class PasswordValidation {
    private readonly passwordOptions: PasswordOptions;

    constructor(passwordOptions?: PasswordOptions) {
        this.passwordOptions = passwordOptions ?? defaultPasswordOptions;
    }

    /**
     * Check password against the PasswordOptions and return a success indicator and an array of any errors, and a friendly description of all errors.
     * @param password
     */
    check(password: string): PasswordCheckResults {
        let ok = true;
        let errors: Array<string> = [];

        if (this.passwordOptions.requiredLength > password.length) {
            ok = false;
            errors.push(i18n.t('passwordValidation.requiredLengthError', 'must be at least {{requiredLength}} characters long', { requiredLength: this.passwordOptions.requiredLength }));
        }

        if (this.passwordOptions.requiredUniqueChars > 1) {
            let charMap = Array<string>();
            for (let i = 0; i < password.length; ++i) {
                let char = password.charAt(i);
                if (charMap.indexOf(char) !== -1) {
                    charMap.push(char);

                    // Break if we know enough to let the password pass on this rule.
                    if (charMap.length > this.passwordOptions.requiredUniqueChars) {
                        break;
                    }
                }
            }

            if (this.passwordOptions.requiredUniqueChars > charMap.length) {
                ok = false;
                errors.push(i18n.t('passwordValidation.requiredUniqueCharsError', 'must contain at least {{requiredUniqueChars}} unique characters', { requiredUniqueChars: this.passwordOptions.requiredUniqueChars }));
            }
        }

        if (this.passwordOptions.requireDigit) {
            let regExp = new RegExp('.*[0-9].*');
            if (!regExp.test(password)) {
                ok = false;
                errors.push(i18n.t('passwordValidation.requireDigitError', 'must contain a number'));
            }
        }

        if (this.passwordOptions.requireLowercase) {
            let regExp = new RegExp('.*[a-z].*');
            if (!regExp.test(password)) {
                ok = false;
                errors.push(i18n.t('passwordValidation.requireLowercaseError', 'must contain a lowercase letter'));
            }
        }

        if (this.passwordOptions.requireUppercase) {
            let regExp = new RegExp('.*[A-Z].*');
            if (!regExp.test(password)) {
                ok = false;
                errors.push(i18n.t('passwordValidation.requireUppercaseError', 'must contain an uppercase letter'));
            }
        }

        if (this.passwordOptions.requireNonAlphanumeric) {
            let regExp = new RegExp('.*[^0-9a-zA-Z].*');
            if (!regExp.test(password)) {
                ok = false;
                errors.push(i18n.t('passwordValidation.requireNonAlphanumericError', 'must contain a symbol (such as !$%&*@#)'));
            }
        }

        // Generate a textual description of all errors, or blank if there are no errors for errorDescription.
        let errorDescription: string = '';
        if (errors.length) {
            errorDescription = i18n.t('passwordValidation.errorDescription', 'Password {{errors}}', { errors: errors.join(', ') });
        }

        return { valid: ok, errors, errorDescription };
    }
}

export interface PasswordCheckResults {
    valid: boolean,
    errors: Array<string>,
    errorDescription: string,
}

