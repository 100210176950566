/**
 * Returns true if there are any non-empty validation errors in the validationErrors passed in.
 * @param validationErrors
 */
export function hasAnyValidationErrors(validationErrors: Array<string | undefined | null> | Array<Array<string | undefined | null>> | undefined | null) {
    let flatErrors = [validationErrors].flat(4).filter(item => item ? true : false);

    for (const error of flatErrors) {
        if (!!error) {
            return true;
        }
    }

    return false;
}