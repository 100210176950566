import { AsyncActionStatus } from "../../shared/abstractStore";
import { useAsyncCallback } from "react-use-async-callback";
import { LockoutRequest } from "./AccountService";
import { useAccountService } from "./useAccountService";

/**
 * Callback to lockout an account.
 */
export function useLockoutCallback(): [(model: LockoutRequest) => Promise<boolean>, AsyncActionStatus] {
    const accountService = useAccountService();

    const [action, { isExecuting, errors }] = useAsyncCallback(async (model: LockoutRequest) => {
        let result = await accountService.lockout(model);
        return result;
    }, [accountService]);

    return [action, { isExecuting, errors }];
}
