import { ApiFetch } from 'apifetch-json';
import { BlobReference } from '../models/BlobReference';

/**
 * Service for handling the uploading of files as blobs.
 */
export class BlobUploadService {
    private readonly url: string;
    private readonly api: ApiFetch;


    constructor(url: string, apiFetch?: ApiFetch) {
        this.url = url;
        this.api = apiFetch || new ApiFetch();
    }

    /**
     * Upload a single file from a FileList (as provided by input[type="file"])
     * @param files
     */
    async upload(files: FileList): Promise<BlobReference | null> {
        let response = await this.uploadMultiple(files);

        if (!response || !response.length) {
            return null;
        }

        return response[0];
    }

    /**
     * Upload a single file that is provided as a File rather than a FileList.
     * @param file
     */
    async uploadFile(file: File): Promise<BlobReference | null> {
        let response = await this.uploadMultiple([file]);

        if (!response || !response.length) {
            return null;
        }

        return response[0];
    }

    /**
     * Upload multiple items.
     * @param files
     */
    async uploadMultiple(files: FileList | Array<File>): Promise<Array<BlobReference>> {
        var data = new FormData();
        for (let i = 0; i < files.length; ++i) {
            let file = files[i];
            data.append('files', file, file.name);
        }

        // We use fetch() rather than post() here as we want full control over headers and body.
        let result = await this.api.fetch<Array<BlobReference>>(
            `${this.url}/upload`,
            (init) => {
                let { headers, ...rest } = init;

                // Make sure we don't pass 'Content-Type' as that will break the way browsers
                // pass file uploads.  They require it to be blank so the browser can handle
                // it with an appropriate boundry set etc.
                let newHeaders = new Headers(headers);
                newHeaders.delete('Content-Type');

                return {
                    ...rest,
                    method: 'POST',
                    headers: newHeaders,
                    body: data
                };
            });

        return result;
    }

    async uploadDataUrl(filename: string, dataUrl: string): Promise<BlobReference> {
        let result = await this.api.post<BlobReference>(`${this.url}/uploadDataUrl?filename=${encodeURIComponent(filename)}`, dataUrl);
        return result;
    }

    async uploadBlobObject(filename: string, blob: globalThis.Blob): Promise<BlobReference | null> {
        var data = new FormData();
        data.append('files', blob, filename);

        // We use fetch() rather than post() here as we want full control over headers and body.
        let result = await this.api.fetch<Array<BlobReference>>(
            `${this.url}/upload`,
            (init) => {
                let { headers, ...rest } = init;

                // Make sure we don't pass 'Content-Type' as that will break the way browsers
                // pass file uploads.  They require it to be blank so the browser can handle
                // it with an appropriate boundry set etc.
                let newHeaders = new Headers(headers);
                newHeaders.delete('Content-Type');

                return {
                    ...rest,
                    method: 'POST',
                    headers: newHeaders,
                    body: data
                };
            });

        return result[0];
    }
}
