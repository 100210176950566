import './externalLoginButton.scss';

import * as React from 'react';
import { ButtonProps, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';

export interface ExternalLoginButtonProps extends ButtonProps {
    provider: string,
    providerDisplayName?: string,
}

interface ProviderDisplayOptions {
    text: string,
    icon: IconProp,
    className: string,
}

/**
 * Button for a an external service that can be used to log in. 
 */
export const ExternalLoginButton = (props: ExternalLoginButtonProps) => {
    const { t } = useTranslation();
    const { provider, providerDisplayName, className: propsClassName, ...rest } = props;

    const { text, icon, className } = React.useMemo((): ProviderDisplayOptions => {
        switch (provider) {
            case 'Microsoft':
                return { text: t('externalLoginButton.microsoftAccountProviderText', 'Microsoft Account'), icon: ['fab', 'microsoft'], className: 'microsoft' };
            case 'Google':
                return { text: providerDisplayName ?? provider, icon: ['fab', 'google'], className: 'google' };
            case 'Facebook':
                return { text: providerDisplayName ?? provider, icon: ['fab', 'facebook'], className: 'facebook' };
            case 'Twitter':
                return { text: providerDisplayName ?? provider, icon: ['fab', 'twitter'], className: 'twitter' };
            default:
                return { text: providerDisplayName ?? provider, icon: 'external-link-alt', className: 'other' };
        }
    }, [provider, providerDisplayName, t]);

    return (
        <Button className={`external-login-button ${className} ${propsClassName ?? ''}`} {...rest}>
            <FontAwesomeIcon icon={icon} /> {t('externalLoginButton.text', '{{providerDisplayName}}', { context: provider, providerDisplayName: text })}
        </Button>
        );
};
