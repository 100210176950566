import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Guid } from "guid-string";
import React from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { ConsumeMembersChildrenViewModelQuery, ConsumeMembersChildrenViewModelQueryVariables } from '../../generated/graphql';
import { childFieldsFragment } from "../../models/Child";
import { classLocationFieldsFragment } from "../../models/ClassLocation";
import { classStageFieldsFragment } from "../../models/ClassStage";
import { classSubStageFieldsFragment } from "../../models/ClassSubStage";
import { classTypeFieldsFragment } from "../../models/ClassType";
import { courtesyClassCreditFieldsFragment } from "../../models/CourtesyClassCredit";
import { genderFieldsFragment } from "../../models/Gender";
import { scheduledClassFieldsFragment } from "../../models/ScheduledClass";
import { scheduledClassStaffFieldsFragment } from "../../models/ScheduledClassStaff";
import { termFieldsFragment } from "../../models/Term";

export function useConsumeMembersChildrenViewModel(memberId: string | null | undefined, options: AsyncLoadOptions = {}): AsyncLoadResult<ConsumeMembersChildrenViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<ConsumeMembersChildrenViewModelQuery, ConsumeMembersChildrenViewModelQueryVariables>(
        gql`
        query consumeMembersChildrenViewModelQuery ($memberId: ID!) {
            items: children (memberId: $memberId) {
                ...childFields

                courtesyClassCredits (isConsumed: false)  {
                    ...courtesyClassCreditFields
                }

                scheduledClassChildren {
                    id
                    isOnWaitingList

                    scheduledClass {
                        ...scheduledClassFields

                        lessonDatesForCurrentTerm

                        classLocation {
                            ...classLocationFields
                        }
                    }
                }
            }

            scheduledClassStaffs {
                ...scheduledClassStaffFields
            }

            genders {
                ...genderFields
            }

            classStages {
                ...classStageFields
            }

            classSubStages {
                ...classSubStageFields
            }

            classTypes {
                ...classTypeFields
            }

            terms {
                ...termFields
            }
        }

        ${childFieldsFragment}
        ${scheduledClassFieldsFragment}
        ${classLocationFieldsFragment}
        ${scheduledClassStaffFieldsFragment}
        ${genderFieldsFragment}
        ${courtesyClassCreditFieldsFragment}
        ${classStageFieldsFragment}
        ${classTypeFieldsFragment}
        ${classSubStageFieldsFragment}
        ${termFieldsFragment}
        `,
        {
            variables: {
                memberId: memberId ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error]);
};