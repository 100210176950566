import './footer.scss';
import { Nav } from 'reactstrap';
import { FooterNavigation } from './FooterNavigation';

export const Footer = () => {
    return (
        <div className="footer">
            <Nav className="footer-nav">
                <FooterNavigation />
            </Nav>
        </div>
    );
};

