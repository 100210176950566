import '../OEmbedProvider';
import { OEmbedProvider } from '../OEmbedProvider';

/**
 * Spotify oembed provider.
 */
export const SpotifyProvider: OEmbedProvider = {
    name: 'spotify',
    type: 'rich',
    format: 'jsonp',
    api: 'https://embed.spotify.com/oembed/',
    urls: [
        "open.spotify.com/(track|album|user)/",
    ],
};
