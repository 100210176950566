import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Badge, Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Col, ListGroup, ListGroupItem, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { roleInClassDisplayName } from "../../api/main/models/constants/RoleInClass";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { HtmlDisplay } from "../../shared/htmlEditor";
import { getFullUrl } from "../../utilities/getFullUrl";
import { getScheduledClassSummary } from "../scheduledClass/getScheduledClassSummary";
import { RegisterComponent } from "./RegisterComponent";
import { ScheduledClassChildrenWithChildDetails, ScheduledClassStaffWithScheduledClassClassStageAndSubStage } from "./StaffClassesTab";
import { useClassTypeForIcons } from "../../api/main/scheduledClasses/viewModels/useClassTypeForIcons";
import { useChangesArray } from "../../shared/useChanges";
import { ChildAttendance } from "../../api/main/models/ChildAttendance";
import { useEditChildAttendancesViewModel } from "../../api/main/childAttendances/viewModels/useEditChildAttendancesViewModel";
import { useSaveChildAttendanceMutation } from "../../api/main/childAttendances/useSaveChildAttendanceMutation";
import { useAsyncCallback } from "react-use-async-callback";
import { StaffNote } from "../../api/main/models/StaffNote";
import { useMemo } from "react";
import moment from "moment";
import { useDebounce } from "../../shared/useDebounce";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { Profile } from "../../api/main/models/Profile";
import { useConsumeStaffScheduledClassesSupportingDataViewModel } from "../../api/main/scheduledClasses/viewModels/useConsumeStaffScheduledClassesSupportingDataViewModel";
import { HappyIcon } from "../shared/Utilities/HappyIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RegisterComponentForCourtesyClassChild } from "./RegisterComponentForCourtesyClassChild";
import { ScheduledClassCancellation } from "../../api/main/models/ScheduledClassCancellation";
import { StyledModal } from "../shared/styledModal/StyledModal";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { TooltipWrapper } from "../shared/Utilities/TooltipWrapper";
import { PaymentState } from "../../api/main/models/constants/PaymentState";

export interface StaffNotesWithProfile extends StaffNote {
    profile?: Profile;
}

export interface StaffClassItemProps {
    item: ScheduledClassStaffWithScheduledClassClassStageAndSubStage | null | undefined,
    currentUserStaffId: string | null | undefined,
    dateInView: string,
    nextClassId: string | null | undefined,
    scheduledClassCancellations: Array<ScheduledClassCancellation> | undefined | null;
}

/**
 * StaffArea for consuming a single ScheduledClass and related details
 */
export const StaffClassItem = (props: StaffClassItemProps) => {
    const {
        item,
        currentUserStaffId,
        dateInView,
        nextClassId,
        scheduledClassCancellations
    } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Generate an array of ChildIds to pull the related staffNotes from the api
    let childIds: string[] = [];
    if (item?.scheduledClass?.scheduledClassChildren?.length) {
        childIds = item?.scheduledClass?.scheduledClassChildren?.map(scheduledClassChild => scheduledClassChild.childId);
    }

    // Generate an array of Unique MemberIds 
    let uniqueMemberIds: string[] = [];
    if (item?.scheduledClass?.scheduledClassChildren?.length) {
        uniqueMemberIds = [...new Set(item?.scheduledClass?.scheduledClassChildren?.map(scheduledClassChild => scheduledClassChild?.child?.memberId ?? ''))];
    }
    // Load the data
    const {
        data: {
            staffNotes: storeChildStaffNotes,
            profiles,
            courtesyClassCredits,
            locationNotes: storeLocationStaffNotes,
            memberNotes: storeMemberStaffNotes,
            scheduledClassNotes: storeScheduledClassStaffNotes
        }, errors: loadErrors
    } = useConsumeStaffScheduledClassesSupportingDataViewModel(childIds, item?.scheduledClassId, item?.scheduledClass?.classLocationId, uniqueMemberIds);
   
    // Load the ChildAttendance data
    const {
        data: {
            items: storeChildAttendances
        }
    } = useEditChildAttendancesViewModel(item?.scheduledClass?.id, dateInView);

    // Model (ChildAttendances)
    const childAttendancesManager = useChangesArray<ChildAttendance, string>(storeChildAttendances, item => item.id);
    const [saveChildAttendances] = useSaveChildAttendanceMutation();

    // Save ChildAttendances 
    const [save, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        for (const item of childAttendancesManager.added) {
            await saveChildAttendances(item.id, childAttendancesManager.changesFor(item.id), true);
        }

        for (const item of childAttendancesManager.updated) {
            await saveChildAttendances(item.id, childAttendancesManager.changesFor(item.id), false);
        }

        childAttendancesManager.markAsSaved();
    }, [childAttendancesManager]);

    // Allow ourselves to be saved after a delay.
    const saveDebounce = useDebounce(() => {
        save();
    }, { delay: 1000 });

    // Model (StaffNotes)
    const staffNotesManager = useChangesArray<StaffNote, string>(storeChildStaffNotes, item => item.id);
    const memberNotesManager = useChangesArray<StaffNote, string>(storeMemberStaffNotes, item => item.id);
    const childStaffNotes = useMemo(() => staffNotesManager.model.filter(it => it.showOnRegister).map(item => {
        const profile = profiles?.find(it => it.userId === item.updatedByUserId);

        return {
            ...item,
            profile
        };
    }), [staffNotesManager, profiles]);
    const memberStaffNotes = useMemo(() => memberNotesManager.model.filter(it => it.showOnRegister).map(item => {
        const profile = profiles?.find(it => it.userId === item.updatedByUserId);

        return {
            ...item,
            profile
        };
    }), [memberNotesManager, profiles]);
    const scheduledClassStaffNotes = useMemo(() => storeScheduledClassStaffNotes?.filter(it => it.showOnRegister).map(item => {
        const profile = profiles?.find(it => it.userId === item.updatedByUserId);

        return {
            ...item,
            profile
        };
    }), [storeScheduledClassStaffNotes, profiles]);
    const locationStaffNotes = useMemo(() => storeLocationStaffNotes?.filter(it => it.showOnRegister).map(item => {
        const profile = profiles?.find(it => it.userId === item.updatedByUserId);

        return {
            ...item,
            profile
        };
    }), [storeLocationStaffNotes, profiles]);

    // Handle opening the MapUrl
    const openMapUrl = (url: string | undefined) => {
        const fullUrl = getFullUrl(url);

        // If we don't have a URL to work with do nothing.
        if (!fullUrl) { return; }

        // Open in a new window.
        window.open(fullUrl);
    };

    // Handle toggling directions visibility
    const [isShowingDirections, toggleShowingDirections] = useToggleState(false);
    const [isShowingClassRegister, toggleShowingClassRegister] = useToggleState(false);

    // Navigate to lessonPlans - We can pass in classStageId, classTypeId, classSubStageId, and lessonNumber to open the correct card and scroll to the correct section.
    const openLessonPlans = (classStageId: string, classTypeId: string, classSubStageId: string, lessonNumber: string) => {
        if (!classStageId || !classTypeId || !classSubStageId || !lessonNumber) {
            return;
        }

        navigate(`/staff/lesson-plans?stage=${classStageId}&classType=${classTypeId}&subStage=${classSubStageId}&lessonNumber=${lessonNumber}`);
    };

    // Get the icon to display along with the classType
    const icon = useClassTypeForIcons(item?.classType?.name ?? '');

    // Check if this is the first time a Child is in a ScheduledClass
    const isFirstTimeInThisClass = (scheduledClassChild: ScheduledClassChildrenWithChildDetails) => {
        return scheduledClassChild.firstLessonDate >= moment(dateInView).toISOString();
    };

    // Check if the class is cancelled 
    const classIsCancelled = useMemo(() => {
        return !!scheduledClassCancellations?.find(it => moment(it.lessonDate).date() === moment(dateInView).date() && it.scheduledClassId === item?.scheduledClassId);
    }, [scheduledClassCancellations, dateInView, item]);

    // Check if we are a substitute for this class
    const areWeASubstituteForThisClass = useMemo(() => {
        if (item?.isSubsitute && moment(item?.subsituteStartDate).date() <= moment(dateInView).date() && moment(item?.subsituteEndDate).date() >= moment(dateInView).date()) {
            return true;
        }

        return false;
    }, [item, dateInView]);

    // Handle toggling the notes modal
    const [isNotesModalOpen, toggleNotesModalIsOpen] = useToggleState(false);

    // Render the UI
    //
    return (
        <ConditionalFragment showIf={!item?.isSubsitute || !!areWeASubstituteForThisClass}>
            <Card style={{ marginBottom: '10px' }} color={classIsCancelled ? 'danger' : nextClassId === item?.scheduledClassId ? 'success' : ''} outline>
                <CardHeader>
                    <AlertOnErrors
                        errors={[
                            loadErrors,
                            saveFormErrors
                        ]}
                    />

                    <ConditionalFragment showIf={isSaving}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>

                    <Row>
                        <Col>
                            <HappyIcon icon="scheduled-class-summary" />
                            <> </>
                            {getScheduledClassSummary(item?.scheduledClass, { classLocation: item?.scheduledClass?.classLocation })}
                        </Col>
                        <Col xs="auto">
                            <Badge color="primary">
                                <ConditionalFragment showIf={!!icon}>
                                    {icon}
                                </ConditionalFragment>
                                {item?.classType?.name}
                            </Badge>
                            <> </>
                            <ConditionalFragment showIf={!classIsCancelled}>
                                <Badge>
                                    {t('staffClassesTab.staffClassItem.attendingWithNumbers', 'Attending {{attending}} / {{maximumClassSize}}', {
                                        attending: (item?.scheduledClass?.scheduledClassChildren?.filter(it => it.paymentState === PaymentState.Paid).length ?? 0) + storeChildAttendances?.filter(it => it.scheduledClassId === item?.scheduledClassId && it.isCourtesyClassBooking).length,
                                        maximumClassSize: item?.scheduledClass?.maximumClassSize
                                    })}
                                </Badge>
                            </ConditionalFragment>

                            <ConditionalFragment showIf={!!classIsCancelled}>
                                <Badge color="danger">
                                    {t('staffClassesTab.staffClassItem.classIsCancelled', 'Cancelled')}
                                </Badge>
                            </ConditionalFragment>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            <HappyIcon icon="class-stage" />
                            <> </>
                            {item?.classStage?.name} {item?.classSubStage?.name}
                        </Col>

                        <Col>
                            <ConditionalFragment showIf={!!locationStaffNotes?.length || !!scheduledClassStaffNotes?.length}>
                                <TooltipWrapper targetId={`secret-input-${item?.id}`} icon="thumb-tack" color="secondary" content={item?.scheduledClass?.classLocation?.name} clickHandler={() => toggleNotesModalIsOpen()}>
                                    {t('staffClassesTab.staffClassItem.viewNotes', '{{count}} note{{plural}}', { count: locationStaffNotes?.length + scheduledClassStaffNotes?.length, plural: (locationStaffNotes?.length + scheduledClassStaffNotes?.length === 1) ? '' : 's' })}
                                </TooltipWrapper>
                                <> </>
                            </ConditionalFragment>
                            <ConditionalFragment showIf={!locationStaffNotes?.length && !scheduledClassStaffNotes?.length}>
                                {item?.scheduledClass?.classLocation?.name}
                            </ConditionalFragment>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <HappyIcon icon="lesson-number" />
                            <> </>
                            {t('staffClassesTab.staffClassItem.lessonNumber', 'Lesson {{lessonNumber}}', { lessonNumber: item?.scheduledClass?.currentLessonNumber })}
                        </Col>

                        <Col>
                            <HappyIcon icon="class-map" />
                            <> </>
                            <Button style={{ padding: '0' }} color="link" size="sm" onClick={() => openMapUrl(item?.scheduledClass?.classLocation?.mapUrl)}>
                                {t('staffClassesTab.staffClassItem.viewMap', 'View map')}
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <HappyIcon icon="class-role" />
                            <> </>
                            <ConditionalFragment showIf={!!item?.isSubsitute}>
                                <Badge color="warning">
                                    {t('', 'Substitute')}
                                </Badge>
                                <> </>
                            </ConditionalFragment>
                            {roleInClassDisplayName(item?.roleInClass ?? '', t)}
                        </Col>
                        <Col>
                            <ConditionalFragment showIf={!!isShowingDirections}>
                                <Col>
                                    <HtmlDisplay html={item?.scheduledClass?.classLocation?.directionsHtml ?? ''} />
                                </Col>
                            </ConditionalFragment>
                        </Col>
                    </Row>

                    <ConditionalFragment showIf={!!isShowingClassRegister}>
                        <h4 style={{ marginTop: '10px' }}>{t('staffClassesTab.staffClassItem.register.header', 'Class register')}</h4>
                        <Table striped responsive>
                            <thead>
                                <tr>
                                    <th className="col-xs-1 col-md-1">
                                        <p className="d-none d-md-block">
                                            <HappyIcon icon="class-attendance-check" />
                                        </p>
                                    </th>
                                    <th className="col-xs-3">
                                        <p className="d-none d-md-block">
                                            {t('staffClassesTab.staffClassItem.register.name.header', 'Name')}
                                        </p>
                                    </th>
                                    <th className="col-xs-3">
                                        <p className="d-none d-md-block">
                                            {t('staffClassesTab.staffClassItem.register.memberName.header', 'Member')}
                                        </p>
                                    </th>
                                    <th className="col-xs-3">
                                        <p className="d-none d-md-block">
                                            {t('staffClassesTab.staffClassItem.register.memberPhone.header', 'Phone')}
                                        </p>
                                    </th>
                                    <th className="col-xs-2">
                                        <p className="d-none d-md-block">
                                            {t('staffClassesTab.staffClassItem.register.healthNote.header', 'Health note')}
                                        </p>
                                    </th>
                                    <th className="col-xs-2">
                                        <p className="d-none d-md-block">
                                            {t('staffClassesTab.staffClassItem.register.staffNotes.header', 'Staff notes')}
                                        </p>
                                    </th>
                                    <th className="col-xs-2">
                                        <p className="d-none d-md-block">
                                            {t('staffClassesTab.staffClassItem.register.progressNotes.header', 'Progress notes')}
                                        </p>
                                    </th>
                                    <th className="col-xs-2">
                                        <p className="d-none d-md-block">
                                            {t('staffClassesTab.staffClassItem.register.birthday.header', 'Birthday')}
                                        </p>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {item?.scheduledClass?.scheduledClassChildren?.filter(it => moment(it.firstLessonDate).startOf('day') <= moment(dateInView).startOf('day') && it.paymentState === PaymentState.Paid)?.map(scheduledClassChild => (
                                    <RegisterComponent
                                        key={scheduledClassChild?.child?.id}
                                        child={scheduledClassChild?.child}
                                        childAttendancesManager={childAttendancesManager}
                                        scheduledClassId={item?.scheduledClassId ?? ''}
                                        currentUserStaffId={currentUserStaffId ?? ''}
                                        currentLessonNumber={item?.scheduledClass?.currentLessonNumber ?? ''}
                                        dateInView={dateInView}
                                        staffNotesManager={staffNotesManager}
                                        childStaffNotes={childStaffNotes}
                                        isFirstTimeInThisClass={isFirstTimeInThisClass(scheduledClassChild)}
                                        saveDebounce={saveDebounce}
                                        courtesyClassCredits={courtesyClassCredits}
                                        scheduledClass={item?.scheduledClass}
                                        memberStaffNotes={memberStaffNotes}
                                    />
                                ))}
                                {
                                    storeChildAttendances?.filter(it => it.isCourtesyClassBooking
                                        && it.scheduledClassId === item?.scheduledClassId
                                        && moment(it.lessonDate).startOf('day').isSame(moment(dateInView).startOf('day'))
                                    ).map(courtesyClassChild => (
                                        <RegisterComponentForCourtesyClassChild
                                            key={courtesyClassChild?.id}
                                            childId={courtesyClassChild.childId}
                                            childAttendancesManager={childAttendancesManager}
                                            scheduledClassId={item?.scheduledClassId ?? ''}
                                            currentUserStaffId={currentUserStaffId ?? ''}
                                            currentLessonNumber={item?.scheduledClass?.currentLessonNumber ?? ''}
                                            dateInView={dateInView}
                                            staffNotesManager={staffNotesManager}
                                            allStaffNotes={childStaffNotes}
                                            isFirstTimeInThisClass={false}
                                            saveDebounce={saveDebounce}
                                            courtesyClassCredits={courtesyClassCredits}
                                            scheduledClass={item?.scheduledClass}
                                            memberStaffNotes={memberStaffNotes}
                                        />
                                    ))
                                }
                            </tbody>
                        </Table>
                    </ConditionalFragment>
                </CardBody>

                <CardFooter>
                    <Row>
                        <Col>
                            <ButtonGroup>
                                <Button size="sm" color="primary" outline={!isShowingClassRegister} onClick={() => toggleShowingClassRegister()}>
                                    {isShowingClassRegister ? (
                                        <>
                                            <FontAwesomeIcon icon="eye-slash" />
                                            <> </>
                                            {t('staffClassesTab.staffClassItem.hideRegister', 'Hide register')}
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon icon="eye" />
                                            <> </>
                                            {t('staffClassesTab.staffClassItem.showRegister', 'Show register')}
                                        </>
                                    )
                                    }
                                </Button>
                                <Button size="sm" color="primary" outline>
                                    <a style={{ textDecoration: 'none' }} href={`/api/exportClassRegister/Export/${item?.scheduledClassId}?format=${encodeURIComponent('xlsx')}`} download>
                                        <FontAwesomeIcon icon="download" />
                                        <> </>
                                        {t('staffClassesTab.exportToExcel.export', 'Export')}
                                    </a>
                                </Button>
                            </ButtonGroup>
                        </Col>
                        <Col xl="auto">
                            <ButtonGroup>
                                <Button size="sm" color="primary" outline onClick={() => openLessonPlans(item?.classStage?.id ?? '', item?.classType?.id ?? '', item?.classSubStage?.id ?? '', item?.scheduledClass?.currentLessonNumber.toString() ?? '')}>
                                    <FontAwesomeIcon icon="book-open" />
                                    <> </>
                                    {t('staffClassesTab.staffClassItem.viewLessonPlans', 'View lesson plans')}
                                </Button>
                                <Button size="sm" color="primary" outline={!isShowingDirections} onClick={() => toggleShowingDirections()}>
                                    <HappyIcon icon="class-map" />
                                    <> </>
                                    {isShowingDirections ? t('staffClassesTab.staffClassItem.hideDirections', 'Hide directions') : t('staffClassesTab.staffClassItem.showDirections', 'Show directions')}
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </CardFooter>
            </Card>

            {/* Modal for ClassLocation and ScheduledClass notes */}
            <ConditionalFragment showIf={isNotesModalOpen}>
                <StyledModal
                    isOpen={isNotesModalOpen}
                    toggle={() => toggleNotesModalIsOpen()}
                    size="md">

                    <ModalHeader toggle={() => toggleNotesModalIsOpen()}>
                        <h2>
                            {t('staffClassesTab.staffClassItem.modal.header', 'Location and Class notes')}
                        </h2>
                    </ModalHeader>

                    <ModalBody>
                        <h6>{t('staffClassesTab.staffClassItem.modal.locationNotes.header', 'Location notes for {{locationName}}', { locationName: item?.scheduledClass?.classLocation?.name })}</h6>
                        <ConditionalFragment showIf={!locationStaffNotes?.length}>
                            <NoResultsFound />
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!!locationStaffNotes?.length}>
                            <ListGroup>
                                {locationStaffNotes?.map((note, index) => (
                                    <ListGroupItem key={note?.id + index}>
                                        <Row>
                                            <Col>
                                                <HtmlDisplay html={note.contentsHtml} key={note.id + index} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="text-muted" style={{ fontWeight: 'bold' }}>
                                                {t('staffClassesTab.staffClassItem.modal.updatedOn', 'Updated on ')}
                                                {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(note.updatedDate) })}
                                                {t('staffClassesTab.staffClassItem.modal.by', ' by ')}
                                                {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: note?.profile?.firstName, lastName: note?.profile?.lastName })}
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                        </ConditionalFragment>

                        <h6 style={{ marginTop: '1rem' }}>{t('staffClassesTab.staffClassItem.modal.scheduledClassNotes.header', 'Class notes for {{classSummary}}', { classSummary: getScheduledClassSummary(item?.scheduledClass, { classLocation: item?.scheduledClass?.classLocation }) })}</h6>
                        <ConditionalFragment showIf={!scheduledClassStaffNotes?.length}>
                            <NoResultsFound />
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!scheduledClassStaffNotes?.length}>
                            <ListGroup>
                                {scheduledClassStaffNotes?.map((note, index) => (
                                    <ListGroupItem key={note?.id + index}>
                                        <Row>
                                            <Col>
                                                <HtmlDisplay html={note.contentsHtml} key={note.id + index} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="text-muted" style={{ fontWeight: 'bold' }}>
                                                {t('staffClassesTab.staffClassItem.modal.updatedOn', 'Updated on ')}
                                                {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(note.updatedDate) })}
                                                {t('staffClassesTab.staffClassItem.modal.by', ' by ')}
                                                {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: note?.profile?.firstName, lastName: note?.profile?.lastName })}
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                        </ConditionalFragment>
                    </ModalBody>

                    <ModalFooter>
                        <Col xs="auto">
                            <Button color="primary" onClick={() => toggleNotesModalIsOpen()}>{t('common.close', 'Close')}</Button>
                        </Col>
                    </ModalFooter>
                </StyledModal>
            </ConditionalFragment>
        </ConditionalFragment>
    );
};