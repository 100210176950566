
/**
 * Rules for a password.
 * 
 * This is designed to mirror PasswordOptions from ASP.NET Core Identity.
 */
export interface PasswordOptions {
    requireDigit: boolean,
    requiredLength: number,
    requireLowercase: boolean,
    requireNonAlphanumeric: boolean,
    requiredUniqueChars: number,
    requireUppercase: boolean
}

/**
 * Default PasswordOptions used when no custom options are supplied.
 * 
 * The default rules match those for ASP.NET Core Identity to make it out-of-the-box compatible with backends using
 * ASP.NET Core identity, however there is no dependency on it and it can be used with any backend.
 */
export const defaultPasswordOptions = {
    requireDigit: true,
    requiredLength: 6,
    requireLowercase: true,
    requireNonAlphanumeric: true,
    requiredUniqueChars: 1,
    requireUppercase: true
} as PasswordOptions;
