import * as React from 'react';
import { atom, useRecoilState } from 'recoil';

export interface SidebarState {
    visible: boolean,
    minimized: boolean,
    suggested: boolean,
}

export const sidebarState = atom<SidebarState>({
    key: 'sidebarState',
    default: {
        visible: false,
        minimized: false,
        suggested: false,
    },
    //effects_UNSTABLE: [persistAtom], // Don't persist to between sessions.
});

/**
 * Global sidebar state used by the layout.
 */
export function useSidebarState(): [SidebarState, React.Dispatch<React.SetStateAction<SidebarState>>] {
    const [value, setValue] = useRecoilState(sidebarState);
    return [value, setValue];
}