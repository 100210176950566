import { gql, useLazyQuery } from "@apollo/client";
import { Guid } from "guid-string";
import React from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { ConsumeChildClassesTabViewModelQuery, ConsumeChildClassesTabViewModelQueryVariables } from '../../generated/graphql';
import { childAttendanceFieldsFragment } from "../../models/ChildAttendance";
import { classLocationFieldsFragment } from "../../models/ClassLocation";
import { classStageFieldsFragment } from "../../models/ClassStage";
import { classSubStageFieldsFragment } from "../../models/ClassSubStage";
import { classTypeFieldsFragment } from "../../models/ClassType";
import { profileFieldsFragment } from "../../models/Profile";
import { scheduledClassFieldsFragment } from "../../models/ScheduledClass";
import { scheduledClassChildFieldsFragment } from "../../models/ScheduledClassChild";
import { scheduledClassStaffFieldsFragment } from "../../models/ScheduledClassStaff";

export function useConsumeChildClassesTabViewModel(childId: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<ConsumeChildClassesTabViewModelQuery> {
    // Query the data we need from the api
    const [load, { data, loading, error, refetch }] = useLazyQuery<ConsumeChildClassesTabViewModelQuery, ConsumeChildClassesTabViewModelQueryVariables>(
        gql`
        query consumeChildClassesTabViewModel ($childId: ID) {
            items: scheduledClassChildren (childId: $childId, isOnWaitingList: false) {
                ...scheduledClassChildFields

                scheduledClass {
                    ...scheduledClassFields

                    lessonDatesForCurrentTerm

                    scheduledClassStaffs {
                        ...scheduledClassStaffFields
                    }

                }
            }

            classLocations {
                ...classLocationFields
            }

            classTypes {
                ...classTypeFields
            }

            profiles (isStaff: true) {
                ...profileFields
            }

            classStages {
                ...classStageFields
            }

            classSubStages {
                ...classSubStageFields
            }

            childAttendances (childId: $childId) {
                ...childAttendanceFields

                scheduledClass {
                    ...scheduledClassFields

                    scheduledClassStaffs {
                        ...scheduledClassStaffFields
                    }

                }
            }
        }

        ${scheduledClassChildFieldsFragment}
        ${scheduledClassFieldsFragment}
        ${classLocationFieldsFragment}
        ${classTypeFieldsFragment}
        ${scheduledClassStaffFieldsFragment}
        ${profileFieldsFragment}
        ${classSubStageFieldsFragment}
        ${childAttendanceFieldsFragment}
        ${classStageFieldsFragment}
        `,
        {
            variables: {
                childId: childId ?? Guid.empty,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error,]);
};