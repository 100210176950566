import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment, { isMoment } from 'moment';
import numeral from 'numeral';
import { isNumber } from 'util';
import { findLanguage } from './languageConfig';
import { localeResources } from '../locales/localeResources';

/**
 * Configure the i18n layer using i18next.
 */
export function configurei18n() {
    i18n
        // detect user language
        // learn more: https://github.com/i18next/i18next-browser-languageDetector
        .use(LanguageDetector)
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
            fallbackLng: 'en-GB',
            //debug: true,

            resources: {
                ...localeResources,
            },

            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default

                format: function (value, format, lang) {
                    // Handle dates with moment.
                    if (value instanceof Date) {
                        return moment(value).format(format);
                    } else if (isMoment(value)) {
                        return moment(value).format(format);
                    }

                    // Handle numbers with numeral.
                    if (value instanceof Number) {
                        return numeral(value).format(format);
                    } else if (isNumber(value)) {
                        return numeral(value).format(format);
                    }

                    return value;
                }
            }
        });

    // Keep the moment and numeral formats in sync with the i18n formats.
    i18n.on('languageChanged', (languageCode) => {
        // Find the mapped language for the new language.
        const thisLanguage = findLanguage(languageCode);

        moment.locale(thisLanguage?.momentLocale ?? languageCode);
        numeral.locale(thisLanguage?.numeralLocale ?? languageCode);
    });

    return i18n;
}
