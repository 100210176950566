import { useCallback, useEffect, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Alert, Badge, Button, ButtonDropdown, ButtonGroup, CardSubtitle, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Row } from "reactstrap";
import { useReplaceSearchParamsEffect, useSearchParams } from "../../shared/useURLSearchParams";
import { Banner } from "../shared/banner/Banner";
import { CardsOrTableToggle } from "../shared/cardsOrTable/CardsOrTableToggle";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { SearchInput } from "../shared/searchInput/SearchInput";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useScheduledClassForClassRegistersViewModel } from "../../api/main/scheduledClasses/viewModels/useScheduledClassesForClassRegistersViewModel";
import { getScheduledClassSummary } from "../scheduledClass/getScheduledClassSummary";
import { CardsOrTable } from "../shared/cardsOrTable/CardsOrTable";
import { usePreferredListViewMode } from "../../globalState/preferredListViewMode/usePreferredListViewMode";
import moment from "moment";
import { HappyIcon } from "../shared/Utilities/HappyIcon";
import { useNavigate, useParams } from "react-router";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { ScheduledClass } from "../../api/main/models/ScheduledClass";
import { ClassLocation } from "../../api/main/models/ClassLocation";
import { ScheduledClassChild } from "../../api/main/models/ScheduledClassChild";
import { Child } from "../../api/main/models/Child";
import { useLocalStorage } from "../../shared/useLocalStorage";
import { useAsyncCallback } from "react-use-async-callback";
import { StyledModal } from "../shared/styledModal/StyledModal";
import { useToggleState, useToggleStateArray } from "use-toggle-state";
import { PaymentState } from "../../api/main/models/constants/PaymentState";
import { MoveChildModal, MoveChildModalResultMessages } from "../shared/moveChildModal/MoveChildModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getPaymentStateBadge } from "../shared/Utilities/getPaymentStateBadge";
import { useRemoveFromScheduledClassMutation } from "../../api/main/scheduledClassChildren/useRemoveFromScheduledClassMutation";
import { AddToRegisterModal } from "../shared/registerModal/AddToRegisterModal";
import { useAddToScheduledClassMutation } from "../../api/main/scheduledClassChildren/useAddToScheduledClassMutation";
import { useMoveToScheduledClassWaitingListMutation } from "../../api/main/scheduledClassChildren/useMoveToWaitingListScheduledClassMutation";
import { getAgeFromDateOfBirth } from "../../utilities/getAgeFromDateOfBirth";
import { TermSelector } from "../shared/termSelector/TermSelector";
import { ClassScheduleFilterState } from "../scheduledClass/schedule/ClassSchedule";
import { useCurrentTerm } from "../../api/main/terms/useCurrentTerm";
import { useManuallyMarkAsPaidMutation } from "../../api/main/payments/useManuallyMarkAsPaidMutation";

export interface ScheduledClassWithChildrenAndClassLocationDetails extends ScheduledClass {
    classLocation?: ClassLocation,
    scheduledClassChildren?: Array<ScheduledClassChildWithDetails>,
}

export interface ScheduledClassChildWithDetails extends ScheduledClassChild {
    child: Child;
}

export const ClassRegistersUnpaid = () => {
    const { t } = useTranslation();

    return (
        <ClassRegistersListCore
            title={t('classRegistersUnpaid.title', 'Class registers (unpaid)')}
            restrictToUnpaid={true}
            includeAllOption={true}
            allowExports={true}
        />
    );
};

export const ClassRegistersList = () => {
    const { t } = useTranslation();

    return (
        <ClassRegistersListCore
            title={t('classRegisters.title', 'Class registers')}
            includePleaseSelectOption={true}
            allowExports={true}
        />
    );
};

export const ClassRegistersListForScheduledClassView = (scheduledClassId: any) => {
    const { t } = useTranslation();

    return (
        <ClassRegistersListCore
            title={t('classRegistersListForScheduledClassView.title', 'Class registers')}
            includePleaseSelectOption={false}
            isScheduledClassView={true}
            scheduledClassIdToFilter={scheduledClassId ?? ''}
        />
    );
};

export interface ClassRegistersListCoreProps {
    restrictToUnpaid?: boolean,
    includeAllOption?: boolean,
    includePleaseSelectOption?: boolean,
    isScheduledClassView?: boolean,
    scheduledClassIdToFilter?: string,
    title: string,
    restrictToSchduledClassId?: string | undefined | null,
    titleIsHidden?: boolean,
    allowExports?: boolean;
}


/**
 * List of all ClassRegisters
 */
export const ClassRegistersListCore = (props: ClassRegistersListCoreProps) => {
    const {
        title,
        restrictToUnpaid = false,
        includeAllOption = false,
        includePleaseSelectOption = false,
        isScheduledClassView = false,
        restrictToSchduledClassId,
        titleIsHidden,
        allowExports = false,
    } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string; }>();

    // Load the data
    const {
        data: {
            items: _items,
            classStages,
            classSubStages,
            classTypes
        }, isLoading: isLoadingData, errors: loadErrors, refresh
    } = useScheduledClassForClassRegistersViewModel();

    // Load the current term
    const {
        data: {
            model: currentTerm
        }, isLoading: isLoadingCurrentTerm, errors: currentTermLoadErrors
    } = useCurrentTerm();
    const isLoading = isLoadingData || isLoadingCurrentTerm;

    // Use the preferred view mode for lists.
    const [viewMode] = usePreferredListViewMode();

    const [markAsPaid, { errors: markAsPaidErrors }] = useManuallyMarkAsPaidMutation();
    const [markAsPaidForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async (childId: string, scheduledClassId: string) => {
        if (!childId || !scheduledClassId) return;

        await markAsPaid(childId, scheduledClassId);

        refresh();
    }, []);

    // Search.
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');

    // Keep the URL up to date with the search text.
    useReplaceSearchParamsEffect({ search: search });

    // Allow filtering (persisted to local storage between sessions).
    const [filterState, setFilterState] = useLocalStorage<ClassScheduleFilterState>('classSchedule.filterState', {});

    // Filter the classes by the TermId
    const items = useMemo(() => {
        let ret = (_items ?? []);

        if (filterState.termId) {
            ret = ret?.filter(item => item.termId === filterState.termId);
        }

        return ret;
    }, [_items, filterState]);

    // State management for the selected ScheduledClass
    // NOTE we persist this and share it between the resgister and waiting list so a user swapping between them gets
    // the experience they would expect.
    const [_scheduledClassId, setScheduledClassId] = useLocalStorage<string>('classRegisterAndWaitingList.selectedClassId', '');

    const scheduledClassId = id ?? restrictToSchduledClassId ?? _scheduledClassId ?? '';
    const isRestrictedToOneClass = !!restrictToSchduledClassId;
    const hideTitle = !!titleIsHidden;

    // Filter by selected ScheduledClass
    const scheduledClass = useMemo(() => items?.find(item => item.id === scheduledClassId), [items, scheduledClassId]);

    // Handle navigating to a Child's StaffNotesTab or HealthNotes
    const gotoChildNotes = (id: string, tab?: string) => {
        if (!id) {
            return;
        }
        if (tab) {
            navigate(`/administration/children-management/children/edit/${id}?tab=${tab}`);
        } else {
            navigate(`/administration/children-management/children/edit/${id}`);
        }
    };

    // Track if we are viewing all ScheduledClass Registers
    const isAllSelected = scheduledClassId === 'all';

    // Filtering by the search
    const scheduledClassChildren = useMemo(() => {
        // If viewing all classes and restricting to unpaid, then we only want to show classes with a payment state not equal to Paid
        if (isAllSelected && restrictToUnpaid) {
            let res: Array<ScheduledClassChildWithDetails> = [];

            items?.forEach(item => {
                return res.push(...item?.scheduledClassChildren.filter(it => !it.isOnWaitingList && it.paymentState !== PaymentState.Paid) || []);
            });

            return res;
        }

        let ret;

        // Filtering for a single class
        if (restrictToUnpaid) {
            // If restrictToUnpaid
            ret = (scheduledClass?.scheduledClassChildren.filter(it => !it.isOnWaitingList && it.paymentState !== PaymentState.Paid) || []);
        } else {
            // Showing only paid
            ret = (scheduledClass?.scheduledClassChildren.filter(it => !it.isOnWaitingList) || []);
        }

        let lowerSearch = search.toLocaleLowerCase();

        if (lowerSearch) {
            ret = ret?.filter(item =>
                item.child.firstName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || item.child.lastName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            );
        }

        // Sort by the child name.
        ret.sort((a, b) => {
            if (a.child.firstName === b.child.firstName) {
                if (a.child.lastName === b.child.lastName) {
                    return 0;
                } else if (a.child.lastName < b.child.lastName) {
                    return -1;
                } else {
                    return 1;
                }
            } else if (a.child.firstName < b.child.firstName) {
                return -1;
            } else {
                return 1;
            }
        });

        return ret;
    }, [scheduledClass, search, items, isAllSelected, restrictToUnpaid]);

    // Showing of the RegisterModal
    const [registerModalIsOpen, _toggleRegisterModal] = useToggleState();
    const toggleRegisterModal = useCallback(() => {
        _toggleRegisterModal();
    }, [_toggleRegisterModal]);

    // Showing of the MoveChildModal
    const [scheduledClassChildToMove, setScheduledClassChildToMove] = useState<string>();
    const moveCub = (scheduledClassChildId: string) => {
        if (!scheduledClassChildId) {
            return;
        }
        setScheduledClassChildToMove(scheduledClassChildId);
        toggleMoveChildModal();
    };
    const [moveChildModalIsOpen, _toggleMoveChildRegisterModal] = useToggleState();
    const toggleMoveChildModal = useCallback(() => {
        _toggleMoveChildRegisterModal();
    }, [_toggleMoveChildRegisterModal]);

    // Message state from the various actions.
    const [actionMessages, setActionMessages] = useState<MoveChildModalResultMessages>({ errorMessage: undefined, warningMessage: undefined, successMessage: undefined });

    // Handle adding of children when the modal closes.
    const [addToScheduledClassMutation, { errors: addToScheduledClassMutationErrors }] = useAddToScheduledClassMutation();
    const [onRegisterModalClosed, { isExecuting: isAdding, errors: addErrors }] = useAsyncCallback(async (event: { selectedIds: Array<string>, cancelled: boolean, reflectChangesInFutureTerms: boolean; }) => {
        if (event.cancelled || !event.selectedIds || !event.selectedIds.length) {
            return;
        }

        if (!scheduledClass) {
            return;
        }

        // For each selected child, add them to the class.
        for (const selectedId of event.selectedIds) {
            await addToScheduledClassMutation(selectedId, scheduledClass.id, event.reflectChangesInFutureTerms);
        }

        // Show a success message.
        setActionMessages({
            successMessage: t('classRegisterListCore.addCubToClass.thisTerm', '{{count}} Cub(s) have been added to the class', { count: event.selectedIds.length }),
            warningMessage: undefined,
            errorMessage: undefined,
        });

        // Refresh the list.
        refresh();
    }, [addToScheduledClassMutation, scheduledClass, setActionMessages, t]);

    // Handle moving the child to the waiting list.
    const [moveToWaitingListMutation, { errors: moveToWaitingListMutationErrors }] = useMoveToScheduledClassWaitingListMutation();
    const [moveToWaitingList, { errors: moveToWaitingListErrors }] = useAsyncCallback(async (scheduledClassChildId: string) => {
        await moveToWaitingListMutation(scheduledClassChildId);

        setActionMessages({
            successMessage: t('classRegisterListCore.moveCubToWaitingList.thisTerm', 'Cub has been moved to the waiting list from the class'),
            warningMessage: undefined,
            errorMessage: undefined,
        });

        // Refresh the list.
        refresh();
    }, [moveToWaitingListMutation, setActionMessages, t]);

    // Removing a child from the register.
    const [removeMutation, { errors: removeMutationErrors }] = useRemoveFromScheduledClassMutation();
    const [remove, { errors: removeErrors }] = useAsyncCallback(async (scheduledClassChildId: string, options: { nextTerm: boolean, }) => {
        await removeMutation(scheduledClassChildId, options.nextTerm);

        // Show a success message.
        setActionMessages({
            successMessage: options.nextTerm ? t('classRegisterListCore.removedCub.nextTerm', 'Cub will be removed from the class at the end of term')
                : t('classRegisterListCore.removedCubFromClass.thisTerm', 'Cub has been removed from the class'),
            warningMessage: undefined,
            errorMessage: undefined,
        });

        // Refresh the list.
        refresh();
    }, [removeMutation, setActionMessages, t]);

    // This hook can be used by Ambidect admins to update the FirstLessonDate with the result of realFirstLessonDate service method to set incorrectly set dates correctly.
    //const [setRealFirstLessonDates, { errors: setRealFirstLessonDatesErrors }] = useSetRealFirstLessonDatesMutation();
    //const [setRealFirstLessonDatesForm, { isExecuting: isSettingRealFirstLessonDates, errors: setRealFirstLessonDatesFormErrors }] = useAsyncCallback(async () => {
    //    if (!filterState?.termId) return;

    //    const result: { success: boolean; } = await setRealFirstLessonDates(filterState?.termId);

    //    if (result) {
    //        if (result.success) {
    //            // Show a success message.
    //            setActionMessages({
    //                successMessage: t('classRegisterListCore.setRealFirstLessonDates.success', 'Real first lesson dates have been set for this term'),
    //                warningMessage: undefined,
    //                errorMessage: undefined,
    //            });
    //        } else {
    //            console.log('Something went wrong');
    //        }
    //    }

    //    // Refresh the list.
    //    refresh();
    //}, [filterState?.termId]);

    // Dropdown toggle
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();

    // Set the default filter state to the current term.
    useEffect(() => {
        if (!filterState.termId) {
            setFilterState({ termId: currentTerm?.id });
        }
    }, [filterState, currentTerm?.id, setFilterState]);

    // Note: Want to send the scheduledClassId and childId to the backend to locate the payment and scheduledClassChild and mark them as paid, whilst adding a note to a cub to explain who marked this as paid manually and when.

    // Render the UI
    //
    return (
        <>
            <ConditionalFragment showIf={!isScheduledClassView}>
                <Banner fluid>
                    <StickyToolbar>
                        <Row>
                            <ConditionalFragment showIf={!hideTitle}>
                                <Col>
                                    <h1>
                                        {title}
                                    </h1>
                                </Col>
                            </ConditionalFragment>

                            <ConditionalFragment showIf={isLoading}>
                                <Col xs="auto">
                                    <LoadingIndicator size="sm" />
                                </Col>
                            </ConditionalFragment>

                            <ConditionalFragment showIf={!hideTitle}>
                                <Col xs="auto">
                                    <CardsOrTableToggle />
                                </Col>
                            </ConditionalFragment>
                        </Row>

                        <Row>
                            <ConditionalFragment showIf={!isRestrictedToOneClass}>
                                <Col xs="auto">
                                    <TermSelector selectedTermId={filterState.termId} setFilterState={({ termId: value }) => setFilterState({ termId: value })} restrictToTermsWithClasses={true} showPreviousTerms={true} />
                                </Col>
                                <Col>
                                    <Input name="scheduledClassSelector" type="select" value={scheduledClassId} onChange={(e) => setScheduledClassId(e.currentTarget.value)}>
                                        {
                                            includeAllOption ? (
                                                <option value="all">{t('classRegistersListUnpaid.scheduledClassSelector.all', 'All')}</option>
                                            ) : null
                                        }

                                        {
                                            includePleaseSelectOption ? (
                                                <option value="">{t('classRegistersListUnpaid.scheduledClassSelector.pleaseSelect', '(Please select a class)')}</option>
                                            ) : null
                                        }

                                        {
                                            items?.map((it, index) => {
                                                const myChildren = it?.scheduledClassChildren;
                                                const myRegisterChildren = myChildren?.filter(childLink => !childLink.isOnWaitingList);
                                                const myWaitingChildren = myChildren?.filter(childLink => childLink.isOnWaitingList);
                                                const myUnpaidChildren = myRegisterChildren?.filter(childLink => childLink?.paymentState !== PaymentState.Paid);

                                                // UI
                                                return (
                                                    <option key={it.id} value={it.id}>
                                                        {t(
                                                            'classRegistersList.scheduledClassSelector.itemTextWithUnpaid',
                                                            '({{classType}}) {{classSummary}} [Register: {{register}}, Unpaid: {{unpaid}}, Spaces: {{spaces}}, Waiting: {{waiting}}]',
                                                            {
                                                                classSummary: getScheduledClassSummary(it, { classLocation: it.classLocation, classStage: classStages?.find(item => item.id === it.currentClassStageId), classSubStage: classSubStages?.find(item => item.id === it.currentClassSubStageId) }),
                                                                register: myRegisterChildren?.length ?? 0,
                                                                spaces: (it.maximumClassSize - (myRegisterChildren?.length ?? 0)),
                                                                waiting: myWaitingChildren?.length ?? 0,
                                                                unpaid: myUnpaidChildren?.length ?? 0,
                                                                classType: classTypes?.find(item => item.id === it.classTypeId)?.name,
                                                            }
                                                        )}
                                                    </option>
                                                );
                                            })
                                        }
                                    </Input>
                                </Col>
                            </ConditionalFragment>
                            <ConditionalFragment showIf={!!scheduledClass}>
                                <Col>
                                    <SearchInput placeholder={t('classRegistersListUnpaid.search.placeholder', 'Search cubs')} value={search} onChange={e => setSearch(e.currentTarget.value)} />
                                </Col>
                            </ConditionalFragment>
                        </Row>
                    </StickyToolbar>
                </Banner>
            </ConditionalFragment>

            <MainContainer fluid>
                <AlertOnErrors
                    errors={[
                        loadErrors, currentTermLoadErrors,
                        addErrors, addToScheduledClassMutationErrors,
                        removeErrors, removeMutationErrors,
                        moveToWaitingListErrors, moveToWaitingListMutationErrors,
                        markAsPaidErrors, saveFormErrors
                    ]}
                />


                {/* Export button */}
                <ConditionalFragment showIf={!!allowExports}>
                    <Row>
                        <Col>
                            {/*<ButtonAsync type="button" color="success" onClick={() => setRealFirstLessonDatesForm()}*/}
                            {/*    isExecuting={isSettingRealFirstLessonDates}*/}
                            {/*    executingChildren={<><Spinner size="sm" />{t('common.sending', 'Setting...')}</>}>*/}
                            {/*    {t('common.setRealFirstLessonDates', 'Set real first lesson dates')}*/}
                            {/*</ButtonAsync>*/}
                        </Col>

                        <Col xs="auto">
                            <Button color="link">
                                <a className="btn btn-outline-primary" href={`/api/exportclassregisters/Export/${filterState.termId}?format=${encodeURIComponent('xlsx')}&restrictToUnpaid=${restrictToUnpaid}`} download>
                                    {t('classRegisterListCore.register.download', 'Export')}
                                </a>
                            </Button>
                        </Col>
                    </Row>
                </ConditionalFragment>

                {/* MoveChildModalResultMessages */}
                <ConditionalFragment showIf={
                    !!actionMessages.errorMessage ||
                    !!actionMessages.warningMessage ||
                    !!actionMessages.successMessage
                }>
                    <Alert color={
                        actionMessages.errorMessage ? 'danger' :
                            actionMessages.warningMessage ? 'warning' : 'success'
                    }>
                        <ConditionalFragment showIf={!!actionMessages.successMessage}>
                            {actionMessages.successMessage}
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!actionMessages.warningMessage}>
                            {actionMessages.warningMessage}
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!actionMessages.errorMessage}>
                            {actionMessages.errorMessage}
                        </ConditionalFragment>
                    </Alert>
                </ConditionalFragment>

                <ConditionalFragment showIf={!!scheduledClassChildren.length}>
                    <CardsOrTable
                        viewMode={viewMode}
                        items={scheduledClassChildren as Array<ScheduledClassChildWithDetails>}
                        tableHeadings={[
                            null /* Handles the card specific function for which we don't want a table column. */,
                            t('classRegistersListUnpaid.name.heading', 'Name'),
                            isAllSelected ? t('', 'Class') : null,
                            t('classRegistersListUnpaid.dateOfBirth.heading', 'Date of birth'),
                            t('classRegistersListUnpaid.dateOfRegistration.heading', 'Registered'),
                            t('classRegistersListUnpaid.healthNotesAvailable.heading', 'Health notes'),
                            t('classRegistersListUnpaid.firstLessonDate.heading', 'First lesson'),
                            t('classRegistersListUnpaid.lastLessonDate.heading', 'Leaving class on'),
                            t('classRegistersListUnpaid.paymentStatus.heading', 'Payment status'),
                        ]}
                        columns={[
                            (item, view) => {
                                // This column doesn't show in the table.
                                if (view !== 'cards') {
                                    return null;
                                }

                                return (
                                    <>
                                        <CardTitle>
                                            <HappyIcon icon="health-notes" />
                                            <> </>
                                            {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.child.firstName, lastName: item.child.lastName })}
                                        </CardTitle>
                                        <CardSubtitle>
                                            <Row>
                                                <Col>
                                                    <HappyIcon icon="birthday" />
                                                    <> </>
                                                    {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.child.dateOfBirth) })}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <HappyIcon icon="calendar" />
                                                    <> </>
                                                    {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.firstLessonDate) })}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    {getPaymentStateBadge(item?.paymentState as PaymentState, t)}
                                                </Col>
                                            </Row>
                                        </CardSubtitle>
                                    </>
                                );
                            },

                            // The remaining columns are for table mode only.

                            // Name
                            (item, view) => {
                                if (view !== 'table') {
                                    return null;
                                }

                                return (
                                    <Button color="link" onClick={() => navigate(`/administration/children-management/children/edit/${item.childId}`)} style={{ textAlign: 'left' }}>
                                        {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.child.firstName, lastName: item.child.lastName })}
                                    </Button>
                                );
                            },

                            // Class
                            (item, view) => {
                                if (view !== 'table' || !isAllSelected) {
                                    return null;
                                }

                                const scheduledClass = _items.find(it => it.id === item.scheduledClassId);

                                return getScheduledClassSummary(scheduledClass, { classLocation: scheduledClass?.classLocation });
                            },

                            // Date of birth
                            (item, view) => {
                                if (view !== 'table') {
                                    return null;
                                }

                                return (
                                    <>
                                        <div>
                                            {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.child.dateOfBirth) })}
                                        </div>
                                        <div>
                                            <Badge>
                                                {t('common.ageForDateOfBirth', 'Age {{years}} years {{months}} months', getAgeFromDateOfBirth(item.child.dateOfBirth))}
                                            </Badge>
                                        </div>
                                    </>
                                );
                            },

                            // Registered date
                            (item, view) => {
                                if (view !== 'table') {
                                    return null;
                                }

                                return t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.child.registeredDate) });
                            },

                            // HealthNotes
                            (item, view) => {
                                if (view !== 'table') {
                                    return null;
                                }

                                return item.child.healthNoteHtml ? <HappyIcon icon="health-notes" clickHandler={() => gotoChildNotes(item.childId)} /> : "";
                            },

                            // First lesson
                            (item, view) => {
                                if (view !== 'table') {
                                    return null;
                                }

                                if (!item.firstLessonDate) {
                                    return '';
                                }

                                return (
                                    <>
                                        <div>
                                            {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.firstLessonDate) })}
                                        </div>
                                        <ConditionalFragment showIf={moment().toISOString() < moment(item.firstLessonDate).toISOString()}>
                                            <div className="text-success" style={{ fontSize: '0.7rem', }}>
                                                {t('classRegisterListCore.joinsInFutureWarning', 'Joins on this future date')}
                                            </div>
                                        </ConditionalFragment>
                                    </>
                                );
                            },

                            // Last lesson
                            (item, view) => {
                                if (view !== 'table') {
                                    return null;
                                }

                                if (!item.lastLessonDate) {
                                    return '';
                                }

                                return (
                                    <>
                                        <div>
                                            {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.lastLessonDate) })}
                                        </div>
                                        <ConditionalFragment showIf={moment().toISOString() < moment(item.firstLessonDate).toISOString()}>
                                            <div className="text-danger" style={{ fontSize: '0.7rem', }}>
                                                {t('classRegisterListCore.leavesInFutureWarning', 'Leaving on this future date')}
                                            </div>
                                        </ConditionalFragment>
                                    </>
                                );
                            },

                            // PaymentState
                            (item, view) => {
                                if (view !== 'table') {
                                    return null;
                                }

                                return getPaymentStateBadge(item?.paymentState as PaymentState, t);
                            },
                        ]}
                        buttons={item => (
                            <ButtonGroup>
                                <Button color="primary" onClick={() => moveCub(item.id)}>
                                    <FontAwesomeIcon icon="arrow-left-long" />
                                    <> </>
                                    {t('classRegistersListUnpaid.moveCub', 'Move cub')}
                                </Button>
                                <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                    <DropdownToggle color="primary" caret>
                                        <span className="visually-hidden">{t('common.menuDropdown', 'More')}</span>
                                    </DropdownToggle>
                                    <DropdownMenu end>
                                        <ConditionalFragment showIf={item.paymentState === PaymentState.Unpaid}>
                                            <DropdownItem className="text-warning" onClick={() => markAsPaidForm(item.childId, item.scheduledClassId)} disabled={isSaving}>
                                                <FontAwesomeIcon icon="edit" />
                                                <> </>
                                                {t('classRegistersListUnpaid.markAsPaid.button', 'Mark as paid')}
                                            </DropdownItem>
                                        </ConditionalFragment>
                                        <DropdownItem className="text-warning" onClick={() => moveToWaitingList(item.id)}>
                                            <FontAwesomeIcon icon="arrow-right-long" />
                                            <> </>
                                            {t('classRegistersListUnpaid.moveToWaitingList.button', 'Move to waiting list')}
                                        </DropdownItem>
                                        <DropdownItem className="text-danger" onClick={() => remove(item.id, { nextTerm: false })} >
                                            <FontAwesomeIcon icon="trash" />
                                            <> </>
                                            {t('classRegistersListUnpaid.removeFromRegister.button', 'Remove from register immediately')}
                                        </DropdownItem>
                                        <DropdownItem className="text-danger" onClick={() => remove(item.id, { nextTerm: true })}>
                                            <FontAwesomeIcon icon="trash" />
                                            <> </>
                                            {t('classRegistersListUnpaid.removeFromRegisterNextTerm.button', 'Remove from register for next term')}
                                        </DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </ButtonGroup>
                        )}
                    />
                </ConditionalFragment>

                <ConditionalFragment showIf={isLoading && !scheduledClassChildren.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>

                {/* RegisterModal */}
                <FormGroup>
                    <Button color="primary" onClick={() => toggleRegisterModal()}>
                        <FontAwesomeIcon icon="plus" />
                        <> </>
                        {t('classRegistersListUnpaid.editRegister', 'Add cubs')}
                    </Button>

                    <ConditionalFragment showIf={registerModalIsOpen}>
                        <StyledModal
                            isOpen={registerModalIsOpen}
                            toggle={toggleRegisterModal}
                            size="xl">
                            <AddToRegisterModal
                                toggle={toggleRegisterModal}
                                onClose={onRegisterModalClosed}
                                isSaving={isAdding}
                                isForWaitingList={false}
                                scheduledClassId={scheduledClass?.id ?? ''}
                            />

                        </StyledModal>
                    </ConditionalFragment>
                </FormGroup>

                {/* MoveChildModal */}
                <StyledModal
                    isOpen={moveChildModalIsOpen}
                    toggle={toggleMoveChildModal}
                    size="xl">
                    <MoveChildModal
                        toggle={toggleMoveChildModal}
                        scheduledClassChildId={scheduledClassChildToMove}
                        refreshChildrenList={refresh}
                        setResultMessages={setActionMessages}
                    />
                </StyledModal>

                <ConditionalFragment showIf={!isLoading && !scheduledClassChildren.length}>
                    {
                        !scheduledClass ? (
                            // No class selected, give more useful instructions.
                            <>
                                <NoResultsFound icon="search">
                                    {t('classRegistersListUnpaid.noClassSelected.text', 'Select a class to manage the register for that class.')}
                                </NoResultsFound>
                            </>
                        ) : (
                            // Class is selected, but we have nothing to show.
                            <NoResultsFound />
                        )
                    }
                </ConditionalFragment>
            </MainContainer>
        </>
    );
};
