import { NavItem } from 'reactstrap';

export interface NavTitleProps {
    className?: string,
    child?: boolean,
    children?: React.ReactNode,
}

export const NavTitle = (props: NavTitleProps) => {
    const { className, child, children } = props;

    return (
        <NavItem className={`nav-title ${child? 'nav-title-child': ''} ${className ?? ''}`}>
            {children}
        </NavItem>
    );
};
