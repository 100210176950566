import { gql, useMutation } from "@apollo/client";
import { useAsyncCallback } from "react-use-async-callback";
import { AsyncActionStatus } from "../../../shared/abstractStore";
import { SwapScheduledClassMutation, SwapScheduledClassMutationVariables } from "../generated/graphql";

export function useSwapScheduledClassChildMutation(): [(scheduledClassChildId: string, scheduledClassId: string, endOfTerm: boolean) => Promise<{ success: boolean, warningMessage: string, errorMessage: string; }>, AsyncActionStatus]  {
    const [mutationAction, { error }] = useMutation<SwapScheduledClassMutation, SwapScheduledClassMutationVariables>(
        gql`
            mutation swapScheduledClassMutation ($scheduledClassChildId: ID!, $scheduledClassId: ID!, $endOfTerm: Boolean!) {
                swapScheduledClass(scheduledClassChildId: $scheduledClassChildId, scheduledClassId: $scheduledClassId, endOfTerm: $endOfTerm) {
                    success
                    warningMessage
                    errorMessage
                }
            }
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (scheduledClassChildId: string, scheduledClassId: string, endOfTerm: boolean) => {
        const result = await mutationAction({ variables: { scheduledClassChildId: scheduledClassChildId, scheduledClassId: scheduledClassId, endOfTerm: endOfTerm, } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }

        return result.data?.swapScheduledClass ?? { success: false, errorMessage: 'Failed to move cub.', warningMessage: '' };
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}