import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, CardImg, CardImgOverlay, CardTitle, Col, Row } from "reactstrap";
import { ClassLocation } from "../../api/main/models/ClassLocation";
import { ClassStage } from "../../api/main/models/ClassStage";
import { ClassSubStage } from "../../api/main/models/ClassSubStage";
import { ClassType } from "../../api/main/models/ClassType";
import { ScheduledClass } from "../../api/main/models/ScheduledClass";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { ClassFinderLocation } from "./ClassFinderLocation";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useScheduledClassesWithChildrenForAnonymousUserClassFinderViewModel } from "../../api/main/scheduledClasses/viewModels/useScheduledClassesWithChildrenForAnonymousUserClassFinderViewModel";
import { useCurrentTerm } from "../../api/main/terms/useCurrentTerm";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocationDistances } from "../../api/main/classLocations/useLocationDistances";
import { useReplaceSearchParamsEffect, useSearchParams } from "../../shared/useURLSearchParams";
import { joinScheduledClassConfig } from "../../configure/joinScheduledClassConfig";
import moment from "moment";
import { HorizontalRule } from "../shared/horizontalRule/HorizontalRule";
import "./classFinder.scss";
import { ChildAbsence } from "../../api/main/models/ChildAbsence";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

export interface ScheduledClassWithRelationships extends ScheduledClass {
    _classStageId?: string | null | undefined,
    _classSubStageId?: string | null | undefined,

    _spacesAvailable?: number,
    _nextLessonDateWithSpacesAvailable?: string | null | undefined,

    classType?: ClassType | undefined,
    classStage?: ClassStage | undefined,
    classSubStage?: ClassSubStage | undefined,
    classLocation?: ClassLocation | undefined,

    allChildren?: Array<{ id: string, scheduledClassId: string; }> | undefined | null,
    allAbsences?: Array<ChildAbsence>,
    lessonDatesForCurrentTerm?: Array<string> | undefined;
}

/**
 * Component for FindingClasses, non-members
 */
export const ClassFinder = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Load the data
    const {
        data: {
            items: _allItems,
            classTypes,
            classLocations,
            classStages,
            classSubStages,
            scheduledClassChildren,
            terms
        }, isLoading: _isLoading, errors: loadErrors
    } = useScheduledClassesWithChildrenForAnonymousUserClassFinderViewModel();

    // Load the current term
    const {
        data: {
            model: currentTerm
        }, isLoading: isLoadingCurrentTerm, errors: currentTermLoadErrors
    } = useCurrentTerm();
    const isLoading = _isLoading || isLoadingCurrentTerm;

    const allItems = useMemo(() => _allItems?.map(item => {
        // Depending on if we are looking at the current or next term, we want the special _classStageId and _classSubStageId to
        // be set to the relevant field.
        const _classStageId = item.currentClassStageId;
        const _classSubStageId = item.currentClassSubStageId;

        const _nextLessonDateWithSpacesAvailable = item.currentLessonNumber >= joinScheduledClassConfig.canJoinCurrentTermUntilLessonNumber ? item.nextLessonDateWithSpacesAvailableForNextTerm : item.nextLessonDateWithSpacesAvailableForCurrentTerm;

        // Follow the various lookups for related data.
        const classType = classTypes?.find(it => it.id === item.classTypeId);
        const classLocation = classLocations?.find(it => it.id === item.classLocationId);
        const activeClassStage = classStages?.find(it => it.id === item.currentClassStageId);
        const activeClassSubStage = classSubStages?.find(it => it.id === item.currentClassSubStageId);

        const allChildren = scheduledClassChildren?.filter(it => it.scheduledClassId === item.id);

        // Return everything as a single object.
        return {
            ...item,

            _classStageId,
            _classSubStageId,

            _nextLessonDateWithSpacesAvailable,

            classType,
            classLocation,
            classStage: activeClassStage,
            classSubStage: activeClassSubStage,

            allChildren
        };
    }), [_allItems, classTypes, classLocations, classStages, classSubStages,
        scheduledClassChildren
    ]);

    // Manage the tab, classType and classLocation being displayed
    const {
        classType: classTypeParam,
        location: locationParam,
        dateOfBirth: dateOfBirthParam
    } = useSearchParams();

    // State for handling the filtering
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [childDateOfBirth, setChildDateOfBirth] = useState<string>(dateOfBirthParam ?? '');

    // Filter ClassTypes
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [filterClassType, setFilterClassType] = useState<string>(classTypeParam ?? '');
    //const filterClassType = _filterClassType || classTypes?.find(it => true)?.id;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [location, setLocation] = useState<string>(locationParam ?? '');

    // Keep the URL up to date with the filters
    useReplaceSearchParamsEffect({ classType: filterClassType, dateOfBirth: childDateOfBirth });

    const {
        data: {
            items: locationDistances
        }
    } = useLocationDistances({ postcodeOrCity: location });

    // Terms Filter
    const [termFilter, setTermFilter] = useState<string | undefined>(currentTerm?.id);

    // Set the term filter to the current term if the child can self register, otherwise set it to the last term.
    useEffect(() => {
        if (!currentTerm || !terms) return;

        if (currentTerm.canSelfRegister) {
            setTermFilter(currentTerm?.id);
        } else {
            // Find the next term
            const nextTerm = terms.find(it => it.startDate > currentTerm.startDate);
            setTermFilter(nextTerm?.id);
        }
    }, [currentTerm, terms]);


    // Filter by the search
    const items = useMemo(() => {
        let ret = (allItems ?? []);

        // Filter out the deferrals class location
        ret = ret.filter(it => it.classLocation?.name !== 'Deferrals - DO NOT REMOVE');

        const term = terms?.find(it => it.id === termFilter);

        // If the term is not found, or the term cannot self register, return an empty array.
        if (!term || !term?.canSelfRegister) return [];

        // Filtering ClassTypes
        if (filterClassType !== 'all' && filterClassType !== '') {
            ret = ret.filter(it => it.classTypeId === filterClassType);
        }

        // Filtering for Terms
        if (termFilter) {
            ret = ret.filter(item => item.termId === termFilter);
        }

        return ret;
    }, [allItems, filterClassType, terms, termFilter]);

    // Calculate the child's age in years and months, to enable finding suitable classStages
    const ageOfChild = useMemo(() => {
        return {
            months: moment().diff(childDateOfBirth, 'months'),
            years: moment().diff(childDateOfBirth, 'years')
        };
    }, [childDateOfBirth]);

    // Generate an Array of suitableClasses, based on the Child's age.
    const suitableClasses = useMemo(() => {
        // Find all the ClassStages the Child can join based on their age
        const suitableClassStages = classStages?.filter(classStage => {
            const startAgeMonths = (classStage.startAgeYears * 12) + (classStage.startAgeMonths);
            const endAgeMonths = (classStage.endAgeYears * 12) + (classStage.endAgeMonths);

            if (ageOfChild.months >= startAgeMonths && ageOfChild.months <= endAgeMonths) {
                return true;
            }
            return false;
        });

        let ret = items;

        // Filter to only the classes withtin suitable stages.
        if (!isNaN(ageOfChild.months) || !isNaN(ageOfChild.years)) {
            ret = items.filter(item => !!suitableClassStages.find(stage => stage.id === item._classStageId));
        }

        return ret;
    }, [classStages, ageOfChild, items]);

    // Locations from suitableClasses
    const suitableLocations = useMemo(() => {
        const classes = suitableClasses.map(it => it.classLocationId).filter((value, index, self) => self.indexOf(value) === index);

        // Sort classes based on distance from member
        classes.sort((a, b) => {
            const distanceA = locationDistances?.find(locationDistance => locationDistance.classLocationId === a)?.distanceFromPoint;
            const distanceB = locationDistances?.find(locationDistance => locationDistance.classLocationId === b)?.distanceFromPoint;

            return parseFloat(distanceA ?? '1000') - parseFloat(distanceB ?? '1000');
        });

        return classes;
    }, [suitableClasses, locationDistances]);

    // Suitable classes from all location if given a classTypeId
    const suitableClassesFromAllLocations = useCallback((classTypeId: string) => {
        if (!classTypeId) return [];

        return suitableClasses.filter(it => it.classTypeId === classTypeId);
    }, [suitableClasses]);

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1 style={{ fontSize: '3rem' }}>
                                {t('classFinder.title', 'Class finder')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer style={{ padding: '0' }}>
                <AlertOnErrors errors={[loadErrors, currentTermLoadErrors]} />

                <ConditionalFragment showIf={!!isLoading}>
                    <Row>
                        <Col></Col>

                        <Col xs="auto" className="class-finder-loading-message">
                            <FontAwesomeIcon icon="magnifying-glass" color="#fada00" />
                            <> </>
                            {t('common.classFinderCore.loadingMessage', 'Searching our classes to find some suitable matches for your cub...')}
                            <> </>
                            <LoadingIndicator />
                        </Col>

                        <Col></Col>
                    </Row>
                </ConditionalFragment>

                <ConditionalFragment showIf={!isLoading && !!filterClassType}>
                    <Row className="class-finder">
                        <Col xs={12} md={6} className="class-finder-header">
                            <CardImg
                                alt="Card image cap"
                                src={`/img/originals/${classTypes?.find(it => it.id === filterClassType)?.name.split(' ').join('')}Main.jpg`}
                                top
                                className="class-finder-header-img"
                            />
                            <CardImgOverlay>
                                <CardTitle tag="h5" className="class-finder-header-title">
                                    <Row>
                                        <Col style={{ marginBottom: '0' }}>
                                            <p>{classTypes?.find(it => it.id === filterClassType)?.name}</p>
                                        </Col>
                                    </Row>
                                </CardTitle>
                            </CardImgOverlay>
                        </Col>
                    </Row>

                    <ConditionalFragment showIf={!suitableClasses.length}>
                        <Row>
                            <Col></Col>

                            <Col xs="auto" className="class-finder-no-suitable-classes-message">
                                {t('classFinderCore.noSuitableClasses.message', 'Hello. Classes for your child’s age group may be unavailable. Please click the button below to contact us, and we’ll do our best to help find a suitable class for you or answer any questions you may have.')}
                            </Col>

                            <Col></Col>
                        </Row>

                        <Row>
                            <Col></Col>

                            <Col xs="auto" className="class-finder-no-suitable-classes-message">
                                <Button className="class-finder-no-suitable-classes-message-button" color="primary" onClick={() => navigate('/class-search')}>
                                    <FontAwesomeIcon icon="magnifying-glass" />
                                    <> </>
                                    {t('common.startANewSearch', 'Start a new search')}
                                </Button>
                            </Col>

                            <Col></Col>
                        </Row>

                        <Row>
                            <Col></Col>

                            <Col xs="auto" className="class-finder-core-no-suitable-classes-message">
                                <Button color="primary">
                                    <a href="https://www.happycubs.ie/contact-us/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#737373', fontWeight: 'bold' }}>
                                        {t('common.anyQuestionsContactUs', 'Any Questions? Contact Us')}
                                    </a>
                                </Button>
                            </Col>

                            <Col></Col>
                        </Row>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!!suitableClasses.length}>
                        {
                            suitableLocations?.map(it => {
                                const location = classLocations?.find(location => location.id === it);
                                const classesForThisLocation = suitableClasses.filter(suitableClass => suitableClass.classLocationId === it);
                                const distance = locationDistances?.find(locationDistance => locationDistance.classLocationId === it)?.distanceFromPoint;

                                return <ClassFinderLocation
                                    locationId={it}
                                    location={location}
                                    suitableClasses={classesForThisLocation}
                                    distance={distance}
                                    childDateOfBirth={childDateOfBirth} />;
                            })
                        }
                    </ConditionalFragment>
                </ConditionalFragment>

                <ConditionalFragment showIf={!isLoading && !filterClassType}>
                    <Row className="class-finder">
                        {
                            classTypes?.map((classType, index) => {
                                return (
                                    <Col xs={12} className="class-finder-header">
                                        <div style={{ position: 'relative', }}>
                                            <CardImg
                                                alt="Card image cap"
                                                src={`/img/originals/${classType.name.split(' ').join('')}Main.jpg`}
                                                top
                                                className="class-finder-header-img"
                                            />
                                            <CardImgOverlay>
                                                <CardTitle tag="h5" className="class-finder-header-title">
                                                    <Row>
                                                        <Col style={{ marginBottom: '0' }}>
                                                            <p>{classType.name}</p>
                                                        </Col>
                                                    </Row>
                                                </CardTitle>
                                            </CardImgOverlay>
                                        </div>

                                        <ConditionalFragment showIf={!suitableClassesFromAllLocations(classType.id)?.length}>
                                            <Row>
                                                <Col></Col>

                                                <Col xs="auto" className="class-finder-no-suitable-classes-message">
                                                    {t('classFinderCore.noSuitableClasses.message', 'Hello. Classes for your child’s age group may be unavailable. Please click the button below to contact us, and we’ll do our best to help find a suitable class for you or answer any questions you may have.')}
                                                </Col>

                                                <Col></Col>
                                            </Row>

                                            <Row>
                                                <Col></Col>

                                                <Col xs="auto" className="class-finder-no-suitable-classes-message">
                                                    <Button className="class-finder-no-suitable-classes-message-button" color="primary" onClick={() => navigate('/class-search')}>
                                                        <FontAwesomeIcon icon="magnifying-glass" />
                                                        <> </>
                                                        {t('common.startANewSearch', 'Start a new search')}
                                                    </Button>
                                                </Col>

                                                <Col></Col>
                                            </Row>

                                            <Row>
                                                <Col></Col>

                                                <Col xs="auto" className="class-finder-core-no-suitable-classes-message">
                                                    <Button color="primary">
                                                        <a href="https://www.happycubs.ie/contact-us/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#737373', fontWeight: 'bold' }}>
                                                            {t('common.anyQuestionsContactUs', 'Any Questions? Contact Us')}
                                                        </a>
                                                    </Button>
                                                </Col>

                                                <Col></Col>
                                            </Row>
                                        </ConditionalFragment>

                                        <ConditionalFragment showIf={!!suitableClassesFromAllLocations(classType.id)?.length}>
                                            {suitableLocations?.map(it => {
                                                const location = classLocations?.find(location => location.id === it);
                                                const classesForThisLocation = suitableClasses.filter(suitableClass => suitableClass.classLocationId === it && suitableClass.classTypeId === classType.id);
                                                const distance = locationDistances?.find(locationDistance => locationDistance.classLocationId === it)?.distanceFromPoint;

                                                if (classesForThisLocation.length === 0) return null;

                                                return <ClassFinderLocation
                                                    locationId={it}
                                                    location={location}
                                                    suitableClasses={classesForThisLocation}
                                                    distance={distance}
                                                    childDateOfBirth={childDateOfBirth} />;
                                            })}

                                            {/* Quick and dirty way to not show on the last one. We only have two class types. */}
                                            <ConditionalFragment showIf={index === 0}>
                                                <div style={{ margin: '4rem 0' }}>
                                                    <HorizontalRule />
                                                </div>
                                            </ConditionalFragment>
                                        </ConditionalFragment>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                </ConditionalFragment>
            </MainContainer>
        </>
    );
};