import { AsyncActionStatus } from "../../shared/abstractStore";
import { useAsyncCallback } from "react-use-async-callback";
import { useAccountService } from "./useAccountService";

/**
 * Callback to change the email of the current user..
 */
export function useChangeEmailCallback(): [(newEmail: string) => Promise<boolean>, AsyncActionStatus] {
    const accountService = useAccountService();

    const [action, { isExecuting, errors }] = useAsyncCallback(async (newEmail: string) => {
        return await accountService.changeEmail({ newEmail: newEmail });
    }, [accountService]);

    return [action, { isExecuting, errors }];
}
