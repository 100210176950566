import { isNullOrUndefined } from "util";
import { configuredErrorMessageConverters } from "./registerErrorMessageConverters";
import { FriendlyErrorMessage } from "./FriendlyErrorMessage";

/**
 * Translate an error message into a user friendly message, also giving the option for wrapping the message for translation if required
 * and seperating out additional details from the core error message.
 * 
 * @param error the original error.
 */
export function userFriendlyErrorMessage(error: any): FriendlyErrorMessage | Array<FriendlyErrorMessage> {
    // Give each converter in order a chance to handle the error until one does.
    for (const converter of configuredErrorMessageConverters) {
        const friendly = converter(error);
        if (!isNullOrUndefined(friendly)) {
            if (Array.isArray(friendly)) {
                return friendly as Array<FriendlyErrorMessage>;
            } else if ((friendly as FriendlyErrorMessage)?.error) {
                return friendly as FriendlyErrorMessage;
            } else {
                return { message: friendly as string, error: error };
            }
        }
    }

    // Give up and return the error with a basic wrapper.
    return {
        message: error?.message ?? error?.toString,
        error: error
    };
}