import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Alert, Button, Col, Form, FormGroup, FormText, Label, Row, Spinner } from "reactstrap";
import { useRegisterAccountCallback, useResendConfirmationEmailCallback } from "../../api/account";
import { Child, childDefaultValues } from "../../api/main/models/Child";
import { Member, memberDefaultValues } from "../../api/main/models/Member";
import { Profile, profileDefaultValues } from "../../api/main/models/Profile";
import { useConsumeScheduledClassForEnrollInClassViewModel } from "../../api/main/scheduledClasses/viewModels/useConsumeScheduledClassForEnrollInClassViewModel";
import { useChanges } from "../../shared/useChanges";
import { getScheduledClassSummary } from "../scheduledClass/getScheduledClassSummary";
import { Banner } from "../shared/banner/Banner";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { Register as RegisterModel } from '../../api/account/models/Register';
import { usePasswordValidation } from "../../shared/passwordValidation";
import { ButtonAsync } from "reactstrap-buttonasync";
import { ValidatedISODateTimeInput } from "../shared/isoDateTimeInput/ValidatedISODateTimeInput";
import { useSaveProfileMutation } from "../../api/main/profiles/useSaveProfileMutation";
import { useSaveMemberMutation } from "../../api/main/members/useSaveMemberMutation";
import { useSaveChildMutation } from "../../api/main/children/useSaveChildMutation";
import { ScheduledClassChild, scheduledClassChildDefaultValues } from "../../api/main/models/ScheduledClassChild";
import { useSaveScheduledClassChildMutation } from "../../api/main/scheduledClassChildren/useSaveScheduledClassChildMutation";
import { FormButtons } from "../shared/formButtons/FormButtons";
import { ConditionalFragment } from "react-conditionalfragment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HappyIcon, HappyIconProps } from "../shared/Utilities/HappyIcon";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import "./enrollInClass.scss";
import { useCreateMemberWithChildForAnonymousUserMutation } from "../../api/main/members/viewModels/useCreateMemberWithChildForAnonymousUserMutation";
import moment from "moment";
import { PaymentState } from "../../api/main/models/constants/PaymentState";
import { useJoinScheduledClassMutation } from "../../api/main/scheduledClassChildren/useJoinScheduledClassMutation";
import { joinScheduledClassConfig } from "../../configure/joinScheduledClassConfig";
import { useJoinScheduledClassWaitingListMutation } from "../../api/main/scheduledClassChildren/useJoinScheduledClassWaitingListMutation";
import { TwoValueSwitch } from "../shared/twoValueSwitch/TwoValueSwitch";
import { useToggleState } from "use-toggle-state";
import { HtmlEditor } from "../../shared/htmlEditor";
import { useEnrollInClassSupportingData } from "../../api/main/scheduledClasses/useEnrollInClassSupportingData";

/**
 * Component to enrole in a waiting list.
 * @returns
 */
export const EnrollInClassWaitingList = () => {
    return (
        <EnrollInClass isWaitingList={true} />
    );
};

export interface EnroleInClassProps {
    isWaitingList?: boolean,
}

/**
 * Component that allows a non-member to register for a ScheduledClass. We will take the minimum required details for the Member and Child
 * So we can create the User, Member, and Child
 */
export const EnrollInClass = (props: EnroleInClassProps) => {
    const {
        isWaitingList = false,
    } = props;


    const { t } = useTranslation();
    const {
        scheduledClassId,
        dateOfBirth, // If a child is added under a Member, we use the route to pass this extra information so we can default it.
    } = useParams<{ scheduledClassId: string | undefined, dateOfBirth: string | undefined, }>();
    const navigate = useNavigate();

    // Load the data
    const {
        data: {
            model,
            classTypes,
            genders,
            childsRelationships,
            terms,
            classStages,
            classSubStages
        }, isLoading, errors: loadErrors
    } = useConsumeScheduledClassForEnrollInClassViewModel(scheduledClassId);

    // Get the relevant term.  If the lesson number is less than or equal to 4 then the relevant term is the current term, otherwise its the next term.
    const { termToJoin, } = useMemo(() => {
        // Get the current and next terms.
        const now = moment().toISOString();
        const nextTerm = terms?.find(item => item.startDate >= now);
        const startedTerms = [...(terms ?? [])?.filter(item => item.startDate < now)];
        startedTerms.reverse();
        const currentTerm = startedTerms.find(item => true);

        // Find out if we're allowed to join this term or not.
        const canJoinThisTerm = (model?.currentLessonNumber ?? 0) < joinScheduledClassConfig.canJoinCurrentTermUntilLessonNumber;
        const termToJoin = canJoinThisTerm ? currentTerm : nextTerm;

        return {
            canJoinThisTerm,
            termToJoin,
            //currentTerm,
            //nextTerm,
        };
    }, [model, terms]);

    // Member related data
    //
    // Model (User)
    const { model: registerModel, change: registerChange } = useChanges<RegisterModel>({ email: '', password: '', confirmPassword: '' });
    const { checkPassword, passwordRequirements } = usePasswordValidation();
    const [register, { isExecuting: _isRegistering, errors: registerErrors }] = useRegisterAccountCallback();
    const [isPendingEmailConfirmation, setIsPendingEmailConfirmation] = useState<boolean>(false);
    const [resendConfirmationEmail, { isExecuting: isResendingConfirmationEmail, errors: resendConfirmationEmailErrors }] = useResendConfirmationEmailCallback();

    // Model (Member)
    const { model: memberModel, change: memberChange, changes: memberChanges } = useChanges({} as Member, { ...memberDefaultValues() });
    const [saveMember, { errors: saveMemberErrors }] = useSaveMemberMutation();

    // Model (Profile) for Member
    const { model: profileModel, change: profileChange, changes: profileChanges } = useChanges({} as Profile, { ...profileDefaultValues() });
    const [saveProfile, { errors: saveProfileErrors }] = useSaveProfileMutation();


    const {
        data: {
            users,
        }, errors: usersLoadErrors
    } = useEnrollInClassSupportingData(registerModel?.email ?? 'sackfieldadampaul@gmail.com');

    // Child related data
    //
    // Model (Child)
    const { model: childModel, change: childChange, changes: childChanges } = useChanges({} as Child, { ...childDefaultValues() });
    const [saveChild, { errors: saveChildErrors }] = useSaveChildMutation();

    // ScheduledClassChild related data
    //
    // Model (ScheduledClassChild)
    const { model: scheduledClassChildModel, changes: scheduledClassChildChanges } = useChanges({} as ScheduledClassChild, { ...scheduledClassChildDefaultValues() });
    const [saveScheduledClassChild] = useSaveScheduledClassChildMutation();

    // Validation
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            // Member validation
            memberFirstName: () => !profileModel?.firstName ? t('classFinder.enrollInClass.errors.memberFirstNameRequired', 'First name is required') : '',
            memberLastName: () => !profileModel?.lastName ? t('classFinder.enrollInClass.errors.memberLastNameRequired', 'Last name is required') : '',
            memberGenderId: () => !profileModel?.genderId ? t('classFinder.enrollInClass.errors.memberGender', 'Gender is required') : '',
            memberPrimaryPhone: () => !profileModel?.primaryPhone ? t('classFinder.enrollInClass.errors.memberPrimaryPhoneRequired', 'Mobile number is required') : '',

            // User validation
            email: () => !registerModel?.email ? t('classFinder.enrollInClass.errors.emailRequired', 'Email address is required') : '',
            password: () => !registerModel?.password ? t('classFinder.enrollInClass.errors.passwordRequired', 'Password is required')
                : checkPassword(registerModel.password).errorDescription,
            confirmPassword: () => registerModel.confirmPassword !== registerModel.password ? t('classFinder.enrollInClass.errors.passwordsDoNotMatch', 'The password and confirmation password do not match') : '',

            // Child validation
            childFirstName: () => !childModel?.firstName ? t('classFinder.enrollInClass.errors.memberFirstNameRequired', 'First name is required') : '',
            childLastName: () => !childModel?.lastName ? t('classFinder.enrollInClass.errors.memberLastNameRequired', 'Last name is required') : '',
            dateOfBirth: () => !childModel?.dateOfBirth ? t('classFinder.enrollInClass.errors.dateOfBirthRequired', 'Date of birth is required') : '',
            genderId: () => !childModel?.genderId ? t('classFinder.enrollInClass.errors.genderIdRequired', 'Gender is required') : '',
            memberChildsRelationshipId: () => !childModel?.memberChildsRelationshipId ? t('classFinder.enrollInClass.memberChildsRelationshipIdRequired', 'Relationship to child is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [profileModel]);

    // Mutation to Save a MemberWithAChild 
    const [saveMemberWithChild] = useCreateMemberWithChildForAnonymousUserMutation();

    // Join a child to the class.
    const [joinScheduledClassMutation, { errors: joinScheduledClassMutationErrors, }] = useJoinScheduledClassMutation();
    const [joinClass, { isExecuting: isJoiningClass, errors: joinClassErrors }] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }

        if (!model || !childModel?.id) {
            return;
        }

        // Join the class.
        const result = await joinScheduledClassMutation(childModel?.id, model.id);
        if (!!result?.payment) {
            if (result.payment.paymentState !== PaymentState.Paid) {
                // Already paid so go back to the list of classes.
                navigate(`/my/children/details/${childModel?.id}?tab=classes`);
            }

            // We need to pay for this, so go the checkout screen.
            navigate(`/my/checkout/${result.payment.id}`);
        } else {
            // We got an error so stay on this screen and let the error show.
        }
    }, [model, childModel, termToJoin, joinScheduledClassMutation]);

    // Join a child to the waiting list.
    const [joinScheduledClassWaitingListMutation, { errors: joinScheduledClassWaitingListMutationErrors, }] = useJoinScheduledClassWaitingListMutation();
    const [joinWaitingList, { isExecuting: isJoiningWaitingList, errors: joinWaitingListErrors }] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }

        if (!model || !childModel) {
            return;
        }

        // Join the class waiting list.
        const result = await joinScheduledClassWaitingListMutation(childModel.id, model.id, false, "");
        if (!!result?.scheduledClassChild) {
            if (result.scheduledClassChild.isOnWaitingList) {
                // They are now on the waiting list.
                navigate(`/my/children/details/${childModel.id}?tab=waitingLists`);
            } else {
                // They already had a non-waiting list entry, so take them to the class list.
                navigate(`/my/children/details/${childModel.id}?tab=classes`);
            }
        } else {
            // We got an error so stay on this screen and let the error show.
        }
    }, [model, childModel, termToJoin, joinScheduledClassWaitingListMutation]);

    const [duplicateEmailError, setDuplicateEmailError] = useState('');

    // Register the new User account, along with their Profile and Member records. Also, create the Child
    const [performRegister, { isExecuting: isSavingRegisterForm, errors: registerFormErrors }] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }

        if (users && users.length > 0) {
            setDuplicateEmailError('Duplicate email');

            // Add the classId and childDOB to localstorage, stored as potentialClass
            localStorage.setItem('potentialClass', JSON.stringify({ scheduledClassId, childDOB: childModel.dateOfBirth, memberEmail: registerModel.email, isWaitingList, expires: moment().add(10, 'minutes').toISOString() }));
            return;
        } else {
            setDuplicateEmailError('');
        }

        // Save the new User
        const result = await register({ email: registerModel.email, password: registerModel.password });

        if (result) {
            setIsPendingEmailConfirmation(result.requiresEmailConfirmation);

            // Save the profile.
            await saveProfile(profileModel.id, { ...profileChanges, userId: result.userId, primaryEmail: registerModel.email, isMember: true }, true);

            await resendConfirmationEmail(registerModel.email);

            // Save the Member, Child, and ScheduledClassChild records
            // Now that we have the User registered and their Profile saved, we have the data we need (UserId) and we can pass these to our mutation
            await saveMemberWithChild(
                { ...memberModel, userId: result.userId, id: profileModel.id },
                { ...childModel, memberId: profileModel.id },
                { ...scheduledClassChildModel, childId: childModel.id, scheduledClassId: scheduledClassId ?? '', isOnWaitingList: isWaitingList, isPreferredClass: false, paymentState: PaymentState.Unpaid }); // TODO - Handle isPrefferedClass

            // Update the models in state to match the id changes we pass in to saveMemberWithChild() in case we go on to use them again on this screen.
            memberChange({ id: profileModel.id, });
            childChange({ memberId: profileModel.id, });

            // Redirect the whole page (not just the react app) as its likely the returnUrl is handled on the server.
            if (result.succeeded) {
                if (isWaitingList) {
                    await joinWaitingList();
                } else {
                    await joinClass();
                }
            }
        }
    }, [
        register,
        saveProfile,
        saveMember,
        saveChild,
        saveMemberWithChild,
        saveScheduledClassChild,
        memberModel,
        childModel,
        profileModel,
        memberChanges,
        profileChanges,
        childChanges,
        scheduledClassChildChanges,
        registerModel,
        scheduledClassChildModel,
        scheduledClassId,
        isWaitingList,
        users
    ]);
    const isRegistering = _isRegistering || isSavingRegisterForm;

    // UseEffect to set the DateOfBirth field, as we have access from the classFinder
    useEffect(() => {
        childChange({ dateOfBirth: moment(dateOfBirth).toISOString() });
    }, [childChange, dateOfBirth]);

    // ClassType, used to show the correct ClassType Icon
    const classType = useMemo(() => classTypes?.find(it => it.id === model?.classTypeId), [classTypes, model]);

    // Toggle to show the Medical Information fields
    const [hasMedicalInformation, toggleMedicalInformation] = useToggleState(false);

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} md="auto">
                            <h1>
                                {t('classFinder.enrollInClass.title', 'Enrol in class')}
                            </h1>
                            <h3>
                                <HappyIcon icon={classType?.name.split(' ').join('-').toLocaleLowerCase() as HappyIconProps['icon']} />
                                <> </>
                                {getScheduledClassSummary(model, { classLocation: model?.classLocation, classStage: classStages?.find(it => it.id === model?.currentClassStageId), classSubStage: classSubStages?.find(it => it.id === model?.currentClassSubStageId) })}
                            </h3>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors
                    error={[
                        loadErrors,
                        registerErrors, resendConfirmationEmailErrors,
                        saveMemberErrors,
                        saveProfileErrors,
                        saveChildErrors,
                        registerFormErrors,
                        joinScheduledClassMutationErrors,
                        joinScheduledClassWaitingListMutationErrors,
                        joinWaitingListErrors,
                        joinClassErrors,
                        usersLoadErrors
                    ]}
                />

                <div className="enroll-in-class">


                    {/* Alert to show if the class the User is registering a Child for is currently implementing a WaitingList */}
                    <ConditionalFragment showIf={!!isWaitingList}>
                        <Alert>
                            {t('classFinder.enrollInClass.warning.waitingListMessage', 'The class you have selected currently has a waiting list, while your cub is on the waiting list, you\'re welcome to check out classes that are currently accepting cubs without losing their place on the waiting list for this class.')}
                        </Alert>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={isPendingEmailConfirmation}>
                        <Alert color="success" >
                            <Row>
                                <Col>
                                    {t('register.confirmationEmailHasBeenResent', 'Please check your email to confirm your account.  You won\'t be able to login until you have confirmed your account.')}
                                </Col>
                                <Col xs="auto">
                                    <ButtonAsync type="button" color="success" onClick={async e => { e.preventDefault(); await resendConfirmationEmail(registerModel.email); }}
                                        isExecuting={isResendingConfirmationEmail}
                                        executingChildren={<><Spinner size="sm" />{t('common.sending', 'Sending...')}</>}>
                                        {t('common.resendEmail', 'Resend email')}
                                    </ButtonAsync>
                                </Col>
                            </Row>
                        </Alert>
                    </ConditionalFragment>

                    {/* General alert, explaining why we are capturing these details at this point: Which is to facilitate adding a Child to a ScheduledClassChild */}
                    <Alert>
                        {t('classFinder.enrollInClass.message.formMessage', 'In order to register a Cub for a class, we first need to take a few details about you and your cub.')}
                    </Alert>

                    <Form onSubmit={e => { e.preventDefault(); }}>
                        <h3>{t('classFinder.enrollInClass.childDetails.header', 'Cub details (your child\'s details)')}</h3>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="childFirstName">{t('classFinder.enrollInClass.firstName.label', 'First name')}</Label>
                                    <ValidatedInput
                                        name="childFirstName"
                                        type="text"
                                        value={childModel?.firstName ?? ''}
                                        onChange={e => childChange({ firstName: e.currentTarget.value })}
                                        onBlur={e => validate('childFirstName')}
                                        validationErrors={validationErrors['childFirstName']} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="childLastName">{t('classFinder.enrollInClass.lastName.label', 'Last name')}</Label>
                                    <ValidatedInput
                                        name="childLastName"
                                        type="text"
                                        value={childModel?.lastName ?? ''}
                                        onChange={e => childChange({ lastName: e.currentTarget.value })}
                                        onBlur={e => validate('childLastName')}
                                        validationErrors={validationErrors['childLastName']} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="childDateOfBirth">{t('classFinder.enrollInClass.dateOfBirth.label', 'Date of birth')}</Label>
                                    <ValidatedISODateTimeInput
                                        name="childDateOfBirth"
                                        type="date"
                                        value={childModel?.dateOfBirth ?? ''}
                                        onChange={e => childChange({ dateOfBirth: e.currentTarget.value })}
                                        onBlur={e => validate('childDateOfBirth')}
                                        validationErrors={validationErrors['childDateOfBirth']}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="genderId">{t('classFinder.enrollInClass.genderId.label', 'Gender')}</Label>
                                    <ValidatedInput
                                        name="genders"
                                        type="select"
                                        value={childModel?.genderId ?? ''}
                                        onChange={e => childChange({ genderId: e.currentTarget.value })}
                                        onBlur={e => validate('genderId')}
                                        validationErrors={validationErrors['genderId']}
                                    >
                                        <option value="">{t('classFinder.enrollInClass.genderId.pleaseSubmit', '(Please select a gender)')}</option>
                                        {
                                            genders?.map(item => (
                                                <option key={item.id} value={item.id}>{t('classFinder.enrollInClass.genderId.value', '{{ gender }}', { gender: item.name })}</option>
                                            ))
                                        }
                                    </ValidatedInput>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <FormGroup>
                                <Label htmlFor="hasMedicalInformation">{t('classFinder.enrollInClass.medicalInformation.label', 'Any medical conditions?')}</Label>
                                <TwoValueSwitch leftLabel="No" rightLabel="Yes" checked={hasMedicalInformation} onChange={() => toggleMedicalInformation()} />
                            </FormGroup>
                        </Row>

                        <ConditionalFragment showIf={hasMedicalInformation}>
                            <FormGroup>
                                <Label htmlFor="healthNoteHtml">{t('editChild.mainTab.healthNotes.label', 'Health notes')}</Label>
                                <HtmlEditor size="sm" value={childModel?.healthNoteHtml ?? ''} onChange={text => childChange({ healthNoteHtml: text })} />
                            </FormGroup>
                        </ConditionalFragment>

                        <h3>{t('classFinder.enrollInClass.memberDetails.header', 'Member details (your details)')}</h3>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="memberFirstName">{t('classFinder.enrollInClass.firstName.label', 'First name')}</Label>
                                    <ValidatedInput
                                        name="memberFirstName"
                                        type="text"
                                        value={profileModel?.firstName ?? ''}
                                        onChange={e => profileChange({ firstName: e.currentTarget.value })}
                                        onBlur={e => validate('memberFirstName')}
                                        validationErrors={validationErrors['memberFirstName']} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="memberLastName">{t('classFinder.enrollInClass.lastName.label', 'Last name')}</Label>
                                    <ValidatedInput
                                        name="memberLastName"
                                        type="text"
                                        value={profileModel?.lastName ?? ''}
                                        onChange={e => profileChange({ lastName: e.currentTarget.value })}
                                        onBlur={e => validate('memberLastName')}
                                        validationErrors={validationErrors['memberLastName']} />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="memberChildsRelationshipId">{t('classFinder.enrollInClass.memberChildsRelationshipId.label', 'Relationship to child')}</Label>
                                    <ValidatedInput
                                        name="memberChildsRelationshipId"
                                        type="select"
                                        value={childModel?.memberChildsRelationshipId ?? ''}
                                        onChange={e => childChange({ memberChildsRelationshipId: e.currentTarget.value })}
                                        onBlur={e => validate('memberChildsRelationshipId')}
                                        validationErrors={validationErrors}
                                    >
                                        <option value="">{t('classFinder.enrollInClass.memberChildsRelationshipId.pleaseSelect', '(Please select relationship to child)')}</option>
                                        {
                                            childsRelationships?.map(childRelationship => (
                                                <option key={childRelationship.id} value={childRelationship.id}>{t('classFinder.enrollInClass.memberChildsRelationshipId.value', '{{ childRelationship }}', { childRelationship: childRelationship.name ?? '' })}</option>
                                            ))
                                        }
                                    </ValidatedInput>
                                </FormGroup>
                            </Col>

                            <Col>
                                <FormGroup>
                                    <Label htmlFor="memberGenderId">{t('classFinder.enrollInClass.genderId.label', 'Gender')}</Label>
                                    <ValidatedInput
                                        name="memberGenderId"
                                        type="select"
                                        value={profileModel?.genderId ?? ''}
                                        onChange={e => profileChange({ genderId: e.currentTarget.value })}
                                        onBlur={e => validate('memberGenderId')}
                                        validationErrors={validationErrors['memberGenderId']}
                                    >
                                        <option value="">{t('classFinder.enrollInClass.genderId.pleaseSubmit', '(Please select a gender)')}</option>
                                        {
                                            genders?.map(item => (
                                                <option key={item.id} value={item.id}>{t('classFinder.enrollInClass.genderId.value', '{{ gender }}', { gender: item.name })}</option>
                                            ))
                                        }
                                    </ValidatedInput>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="email">{t('classFinder.enrollInClass.email.label', 'Email')}</Label>
                                    <ValidatedInput
                                        name="email"
                                        type="email"
                                        value={registerModel?.email ?? ''}
                                        onChange={e => registerChange({ email: e.currentTarget.value })}
                                        onBlur={e => validate('email')}
                                        validationErrors={validationErrors['email']}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="primaryPhone">{t('classFinder.enrollInClass.mobile.label', 'Mobile')}</Label>
                                    <ValidatedInput
                                        name="primaryPhone"
                                        type="tel"
                                        value={profileModel?.primaryPhone ?? ''}
                                        onChange={e => profileChange({ primaryPhone: e.currentTarget.value })}
                                        onBlur={e => validate('memberPrimaryPhone')}
                                        validationErrors={validationErrors['memberPrimaryPhone']}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="password">{t('classFinder.enrollInClass.password.label', 'Password')}</Label>
                                    <ValidatedInput
                                        name="password"
                                        type="password"
                                        value={registerModel.password}
                                        onChange={e => registerChange({ password: e.currentTarget.value })}
                                        onBlur={e => validate('password')}
                                        validationErrors={validationErrors['password']}
                                    />
                                    <ConditionalFragment showIf={!!validationErrors['password']}>
                                        <FormText color="white">
                                            {passwordRequirements}
                                        </FormText>
                                    </ConditionalFragment>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="confirmPassword">{t('classFinder.enrollInClass.confirmPassword.label', 'Confirm password')}</Label>
                                    <ValidatedInput
                                        name="confirmPassword"
                                        type="password"
                                        value={registerModel.confirmPassword}
                                        onChange={e => registerChange({ confirmPassword: e.currentTarget.value })}
                                        onBlur={e => validate('confirmPassword')}
                                        validationErrors={validationErrors['confirmPassword']}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>

                    <ConditionalFragment showIf={!!duplicateEmailError}>
                        <Alert color="danger">
                            {t('classFinder.enrollInClass.warning.duplicateEmailMessage', 'The email address you are trying to join with is already in use. Try logging in instead.')}
                        </Alert>
                    </ConditionalFragment>

                    {/*Save button */}
                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isRegistering || isJoiningClass || isJoiningWaitingList} onClick={() => performRegister()}
                                executingChildren={<><Spinner size="sm" /> {t('common.registering', 'Registering...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> </>
                                {t('common.register', 'Register')}
                            </ButtonAsync>
                        </ConditionalFragment>

                        <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </div>
            </MainContainer>
        </>
    );
};