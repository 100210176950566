import { ErrorMessageConverter } from "./ErrorMessageConverter";

/**
 * Register all converters to use for error messages when calling userFriendlyErrorMessage().
 * 
 * Convters will be run in array order, with the first non-null/undefined result being returned as the error message.
 * 
 * @param converters
 */
export function registerErrorMessageConverters(converters: Array<ErrorMessageConverter>): void {
    configuredErrorMessageConverters = converters;
}

/**
 * Currently registered ErrorMessageConvters set by registerErrorMessageConverters().
 */
export let configuredErrorMessageConverters: Array<ErrorMessageConverter> = [];