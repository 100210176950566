import '../OEmbedProvider';
import { OEmbedProvider } from '../OEmbedProvider';

/**
 * YouTube oembed provider.
 */
export const YouTubeProvider: OEmbedProvider = {
    name: 'youtube',
    type: 'video',
    format: 'json',
    api: 'https://www.youtube.com/oembed',
    urls: [
        "youtube\\.com/watch.+v=[\\w-]+&?",
        "youtu\\.be/[\\w-]+",
        "youtube.com/embed"
    ],
};
