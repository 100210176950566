import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useCallback, useEffect, useMemo } from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { ScheduledClassesWithChildrenForAnonymousUserClassFinderViewModelQuery, ScheduledClassesWithChildrenForAnonymousUserClassFinderViewModelQueryVariables } from "../../generated/graphql";
import { classLocationFieldsFragment } from "../../models/ClassLocation";
import { classStageFieldsFragment } from "../../models/ClassStage";
import { classSubStageFieldsFragment } from "../../models/ClassSubStage";
import { classTypeFieldsFragment } from "../../models/ClassType";
import { scheduledClassFieldsFragment } from "../../models/ScheduledClass";
import { termFieldsFragment } from "../../models/Term";

export interface ScheduledClassesWithChildrenForAnonymousUserClassFinderViewModelOptions extends AsyncLoadOptions {
    includeChildAbsences?: boolean;
}

export function useScheduledClassesWithChildrenForAnonymousUserClassFinderViewModel(options: ScheduledClassesWithChildrenForAnonymousUserClassFinderViewModelOptions = {}): AsyncLoadResult<ScheduledClassesWithChildrenForAnonymousUserClassFinderViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<ScheduledClassesWithChildrenForAnonymousUserClassFinderViewModelQuery, ScheduledClassesWithChildrenForAnonymousUserClassFinderViewModelQueryVariables>(
        gql`
        query scheduledClassesWithChildrenForAnonymousUserClassFinderViewModelQuery {
            items: scheduledClassesForAnonymousUser (includeOpenTermOnly: true) {
                ...scheduledClassFields

                lessonDatesForCurrentTerm
                nextLessonDateWithSpacesAvailableForCurrentTerm
                nextLessonDateWithSpacesAvailableForNextTerm
            }

            classTypes :classTypesForAnonymousUser {
                ...classTypeFields
            }

            classStages: classStagesForAnonymousUser {
                ...classStageFields
            }

            classSubStages: classSubStagesForAnonymousUser {
                ...classSubStageFields
            }

            classLocations: classLocationsForAnonymousUser {
                ...classLocationFields
            }

            scheduledClassChildren: scheduledClassChildrenForAnonymousUser (includeOpenTermOnly: true) {
                id
                scheduledClassId
            }    

            terms: termsForAnonymousUser {
                ...termFields
            }
        }

        ${scheduledClassFieldsFragment}
        ${classTypeFieldsFragment}
        ${classStageFieldsFragment}
        ${classSubStageFieldsFragment}
        ${classLocationFieldsFragment}
        ${termFieldsFragment}
        `,
        {
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error]);
};