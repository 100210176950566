import { AsyncActionStatus } from "../../shared/abstractStore";
import { useAsyncCallback } from "react-use-async-callback";
import { InviteRequest, InviteResult } from "./AccountService";
import { useAccountService } from "./useAccountService";

/**
 * Callback to Invite an account to register.
 */
export function useInviteCallback(): [(model: InviteRequest) => Promise<InviteResult>, AsyncActionStatus] {
    const accountService = useAccountService();

    const [action, { isExecuting, errors }] = useAsyncCallback(async (model: InviteRequest) => {
        let result = await accountService.invite(model);
        return result;
    }, [accountService]);

    return [action, { isExecuting, errors }];
}
