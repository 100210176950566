import * as React from 'react';
import { atom, useRecoilState } from 'recoil';

export interface CurrentUserRolesCache {
    isLoading: boolean,
    isAuthenticated: boolean,
    roles: Array<string>,
}

export const currentUserRolesCacheState = atom<CurrentUserRolesCache>({
    key: 'currentUserRolesCacheState',
    default: { isAuthenticated: false, roles: [], isLoading: false },
    //effects_UNSTABLE: [persistAtom],
});

/**
 * Hook that provides access to the Global search text from the global search text state while abstracting the global stage provider from the calling code.
 */
export function useCurrentUserRolesCache(): [CurrentUserRolesCache, React.Dispatch<React.SetStateAction<CurrentUserRolesCache>>] {
    const [value, setValue] = useRecoilState(currentUserRolesCacheState);
    return [value, setValue];
}