import * as React from "react";
import { Form, Alert, FormGroup, Label, Spinner } from "reactstrap";
import { useChanges } from "../../shared/useChanges";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { ChangeEmail as ChangeEmailModel } from '../../api/account/models/ChangeEmail';
import { useTranslation } from "react-i18next";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useChangeEmailCallback, useResendConfirmationEmailCallback } from "../../api/account";
import { useUser } from "../../api/main/users/useUser";
import { useAuthenticatedState } from "../../api/api-authorization/useAuthenticatedState";
import { FormButtons } from "../shared/formButtons/FormButtons";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { ConditionalFragment } from "react-conditionalfragment";

/**
 * Change the logged in user's email.
 */
export const ChangeEmail = () => {
    const { t } = useTranslation();
    const { user } = useAuthenticatedState({ includeUser: true });
    const { data: { model: storeModel, }, isLoading, errors: loadErrors, refresh: refreshUserModel } = useUser(user?.sub ?? '', { lazy: true });

    const [changeEmail, { isExecuting: isChangingPassword, errors: changeEmailErrors }] = useChangeEmailCallback();
    const [hasSentEmail, setHasSentEmail] = React.useState<boolean>(false);

    const [resendConfirmationEmail, { isExecuting: isResendingConfirmationEmail, errors: resendConfirmationEmailErrors }] = useResendConfirmationEmailCallback();
    const [hasSentConfirmationEmail, setHasSentConfirmationEmail] = React.useState<boolean>(false);

    const { model, change } = useChanges<ChangeEmailModel>({ newEmail: storeModel?.email ?? '' });
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        if (!model) {
            return;
        }

        const rules = {
            newEmail: () => !model.newEmail ? t('changeEmail.newEmailRequired', 'Email is required')
                : storeModel && storeModel.email === model.newEmail ? t('changeEmail.newEmailMatchesOldEmail', 'Your new email is the same as your old email and so does not need to go through an email update process.')
                : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Only load the user model once we know the user id.
    React.useEffect(() => {
        if (!user) {
            return;
        }

        refreshUserModel();
    }, [user, refreshUserModel]);
 
    // Render the UI.
    //

    return (
        <MainContainer>
            <ConditionalFragment showIf={isLoading}>
                <LoadingIndicator fullWidth />
            </ConditionalFragment>

            <Form onSubmit={async e => {
                e.preventDefault();
                if (!validate()) {
                    return;
                }

                const ok = await changeEmail(model.newEmail);
                setHasSentEmail(ok);
            }}>
                <AlertOnErrors errors={[loadErrors, changeEmailErrors, resendConfirmationEmailErrors]} />

                {
                    hasSentEmail ? (
                        <Alert color="success">
                            <>{t('changeEmail.emailHasBeenSent', 'Confirmation link to change email sent.  Please check your email to confirm.')} </>
                            <ButtonAsync type="button" color="success" onClick={async e => { e.preventDefault(); await changeEmail(model.newEmail); }}
                                isExecuting={isResendingConfirmationEmail}
                                executingChildren={<><Spinner size="sm" />{t('common.sending', 'Sending...')}</>}>
                                {t('common.resendEmail', 'Resend email')}
                            </ButtonAsync>
                        </Alert>
                    ) : null
                }
                {
                    hasSentConfirmationEmail ? (
                        <Alert color="success" >
                            <>{t('login.confirmationEmailHasBeenResent', 'Confirmation link to verify the email for this account has been resent.  Please check your email to confirm.')} </>
                            <ButtonAsync type="button" color="success" onClick={async e => { e.preventDefault(); await resendConfirmationEmail(storeModel?.email ?? model.newEmail); }}
                                isExecuting={isResendingConfirmationEmail}
                                executingChildren={<><Spinner size="sm" />{t('common.sending', 'Sending...')}</>}>
                                {t('common.resendEmail', 'Resend email')}
                            </ButtonAsync>
                        </Alert>
                    ) : null
                }

                <FormGroup>
                    <Label htmlFor="newEmail">{t('changeEmail.email', 'Email')}</Label>
                    <ValidatedInput type="email" name="newEmail" value={model.newEmail} onChange={e => change({ newEmail: e.currentTarget.value })} onBlur={e => validate('newEmail')} validationErrors={validationErrors['newEmail']} />
                    {
                        storeModel && !storeModel.emailConfirmed && model.newEmail === storeModel.email ? (
                            <div>
                                <ButtonAsync type="button" color="link" onClick={async e => { e.preventDefault(); await resendConfirmationEmail(model.newEmail); setHasSentConfirmationEmail(true); }}
                                    isExecuting={isResendingConfirmationEmail}
                                    executingChildren={<><Spinner size="sm" />{t('common.resendingEmail', 'Resending email...')}</>}>
                                    {t('changeEmail.resendVerificationEmail', 'Resend verification email')}
                                </ButtonAsync>
                            </div>
                            ): null
                    }
                </FormGroup>

                <FormButtons>
                    <ButtonAsync type="submit" color="primary" isExecuting={isChangingPassword}
                        executingChildren={<><Spinner size="sm" /> {t('changeEmail.updatingEmail', 'Updating email...')}</>}>
                        {t('changeEmail.updateEmail', 'Update email')}
                    </ButtonAsync>
                </FormButtons>
            </Form>
        </MainContainer>
    );
};
