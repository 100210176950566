import gql from "graphql-tag";
import { Guid } from "guid-string";
import { UserFieldsFragment } from "../generated/graphql";
import { roleGroupFieldsFragment } from "./RoleGroup";

export const userFieldsFragment = gql`
    fragment userFields on User {
        id
        userName
        email
        emailConfirmed
        phoneNumberConfirmed
        twoFactorEnabled
        lockoutEnd
        lockoutEnabled
        accessFailedCount
        roleGroup {
            ...roleGroupFields
        }
    }

    ${roleGroupFieldsFragment}
`;


export type User = Omit<UserFieldsFragment, '__typename' | 'roleGroup'>;

/**
 * Default values.
 */
export const userDefaultValues = (): Partial<User> => ({
    id: Guid.newGuid(),
    userName: '',
    email: '',
    emailConfirmed: false,
    phoneNumberConfirmed: false,
    twoFactorEnabled: false,
    lockoutEnd: null,
    lockoutEnabled: true,
    accessFailedCount: 0,
});