import * as React from "react";
import { AsyncActionStatus } from "../../shared/abstractStore";
import { useAsyncCallback } from "react-use-async-callback";
import { CreateExternalLoginAccountDetails, RegisterResult } from "./AccountService";
import { useAccountService } from "./useAccountService";

/**
 * Register an account for an authenticated external login.
 */
export function useCreateAccountForExternalLoginCallback(): [(model: CreateExternalLoginAccountDetails) => Promise<RegisterResult>, AsyncActionStatus] {
    const accountService = useAccountService();

    // Get the return url from the query string.
    const returnUrl = React.useMemo(() => {
        const params = new URLSearchParams(window.location.search);
        const returnUrl = params.get('returnUrl') ?? params.get('ReturnUrl') ?? '';
        return returnUrl;
    }, []);

    const [action, { isExecuting, errors }] = useAsyncCallback(async (model: CreateExternalLoginAccountDetails) => {
        let result = await accountService.createAccountForExternalLogin(model, returnUrl);

        // If we get here everything went well so redirect to the returnUrl.
        // Redirect the whole page (not just the react app) as its likely the returnUrl is handled on the server.
        window.location.href = result.returnUrl;

        return result;
    }, [accountService]);

    return [action, { isExecuting, errors }];
}
