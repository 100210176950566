import { gql, useMutation } from "@apollo/client";
import { useAsyncCallback } from "react-use-async-callback";
import { AsyncActionStatus } from "../../../shared/abstractStore";
import { JoinScheduledClassFromLocalStorageMutation, JoinScheduledClassFromLocalStorageMutationVariables, JoinScheduledClassFromLocalStorageResult } from '../generated/graphql';

export function useJoinScheduledClassFromLocalStorageMutation(): [(scheduledClassId: string, childDob: string, memberId: string, isWaitingList: boolean) => Promise<JoinScheduledClassFromLocalStorageResult>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<JoinScheduledClassFromLocalStorageMutation, JoinScheduledClassFromLocalStorageMutationVariables>(
        gql`
            mutation joinScheduledClassFromLocalStorageMutation ($scheduledClassId: ID!, $childDob: DateTimeOffset!, $memberId: ID!, $isWaitingList: Boolean!) {
                joinScheduledClassFromLocalStorage(scheduledClassId: $scheduledClassId, childDob: $childDob, memberId: $memberId, isWaitingList: $isWaitingList) {
                    message
                    returnURL
                }
            }
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (scheduledClassId: string, childDob: string, memberId: string, isWaitingList: boolean) => {
        const result = await mutationAction({ variables: { scheduledClassId, childDob, memberId, isWaitingList } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }

        return result.data?.joinScheduledClassFromLocalStorage as any;
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}