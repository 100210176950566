import { gql, useLazyQuery } from "@apollo/client";
import { Guid } from "guid-string";
import React from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { ConsumeMemberChildrenViewModelQuery, ConsumeMemberChildrenViewModelQueryVariables } from '../../generated/graphql';
import { childFieldsFragment } from "../../models/Child";
import { classLocationFieldsFragment } from "../../models/ClassLocation";
import { emergencyContactFieldsFragment } from "../../models/EmergencyContact";
import { genderFieldsFragment } from "../../models/Gender";
import { queryMessageFieldsFragment } from "../../models/QueryMessage";
import { scheduledClassFieldsFragment } from "../../models/ScheduledClass";
import { scheduledClassChildFieldsFragment } from "../../models/ScheduledClassChild";
import { termFieldsFragment } from "../../models/Term";

export function useConsumeMemberChildrenViewModel(id: string | null | undefined, options: AsyncLoadOptions = {}): AsyncLoadResult<ConsumeMemberChildrenViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<ConsumeMemberChildrenViewModelQuery, ConsumeMemberChildrenViewModelQueryVariables>(
        gql`
        query consumeMemberChildrenViewModelQuery ($id: ID!) {
            model: child (id: $id) {
                ...childFields

                scheduledClassChildren {
                    ...scheduledClassChildFields

                    scheduledClass {
                        ...scheduledClassFields

                        classLocation {
                            ...classLocationFields
                        }
                    }
                }
            }

            genders {
                ...genderFields
            }

            emergencyContacts (targetId: $id) {
                ...emergencyContactFields
            }

            queryMessages (childId: $id) {
                ...queryMessageFields

                member {
                    user {
                        profile {
                            firstName,
                            lastName
                        }
                    }
                }
            }

            terms {
                ...termFields
            }
        }

        ${childFieldsFragment}
        ${scheduledClassChildFieldsFragment}
        ${scheduledClassFieldsFragment}
        ${classLocationFieldsFragment}
        ${genderFieldsFragment}
        ${emergencyContactFieldsFragment}
        ${queryMessageFieldsFragment}
        ${termFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error]);
};