import { AccountService } from "./AccountService";
import { useServices } from "inject-typesafe-react";
import { AppServices } from "../../configure/configureServices";

/**
 * Hook to return the AccountService from the context (via useService).
 */
export function useAccountService(): AccountService {
    return useServices((services: AppServices) => services.accountService());
}
