import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConditionalFragment } from "react-conditionalfragment";
import { Card, CardBody, CardHeader, Col, Collapse, Row } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { ScheduledClassWithRelationships } from "./ClassFinder";
import { ClassFinderClass } from "./ClassFinderClass";
import "./classFinderDay.scss";
interface ClassFinderDayProps {
    dayName: string | undefined | null;
    classes: Array<ScheduledClassWithRelationships> | undefined | null;
    childDateOfBirth: string | undefined | null;

    childId?: string | undefined | null;
    isForCourtesyClass?: boolean;
    courtesyClassCreditId?: string | undefined | null;
    isSwappingClass?: boolean | undefined | null;
    oldScheduledClassChildId?: string | undefined | null;
}

export const ClassFinderDay = (props: ClassFinderDayProps) => {
    const {
        dayName,
        classes,
        childDateOfBirth,
        childId,
        isForCourtesyClass,
        courtesyClassCreditId,
        isSwappingClass = false,
        oldScheduledClassChildId
    } = props;
    const [isOpen, toggleIsOpen] = useToggleState();

    // Render the UI
    //
    return (
        <>
            <Row className="class-finder-location-day">
                <Card className="class-finder-location-day-card">
                    <CardHeader className="class-finder-location-day-card-header">
                        <Row className="class-finder-location-day-card-header-row">
                            <Col xs={1}>
                                <img className="class-finder-location-day-card-header-row-img" src="/img/originals/Cub-Cropped.png" alt="Happy Cubs Cub" style={{ borderRadius: '50%', width: '30px', height: '30px', objectFit: 'cover' }} />
                            </Col>

                            <Col>
                                <h2 className="class-finder-location-day-card-header-row-name">{dayName} classes</h2>
                            </Col>

                            <Col xs="auto">
                                <FontAwesomeIcon icon={isOpen ? 'circle-minus' : 'circle-plus'} className="class-finder-location-day-card-header-row-icon" onClick={() => toggleIsOpen()} />
                            </Col>
                        </Row>
                    </CardHeader>

                    <Collapse isOpen={isOpen}>
                        <CardBody className="class-finder-location-day-card-body">
                            <ConditionalFragment showIf={isOpen}>
                                {
                                    classes?.map(it => (
                                        <>

                                            <ConditionalFragment showIf={!childId}>
                                                <ClassFinderClass
                                                    scheduledClass={it}
                                                    childDateOfBirth={childDateOfBirth} />
                                            </ConditionalFragment>

                                            <ConditionalFragment showIf={!!childId}>
                                                <ClassFinderClass
                                                    scheduledClass={it}
                                                    childDateOfBirth={childDateOfBirth}
                                                    childId={childId}
                                                    isForCourtesyClass={isForCourtesyClass}
                                                    courtesyClassCreditId={courtesyClassCreditId}
                                                    isSwappingClass={isSwappingClass}
                                                    oldScheduledClassChildId={oldScheduledClassChildId} />
                                            </ConditionalFragment>
                                        </>
                                    ))
                                }
                            </ConditionalFragment>
                        </CardBody>
                    </Collapse>
                </Card>
            </Row>
        </>
    );
};