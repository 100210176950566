import { gql, useLazyQuery } from "@apollo/client";
import { Guid } from "guid-string";
import React from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { StaffClassAvailablityListViewModelQuery, StaffClassAvailablityListViewModelQueryVariables } from '../../generated/graphql';
import { childAttendanceFieldsFragment } from "../../models/ChildAttendance";
import { classLocationFieldsFragment } from "../../models/ClassLocation";
import { classStageFieldsFragment } from "../../models/ClassStage";
import { classSubStageFieldsFragment } from "../../models/ClassSubStage";
import { scheduledClassFieldsFragment } from "../../models/ScheduledClass";
import { scheduledClassChildFieldsFragment } from "../../models/ScheduledClassChild";

export function useStaffClassAvailablityListViewModel(termId: string | null | undefined, options: AsyncLoadOptions = {}): AsyncLoadResult<StaffClassAvailablityListViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<StaffClassAvailablityListViewModelQuery, StaffClassAvailablityListViewModelQueryVariables>(
        gql`
        query staffClassAvailablityListViewModelQuery ($termId: ID!) {
            scheduledClasses (termId: $termId) {
                ...scheduledClassFields
            }

            scheduledClassChildren {
                ...scheduledClassChildFields
            }

            classLocations {
                ...classLocationFields
            }

            classStages {
                ...classStageFields
            }

            classSubStages {
                ...classSubStageFields
            }

            childAttendances {
                ...childAttendanceFields
            }
        }

        ${scheduledClassFieldsFragment}
        ${scheduledClassChildFieldsFragment}
        ${classLocationFieldsFragment}
        ${classStageFieldsFragment}
        ${classSubStageFieldsFragment}
        ${childAttendanceFieldsFragment}

        `,
        {
            variables: {
                termId: termId ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error]);
};