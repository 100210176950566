import { Login } from './Login'
import { Logout } from './Logout'
import { RouteEntry } from '../../shared/routes';

export const apiAuthorizationRoutes: Array<RouteEntry> = [
    { path: '/authentication/login', element: loginAction('login') },
    { path: '/authentication/login-callback', element: loginAction('login-callback') },
    { path: '/authentication/login-failed', element: loginAction('login-failed') },
    { path: '/authentication/profile', element: loginAction('profile') },
    { path: '/authentication/register', element: loginAction('register') },

    { path: '/authentication/logout', element: logoutAction('logout') },
    { path: '/authentication/logout-callback', element: logoutAction('logout-callback') },
    { path: '/authentication/logged-out', element: logoutAction('logged-out') },
];

function loginAction(name: string){
    return (<Login action={name} />);
}

function logoutAction(name: string) {
    return (<Logout action={name} />);
}
