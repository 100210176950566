import { AsyncActionStatus } from "../../shared/abstractStore";
import { useAsyncCallback } from "react-use-async-callback";
import { useAccountService } from "./useAccountService";

/**
 * Callback to resend the email confirmation email.
 */
export function useResendConfirmationEmailCallback(): [(email: string) => Promise<boolean>, AsyncActionStatus] {
    const accountService = useAccountService();

    const [action, { isExecuting, errors }] = useAsyncCallback(async (email: string) => {
        return await accountService.resendConfirmEmail(email);
    }, [accountService]);

    return [action, { isExecuting, errors }];
}
