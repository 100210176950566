import * as React from "react";
import { AsyncActionStatus } from "../../shared/abstractStore";
import { useAsyncCallback } from "react-use-async-callback";
import { useAccountService } from "./useAccountService";

/**
 * Confirm an email address.
 */
export function useConfirmEmailCallback(): [() => Promise<boolean>, AsyncActionStatus] {
    const accountService = useAccountService();

    // Get the return url from the query string.
    const { userId, code } = React.useMemo(() => {
        const params = new URLSearchParams(window.location.search);
        const userId = params.get('userId') ?? params.get('UserId') ?? '';
        const code = params.get('code') ?? params.get('Code') ?? '';
        return { userId, code };
    }, []);

    const [action, { isExecuting, errors }] = useAsyncCallback(async () => {
        return await accountService.confirmEmail({ userId: userId, code: code });
    }, [accountService, userId, code]);

    return [action, { isExecuting, errors }];
}
