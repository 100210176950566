import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { ScheduledClassesWithChildrenForMembersClassFinderViewModelQuery, ScheduledClassesWithChildrenForMembersClassFinderViewModelQueryVariables } from '../../generated/graphql';
import { childAbsenceFieldsFragment } from "../../models/ChildAbsence";
import { classLocationFieldsFragment } from "../../models/ClassLocation";
import { classStageFieldsFragment } from "../../models/ClassStage";
import { classSubStageFieldsFragment } from "../../models/ClassSubStage";
import { classTypeFieldsFragment } from "../../models/ClassType";
import { scheduledClassFieldsFragment } from "../../models/ScheduledClass";
import { termFieldsFragment } from "../../models/Term";

export interface ScheduledClassesWithChildrenForMembersClassFinderViewModelOptions extends AsyncLoadOptions {
    includeChildAbsences?: boolean;
}

export function useScheduledClassesWithChildrenForMembersClassFinderViewModel(options: ScheduledClassesWithChildrenForMembersClassFinderViewModelOptions = {}): AsyncLoadResult<ScheduledClassesWithChildrenForMembersClassFinderViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<ScheduledClassesWithChildrenForMembersClassFinderViewModelQuery, ScheduledClassesWithChildrenForMembersClassFinderViewModelQueryVariables>(
        gql`
        query scheduledClassesWithChildrenForMembersClassFinderViewModelQuery ($childAbsenceFirst: Int) {
            items: scheduledClasses (includeOpenTermOnly: true) {
                ...scheduledClassFields

                lessonDatesForCurrentTerm

                nextLessonDateWithSpacesAvailableForCurrentTerm
                nextLessonDateWithSpacesAvailableForNextTerm
            }

            classTypes {
                ...classTypeFields
            }

            classStages {
                ...classStageFields
            }

            classSubStages {
                ...classSubStageFields
            }

            classLocations {
                ...classLocationFields
            }

            scheduledClassChildren (includeOpenTermOnly: true) {
                id
                scheduledClassId
                childId
            }

            terms {
                ...termFields
            }

            childAbsences (includeOpenTermOnly: true, first: $childAbsenceFirst) {
                ...childAbsenceFields
            }
        }

        ${scheduledClassFieldsFragment}
        ${classTypeFieldsFragment}
        ${classStageFieldsFragment}
        ${classSubStageFieldsFragment}
        ${classLocationFieldsFragment}
        ${termFieldsFragment}
        ${childAbsenceFieldsFragment}
        `,
        {
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            variables: {
                childAbsenceFirst: options.includeChildAbsences ? undefined : 0,
            },
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error]);
};