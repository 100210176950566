import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useSidebarState } from "../../../../globalState/sidebarState/useSidebarState";
import "./sidebarMinimizer.scss";

/**
 * Button to minimize the sidebar.
 */
export const SidebarMinimizer = () => {
    const [sidebarState, setSidebarState] = useSidebarState();
    const { t } = useTranslation();

    return (
        <>
            {/* Button to toggle minimized sidebar */}
            <div className="sidebar-minimizer" onClick={() => setSidebarState(prevState => ({ ...prevState, minimized: !prevState.minimized }))}>
                <FontAwesomeIcon icon={sidebarState.minimized ? 'angle-right' : 'angle-left'} />
                <span className="visually-hidden">
                    {t('sidebarMinimizer.screenReaderText', 'Minimise sidebar')}
                </span>
            </div>
        </>

        );
};