import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { MainContainer } from '../shared/mainContainer/MainContainer';
import { Banner } from '../shared/banner/Banner';

/**
 * Component to show when a route is not found. 
 */
export const PageNotFound = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            <Banner>
                <h1>{t('notFound.notFoundHeading', 'Sorry the page you have requested cannot be found')}</h1>
            </Banner>
            <MainContainer>
                <p>
                    {t('notFound.helpText', 'The link you have accessed is for a page that could not be found.  Please check and try again, or try navigating to the page you want using the apps menus.')}
                </p>

                <Button color="primary" outline onClick={() => navigate(-1)}>
                    {t('notFound.goBack', 'Go back to the previous page')}
                </Button>
            </MainContainer>
        </>
        );
};