import { NavLink, NavLinkProps } from "react-router-dom";

export interface NavLinkTrackActiceProps extends NavLinkProps {
    children: React.ReactNode,
}

/**
 * NavLink component that integrates with NavLink from react-router and nav-link from reactstrap and tracks the active class for the active link and allows more than
 * one child component.
 * 
 * Frustrating that this behaviour isn't built in anywhere thanks to react-router 6.
 * @param props
 */
export const NavLinkTrackActive = (props: NavLinkTrackActiceProps) => {
    const {
        className,
        children,
        ...rest
    } = props;

    return (
        <NavLink className={({ isActive }) => `nav-link ${isActive? 'active': ''} ${className ?? ''}`} {...rest}>
            <>
                {children}
            </>
        </NavLink>
    );
}