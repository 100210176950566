import { ReactNode } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { Card, CardBody, CardFooter, Col, Row, Table } from "reactstrap";
import { isNullOrUndefined } from "util";
import "./cardsOrTable.scss";

export type CardsOrTableViewMode = 'cards' | 'table';

export type CardsOrTableColumnFunction<ItemType extends { id: string }> = (item: ItemType, view: CardsOrTableViewMode) => ReactNode | undefined | null;

export interface CardsOrTableProps<ItemType extends { id: string }> {
    viewMode: CardsOrTableViewMode,
    items: Array<ItemType>,
    columns: Array<CardsOrTableColumnFunction<ItemType>>,
    buttons?: CardsOrTableColumnFunction<ItemType> | undefined | null,
    tableHeadings: Array<ReactNode>,
    onItemClick?: (item: ItemType) => void,
}

export const CardsOrTable = <ItemType extends { id: string }, >(props: CardsOrTableProps<ItemType>) => {
    const {
        viewMode = 'cards',
        items,
        columns,
        buttons,
        tableHeadings,
        onItemClick,
    } = props;

    switch (viewMode) {
        case 'cards':
            return (
                <div className={`cards-or-table cards-or-table-${viewMode}`}>
                    <Row>
                            {
                                items?.map((item, index) => {
                                    return (
                                        <Col key={item?.id ?? index}
                                            xs={12} sm={6} lg={3}>
                                            <Card className="cards-or-table-card">
                                                <CardBody onClick={() => !!onItemClick && onItemClick(item)} style={{ cursor: !!onItemClick ? 'pointer' : undefined }}>
                                                    {
                                                        columns.map((column, columnIndex) => {
                                                            const node = column(item, viewMode);
                                                            if (isNullOrUndefined(node)) {
                                                                return null;
                                                            }

                                                            return (
                                                                <div key={columnIndex}>
                                                                    {node}
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </CardBody>
                                                <CardFooter>
                                                    <Row>
                                                        <Col>
                                                        </Col>
                                                        <Col xs="auto">
                                                            {buttons && buttons(item, viewMode)}
                                                        </Col>
                                                    </Row>
                                                </CardFooter>
                                            </Card>
                                        </Col>
                                    );
                                })
                            }
                    </Row>
                </div>
                );
        case 'table':
            return (
                <div className={`cards-or-table cards-or-table-${viewMode}`}>
                    <Table responsive striped>
                        <thead>
                            <tr>
                                {
                                    tableHeadings.map((heading, columnIndex) => {
                                        const node = heading;
                                        if (isNullOrUndefined(node)) {
                                            return null;
                                        }

                                        return (
                                            <th key={columnIndex}>
                                                {node}
                                            </th>
                                        );
                                    })
                                }
                                <ConditionalFragment showIf={!!buttons}>
                                    <th>&nbsp;</th>
                                </ConditionalFragment>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                items?.map((item, itemIndex) => (
                                    <tr key={item.id ?? itemIndex} onDoubleClick={() => !!onItemClick && onItemClick(item)}>
                                        {
                                            columns.map((column, columnIndex) => {
                                                const node = column(item, viewMode);
                                                if (isNullOrUndefined(node)) {
                                                    return null;
                                                }

                                                return (
                                                    <td key={columnIndex}>
                                                        {node}
                                                    </td>
                                                );
                                            })
                                        }

                                        <ConditionalFragment showIf={!!buttons}>
                                            <td className="cards-or-table-table-buttons-td">
                                                {buttons && buttons(item, viewMode)}
                                            </td>
                                        </ConditionalFragment>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </div>
            );
    }
}
