import gql from "graphql-tag";
import { BlobReferenceFieldsFragment } from "../generated/graphql";

export const blobReferenceFieldsFragment = gql`
    fragment blobReferenceFields on BlobReference {
        id
        filename
        mimeType
        storageReference
        archived
        url
    }
`;


export type BlobReference = Omit<BlobReferenceFieldsFragment, '__typename'>;
