import { Nav, NavProps } from 'reactstrap';
import './sidebarNav.scss';

export interface SidebarNavProps extends NavProps {
}

/**
 * Display of Navigation components in the sidebar.
 */
export const SidebarNav = (props: SidebarNavProps) => {
    const {
        className,
        ...rest
    } = props;

    return (
        <Nav className={`sidebar-nav ${className ?? ''}`} {...rest} />
    );
};
