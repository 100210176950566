import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import { Child } from "../../api/main/models/Child";
import { ClassLocation } from "../../api/main/models/ClassLocation";
import { ClassStage } from "../../api/main/models/ClassStage";
import { ClassSubStage } from "../../api/main/models/ClassSubStage";
import { ClassType } from "../../api/main/models/ClassType";
import { ScheduledClass } from "../../api/main/models/ScheduledClass";
import { ScheduledClassChild } from "../../api/main/models/ScheduledClassChild";
import { ScheduledClassStaff } from "../../api/main/models/ScheduledClassStaff";
import { useCurrentUserProfile } from "../../api/main/profiles/useCurrentUserProfile";
import { useConsumeStaffScheduledClassesListViewModel } from "../../api/main/scheduledClasses/viewModels/useConsumeStaffScheduledClassesListViewModel";
import { useTerm } from "../../api/main/terms/useTerm";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { StaffClassItem } from "./StaffClassItem";
import { useDate } from "./useDate";

export interface ScheduledClassChildrenWithChildDetails extends ScheduledClassChild {
    child?: Child,
}

export interface ScheduledClassWithClassLocationAndChildren extends ScheduledClass {
    classLocation?: ClassLocation,
    scheduledClassChildren?: Array<ScheduledClassChildrenWithChildDetails>,
}
export interface ScheduledClassStaffWithScheduledClassClassStageAndSubStage extends ScheduledClassStaff {
    scheduledClass?: ScheduledClassWithClassLocationAndChildren,
    classStage?: ClassStage,
    classSubStage?: ClassSubStage,
    classType?: ClassType,
}
/**
 * StaffArea for consuming ScheduledClasses and related details
 */
export const StaffClassesTab = () => {
    const { t } = useTranslation();
    const { chosenDay: _chosenDay, calculcateNextDay, calculcatePreviousDay } = useDate();

    // Load the data - Current UserProfile
    const {
        data: {
            model
        }, isLoading: _isLoading, errors: loadErrors,
    } = useCurrentUserProfile();

    // Get the current User's StaffId
    const currentUserStaffId = model?.id;

    const [activeTerm, setActiveTerm] = useState<string>();

    // CurrentTerm in this context will be the term that the chosenDay falls within
    const {
        data: {
            model: currentTerm
        }, isLoading: isLoadingCurrentTerm, errors: currentTermLoadErrors,
    } = useTerm(activeTerm);

    // Get all the supporting data using the StaffId - this will return only children that are not tagged as isOnWaitingList
    // We are only interested in the children registered to partake in this class
    const {
        data: {
            items: storeItems,
            classStages,
            classSubStages,
            classTypes,
            scheduledClassCancellations,
            terms
        }, isLoading: _isLoadingSupportingData, errors: supportingDataLoadErrors,
    } = useConsumeStaffScheduledClassesListViewModel(currentUserStaffId);
    const isLoading = _isLoading || _isLoadingSupportingData || isLoadingCurrentTerm;

    const _items = useMemo(() => storeItems?.map(item => {
        const classStage = classStages.find(it => it.id === item.scheduledClass.currentClassStageId);
        const classSubStage = classSubStages.find(it => it.id === item.scheduledClass.currentClassSubStageId);
        const classType = classTypes.find(it => it.id === item.scheduledClass.classTypeId);

        return {
            ...item,
            classStage,
            classSubStage,
            classType,
        };
    }), [storeItems, classStages, classSubStages, classTypes]);

    // Calendar functionality
    // State
    const [chosenDay, setChosenDay] = useState<string>();
    // Load initial value for chosenDay which is today
    useEffect(() => setChosenDay(_chosenDay), [_chosenDay]);

    // Set the active term based on the chosenDay, by checking which start and end date the chosenDay falls between
    //useEffect(() => {
    //    if (terms) {
    //        const term = terms.find(it => moment(chosenDay).isBetween(it.startDate, it.endDate, 'day', '[]'));
    //        setActiveTerm(term?.id);
    //    }
    //}, [chosenDay, terms]);

    // Traverse dates
    const goToNextDay = () => {
        setChosenDay(calculcateNextDay(chosenDay ?? ''));
    };
    const goToPreviousDay = () => {
        setChosenDay(calculcatePreviousDay(chosenDay ?? ''));
    };

    // Filtering - we can now filter by the chosen day to only show classes for that day
    const items = useMemo(() => {
        let ret = (_items ?? []);
        ret = ret.filter(item => item.scheduledClass.dayOfWeek === moment(chosenDay).day());

        ret = ret.filter(it => it.scheduledClass.termId === currentTerm?.id);

        // Sort the ScheduledClasses by the StartTime so the soonest appears at the top
        ret = ret.sort((a, b) => {
            const timeA = a.scheduledClass?.startTimeHours * 60 + a.scheduledClass?.startTimeMinutes;
            const timeB = b.scheduledClass?.startTimeHours * 60 + b.scheduledClass?.startTimeMinutes;
            return timeA - timeB;
        });

        // Filter to only include classes that have a matching date in lessonDatesForCurrentTerm, otherwise we can assume the class is not running on this day
        const chosenDate = moment(chosenDay).format("YYYY-MM-DD");
        ret = ret.filter(item =>
            item.scheduledClass?.lessonDatesForCurrentTerm?.some(date =>
                moment(date).format("YYYY-MM-DD") === chosenDate
            )
        );

        return ret;
    }, [_items, chosenDay, currentTerm]);

    // Highlight the next class - if multiple classes on a dayview then we want to draw attention to the next one to run
    const nextClassId = useMemo(() => {
        const now = moment().hours(); // Get the current hour
        const today = moment().date(); // Date of month eg: 24 (th)

        return items.find(it => it.scheduledClass.startTimeHours > now && moment(chosenDay).date() === today)?.scheduledClassId; // Return the first class after the current hour and isToday
    }, [items, chosenDay]);

    const myTerms = useMemo(() => {
        if (!terms) return;

        // Return terms that have an endDate that is greater than today
        return terms.filter(term => moment(term.endDate) >= moment());
    }, [terms]);

    // Render the UI
    //
    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row style={{ marginBottom: '12px' }}>
                        <Col></Col>

                        <Col>
                            <FormGroup>
                                <Input type="select" value={activeTerm} onChange={e => setActiveTerm(e.target.value)}>
                                    {myTerms?.map(term => (
                                        <option key={term.id} value={term.id}>{term.name}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>

                        <Col></Col>
                    </Row>

                    <Row className="text-center">
                        <Col>
                            <Button size="sm" onClick={() => goToPreviousDay()}>
                                {t('staffClassesTab.backDate.value', '< {{date, DD MMMM YYYY}}', { date: moment(calculcatePreviousDay(chosenDay ?? '')) })}
                            </Button>
                        </Col>
                        <Col>
                            <h3>{t('staffClassesTab.dayOfWeek.value', '{{dayOfWeek, dddd}}', { dayOfWeek: moment(chosenDay) })}</h3>
                            <h3>{t('common.longDate', '{{date, DD MMMM YYYY}}', { date: moment(chosenDay) })}</h3>
                        </Col>
                        <Col>
                            <Button size="sm" onClick={() => goToNextDay()}>
                                {t('staffClassesTab.forwardDate.value', '{{date, DD MMMM YYYY}} >', { date: moment(calculcateNextDay(chosenDay ?? '')) })}
                            </Button>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer>
                <AlertOnErrors
                    errors={[
                        loadErrors,
                        supportingDataLoadErrors,
                        currentTermLoadErrors
                    ]}
                />

                {items?.map(item => (
                    <StaffClassItem
                        item={item as ScheduledClassStaffWithScheduledClassClassStageAndSubStage}
                        key={item.id}
                        currentUserStaffId={currentUserStaffId}
                        dateInView={chosenDay ?? ''}
                        nextClassId={nextClassId}
                        scheduledClassCancellations={scheduledClassCancellations}
                    />
                ))}

                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound />
                </ConditionalFragment>
            </MainContainer>
        </>
    );
};