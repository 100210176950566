import './pillsNavBar.scss';

import * as React from 'react';
import { Nav } from 'reactstrap';

export interface PillsNavBarProps {
    className?: string,
    style?: React.CSSProperties,
    children?: React.ReactNode,
    justified?: boolean,
    textColor?: 'inherit' | 'white'
}

/**
 * Navigation of NavItems via styled pills.
 * @param props
 */
export const PillsNavBar = (props: PillsNavBarProps) => {
    const {
        className,
        style,
        children,
        justified,
        textColor = 'inherit',
    } = props;

    return (
        <Nav pills justified={justified} className={`pills-nav-bar pills-nav-bar-text-color-${textColor} ${className ?? ''}`} style={style}>
            {children}
        </Nav>
        );
};