import * as React from "react";
import { AsyncActionStatus } from "../../shared/abstractStore";
import { useAsyncCallback } from "react-use-async-callback";
import { RegisterRequest, RegisterResult } from "./AccountService";
import { useAccountService } from "./useAccountService";

/**
 * Callback to Register an account.
 */
export function useRegisterAccountCallback(): [(model: RegisterRequest) => Promise<RegisterResult>, AsyncActionStatus] {
    const accountService = useAccountService();

    // Get the return url from the query string.
    const returnUrl = React.useMemo(() => {
        const params = new URLSearchParams(window.location.search);
        const returnUrl = params.get('returnUrl') ?? params.get('ReturnUrl') ?? '';
        return returnUrl;
    }, []);

    const [action, { isExecuting, errors }] = useAsyncCallback(async (model: RegisterRequest) => {
        let result = await accountService.register(model, returnUrl);

        // If we have additional steps to take before we are completely signed in, handle those now.
        if (result.requiresTwoFactor || result.requiresEmailConfirmation) {
            // Don't want to redirect to returnUrl.
            return result;
        }

        // If we get here we have a successful login

        return result;
    }, [accountService]);

    return [action, { isExecuting, errors }];
}
