import { gql, useLazyQuery } from "@apollo/client";
import { Guid } from "guid-string";
import React from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { classLocationFieldsFragment } from "../../models/ClassLocation";
import { scheduledClassFieldsFragment } from "../../models/ScheduledClass";
import { ScheduledClassForScheduledClassCanellationSupportingDataQuery, ScheduledClassForScheduledClassCanellationSupportingDataQueryVariables } from '../../generated/graphql';
import { cancellationReasonFieldsFragment } from "../../models/CancellationReason";
import { scheduledClassChildFieldsFragment } from "../../models/ScheduledClassChild";
import { scheduledClassCancellationFieldsFragment } from "../../models/ScheduledClassCancellation";

/**
 * Load the ScheduledClass from the API
 */
export function useScheduledClassForScheduledClassCanellationSupportingData(id: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<ScheduledClassForScheduledClassCanellationSupportingDataQuery> {
    const [load, { data, loading, error, refetch }] = useLazyQuery<ScheduledClassForScheduledClassCanellationSupportingDataQuery, ScheduledClassForScheduledClassCanellationSupportingDataQueryVariables>(
        gql`
        query scheduledClassForScheduledClassCanellationSupportingDataQuery ($id: ID!) {
            model: scheduledClass(id: $id) {
                ...scheduledClassFields

                lessonDatesForCurrentTerm

                scheduledClassChildren {
                    ...scheduledClassChildFields
                }

                classLocation {
                    ...classLocationFields
                }
            }

            cancellationReasons {
                ...cancellationReasonFields
            }

            scheduledClassCancellations {
                ...scheduledClassCancellationFields
            }
        }

        ${scheduledClassFieldsFragment}
        ${scheduledClassChildFieldsFragment}
        ${classLocationFieldsFragment}
        ${cancellationReasonFieldsFragment}
        ${scheduledClassCancellationFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
};