// Import the required localization files for momentjs.
//import 'moment/locale/en-us'; // Built in.
import 'moment/locale/en-gb';
//import 'moment/locale/fr';

// Import the required localization files for numeraljs.
//import 'numeral/locale/en-us'; // Built in.
import 'numeral/locales/en-gb';
//import 'numeral/locales/fr';


/**
 * Languages available in the application. 
 */
export const availableLanguages = [
    // Always put the default language and locale first.
    { code: 'en-GB', name: 'English (British)', flagCountryCode: 'gb', momentLocale: 'en-gb', numeralLocale: 'en-gb' },

    // Other languages.
    { code: 'en-US', name: 'English (American)', flagCountryCode: 'us', momentLocale: 'en', numeralLocale: 'en' },
   // { code: 'fr', name: 'Français', flagCountryCode: 'fr', momentLocale: 'fr', numeralLocale: 'fr' },
    //{ code: 'pt', name: 'Português', flagCountryCode: 'pt', momentLocale: 'pt', numeralLocale: 'pt-pt' },
    //{ code: 'es', name: 'Español', flagCountryCode: 'es', momentLocale: 'es', numeralLocale: 'es' }
];

/**
 * Lookup the best matching language for the language code given.
 */
export const findLanguage = (code: string) => {
    // If the code matches a fully language code then use it.
    let ret = availableLanguages.find(item => item.code === code);
    if (ret) {
        return ret;
    }

    // Try find a matching language with a shorter version of the code if we can, e.g. en-GB will look for en.
    const indexOfDash = code.indexOf('-');
    if (indexOfDash !== -1) {
        const shortCode = code.substring(0, indexOfDash);

        // If we have a short code language available, use it.
        ret = availableLanguages.find(item => item.code === shortCode);
        if (ret) {
            return ret;
        }

        // If we have a short code, but didn't find an exact match, try find any language that shares the short code and return the first one we find.
        ret = availableLanguages.find(item => item.code.indexOf(`${shortCode}-`) === 0);
        if (ret) {
            return ret;
        }
    }

    // If we get here, we couldn't match a language at all, so just return the default language.
    return availableLanguages[0];
};
