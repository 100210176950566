import { ApiFetch } from "apifetch-json";
import { BlobUploadService } from "../../api/main/blobReferences/BlobUploadService";
import { mainApiConfig } from "../../configure/mainApiConfig";

/**
 * File upload adapter for CKEdtior5 that hooks the image upload capabilities up to our BlobReferences for storing the blobs and generating the URLs.
 */
export class BlobReferenceUploadAdapter {
    loader: any;
    apiFetch: ApiFetch;

    constructor(loader: any, apiFetch?: ApiFetch) {
        // The file loader instance to use during the upload.
        this.loader = loader;

        this.apiFetch = apiFetch ?? new ApiFetch();
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
            .then((file: File) => new Promise((resolve, reject) => {
                const blobService = new BlobUploadService(mainApiConfig.blobUploadEndpoint, this.apiFetch);

                // Upload as a blob reference.
                return blobService
                    .uploadFile(file)
                    .then(blob => {
                        if (blob && blob.id) {
                            resolve({
                                //default: blob.url,
                                default: `${mainApiConfig.blobDownloadEndpoint}/${blob.id}`,
                            });
                        } else {
                            reject(`Could not upload ${file.name}`);
                        }
                    });
            }));
    }
}

/**
 * Plugin to create the adapter (using default ApiFetch).
 * @param editor
 */
export function BlobReferenceUploadAdapterPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
        // Configure the URL to the upload script in your back-end here!
        return new BlobReferenceUploadAdapter(loader);
    };
}
