import moment from "moment";
import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Button } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { Gender } from "../../api/main/models/Gender";
import { ScheduledClassStaff } from "../../api/main/models/ScheduledClassStaff";
import { getScheduledClassSummary } from "../scheduledClass/getScheduledClassSummary";
import { StyledModal } from "../shared/styledModal/StyledModal";
import { MembersChildrenListWithScheduledClasses } from "./MembersChildrenList";
import { MessageModal } from "./MessageModal";
import { getNextClassForChild } from "../shared/Utilities/getNextClassForChild";
import "./membersChildrenItem.scss";
import { useNavigate } from "react-router-dom";
import { ClassType } from "../../api/main/models/ClassType";
import { ClassStage } from "../../api/main/models/ClassStage";
import { ClassSubStage } from "../../api/main/models/ClassSubStage";
import { HorizontalRule } from "../shared/horizontalRule/HorizontalRule";
import { Term } from "../../api/main/models/Term";
import { useCurrentTerm } from "../../api/main/terms/useCurrentTerm";
import { useNextTerm } from "../../api/main/terms/useNextTerm";
import { ScheduledClass } from "../../api/main/models/ScheduledClass";
import { ClassLocation } from "../../api/main/models/ClassLocation";

export interface MembersChildrenItemProps {
    model: MembersChildrenListWithScheduledClasses | undefined | null,
    genders: Array<Gender> | undefined | null,
    scheduledClassStaffs: Array<ScheduledClassStaff> | undefined | null,
    showManageButton?: boolean,

    classTypes?: Array<ClassType> | undefined | null,
    classStages?: Array<ClassStage> | undefined | null,
    classSubStages?: Array<ClassSubStage> | undefined | null,

    hasMultipleChildren?: boolean,
    childNumber?: number,
    terms: Array<Term> | undefined | null;
}

interface ScheduledClassWithLocationAndLessonDates extends ScheduledClass {
    classLocation?: ClassLocation;
    lessonDatesForCurrentTerm?: Array<string>
}

/**
 * Component that renders a single Child for MembersChildrenList
 */
export const MembersChildrenItem = (props: MembersChildrenItemProps) => {
    const {
        model,
        showManageButton = true,
        classTypes,
        classStages,
        classSubStages,
        hasMultipleChildren,
        childNumber,
        terms
    } = props;
    const { t } = useTranslation();

    const {
        data: {
            model: currentTerm
        }
    } = useCurrentTerm();

    const {
        data: {
            model: nextTerm
        }
    } = useNextTerm();

    const termToShow = useMemo(() => {
        if (!currentTerm && !nextTerm) return null;

        if (currentTerm) return currentTerm;

        return nextTerm;
    }, [currentTerm, nextTerm]);

    // Determine the next class for the child
    const classes = useMemo(() => model?.scheduledClassChildren?.filter(item => item.isOnWaitingList === false && item.scheduledClass.termId === termToShow?.id)?.map(it => it.scheduledClass), [model, termToShow]);

    const nextSwimClass = useMemo(() => getNextClassForChild(classes?.filter(it => it.classTypeId === classTypes?.find(it => it.name === 'Swim Cubs')?.id ?? '')), [classes, classTypes]);
    const nextMusicClass = useMemo(() => getNextClassForChild(classes?.filter(it => it.classTypeId === classTypes?.find(it => it.name === 'Music Cubs')?.id ?? '')), [classes, classTypes]);

    const myClassTypes = useMemo(() => classTypes?.filter(it => classes?.map(it => it.classTypeId).includes(it.id)), [classTypes, classes]);
    const myMissingClassTypes = useMemo(() => classTypes?.filter(it => !classes?.map(it => it.classTypeId).includes(it.id)), [classTypes, classes]);

    // Handling toggling of the Send message modal
    const [messageModalIsOpen, _toggleMessageModal] = useToggleState();
    const toggleMessageModal = useCallback(() => {
        _toggleMessageModal();
    }, [_toggleMessageModal]);

    const navigate = useNavigate();

    // Open when the user clicks on the elements we have made into hyperlinks.
    // NOTE we don't make the whole tile a hyperlink as we have extra buttons inside the tile which would cause issues.
    const openOnClick = useCallback(() => {
        if (showManageButton) {
            navigate(`/my/children/details/${model?.id}`);
        }
    }, [model, showManageButton, navigate]);

    // Get the next class date
    const getNextClassDate = useCallback((scheduledClass: ScheduledClassWithLocationAndLessonDates) => {
        const today = new Date();

        const nextClassDate = scheduledClass?.lessonDatesForCurrentTerm?.map(lesson => new Date(lesson))?.find(lesson => lesson > today);

        // 2 years from now - Used as a default date if we can't find a next class date
        const twoYearsFromNow = new Date();
        twoYearsFromNow.setFullYear(twoYearsFromNow.getFullYear() + 2);

        return nextClassDate ?? twoYearsFromNow;
    }, []);

    // Return our classes in order of when they next run
    const nextClassesInOrder = useMemo(() => {
        if (!nextSwimClass && !nextMusicClass) return [];

        if (!nextSwimClass && nextMusicClass) {
            return [nextMusicClass];
        }

        if (nextSwimClass && !nextMusicClass) {
            return [nextSwimClass];
        }

        const res = [];

        const nextSwimDate = getNextClassDate(nextSwimClass ?? '');
        const nextMusicDate = getNextClassDate(nextMusicClass ?? '');

        if (nextSwimDate < nextMusicDate) {
            res.push(nextSwimClass);
            res.push(nextMusicClass);
        } else {
            res.push(nextMusicClass);
            res.push(nextSwimClass);
        }

        return res;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextSwimClass, nextMusicClass]);

    // Render the UI
    //
    return (
        <>
            <div className="members-children-item-child">
                <div className="members-children-item-child-header" onClick={openOnClick} style={{ cursor: 'pointer' }}>

                    {/* Yellow Header */}
                    <div className="members-children-item-child-header-topbar">
                        <ConditionalFragment showIf={!!hasMultipleChildren}>
                            <h6 className="members-children-item-child-header-topbar-child-number">{t('common.childNumber', 'Child {{childNumber}}', { childNumber })}</h6>
                        </ConditionalFragment>

                        <h2 className="members-children-item-child-header-topbar-child-name">{t('common.fullName', '{{firstName}} {{lastName}}', { firstName: model?.firstName, lastName: model?.lastName })}</h2>

                        <h6>{t('common.age', 'age')}
                            <> </>
                            {
                                moment().diff(model?.dateOfBirth, 'years') > 0 ? <>{moment().diff(model?.dateOfBirth, 'years')} {t('common.years', 'years')}</> : <>{moment().diff(model?.dateOfBirth, 'months')} {t('common.months', 'months')}</>
                            }
                        </h6>
                    </div>
                </div>

                {/* SECTION 1 */}
                {/* No Classes Message */}
                <ConditionalFragment showIf={!nextClassesInOrder?.length}>
                    <div className="members-children-item-child-no-classes">
                        <h5 className="members-children-item-child-no-classes-header">{t('membersChildrenItem.noClassMessage', 'You\'ve set up a profile for {{firstName}}', { firstName: model?.firstName })}</h5>
                        <p className="members-children-item-child-no-classes-icon">&#127881;</p>
                        <p className="members-children-item-child-no-classes-footer">{t('membersChildrenItem.wouldYouLike', 'Would you like to join a class?')}</p>
                    </div>
                </ConditionalFragment>

                {/* We have a next class */}
                <ConditionalFragment showIf={!!nextClassesInOrder?.length}>
                    <div className="members-children-item-child-first-class">
                        <h5 className="members-children-item-child-first-class-header">{t('membersChildrenItem.bookedForSwimCubs', 'Booked for {{classType}}', { classType: classTypes?.find(it => it.id === nextClassesInOrder[0]?.classTypeId)?.name ?? '' })} <span className="members-children-item-child-first-class-header-term">{terms?.find(it => it.id === nextClassesInOrder[0]?.termId)?.name}</span></h5>
                        <p className="members-children-item-child-first-class-details">{getScheduledClassSummary(nextClassesInOrder[0], { classLocation: nextClassesInOrder[0]?.classLocation })}</p>
                        <p className="members-children-item-child-first-class-stage">{classStages?.find(it => it.id === nextClassesInOrder[0]?.currentClassStageId)?.name} {classSubStages?.find(it => it.id === nextClassesInOrder[0]?.currentClassSubStageId)?.name}</p>
                        <p className="members-children-item-child-first-class-next">
                            {t('membersChildrenItem.nextClass', 'Next class is on {{date, Do MMMM YYYY}}', { date: moment(getNextClassDate(nextClassesInOrder[0])) })}
                        </p>
                    </div>
                </ConditionalFragment>

                <HorizontalRule />

                {/* SECTION 2 */}
                {/* Find Class Buttons */}
                <div className="members-children-item-child-find-class-buttons">
                    <ConditionalFragment showIf={!myClassTypes?.length}>
                        {
                            classTypes?.map(classType => {
                                return <Button className={`members-children-item-child-find-class-buttons-${classType.name.split(' ')[0].toLocaleLowerCase()}`} onClick={() => navigate(`/my/class-finder/${model?.id}?classType=${encodeURIComponent(classType.id)}`)}>{t('membersChildrenItem.findClass', 'Find a {{classType}} class', { classType: classType.name })}</Button>;
                            })
                        }
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!!myClassTypes?.length && !!myMissingClassTypes?.length}>
                        {
                            myMissingClassTypes?.map(classType => {
                                return (
                                    <>
                                        <p style={{ textAlign: 'center', fontWeight: '700', color: '#a6a6a6' }}>{t('membersChildrenItem.wouldYouLikeSpecific', 'Would you like to join a {{classType}} class?', { classType: classType.name })}</p>
                                        <Button className={`members-children-item-child-find-class-buttons-${classType.name.split(' ')[0].toLocaleLowerCase()}`} onClick={() => navigate(`/my/class-finder/${model?.id}?classType=${encodeURIComponent(classType.id)}`)}>{t('membersChildrenItem.findClass', 'Find a {{classType}} class', { classType: classType.name })}</Button>
                                    </>
                                );
                            })
                        }
                    </ConditionalFragment>
                </div>

                {/* Do we have another class */}
                <ConditionalFragment showIf={!!nextClassesInOrder?.length && nextClassesInOrder?.length === 2}>
                    <div className="members-children-item-child-first-class">
                        <h5 className="members-children-item-child-first-class-header">{t('membersChildrenItem.bookedForMusicCubs', 'Booked for {{classType}}', { classType: classTypes?.find(it => it.id === nextClassesInOrder[1]?.classTypeId)?.name ?? '' })} <span className="members-children-item-child-first-class-header-term">{terms?.find(it => it.id === nextClassesInOrder[1]?.termId)?.name}</span></h5>
                        <p className="members-children-item-child-first-class-details">
                            {getScheduledClassSummary(nextClassesInOrder[1], { classLocation: nextClassesInOrder[1]?.classLocation })}
                        </p>
                        <p className="members-children-item-child-first-class-stage">{classStages?.find(it => it.id === nextClassesInOrder[1]?.currentClassStageId)?.name} {classSubStages?.find(it => it.id === nextClassesInOrder[1]?.currentClassSubStageId)?.name}</p>
                        <p className="members-children-item-child-first-class-next">
                            {t('membersChildrenItem.nextClass', 'Next class is on {{date, Do MMMM YYYY}}', { date: moment(getNextClassDate(nextClassesInOrder[1])) })}
                        </p>
                    </div>
                </ConditionalFragment>

                <HorizontalRule />

                {/* Booked Class Component - Swim/Music */}
                <div className="members-children-item-child-manage">
                    <ConditionalFragment showIf={showManageButton}>
                        <LinkContainer to={`/my/children/details/${model?.id}?tab=classes`}>
                            <Button color="primary">
                                {t('membersChildrenItem.view.button', 'Manage classes')}
                            </Button>
                        </LinkContainer>
                    </ConditionalFragment>

                    <LinkContainer to={`/my/children/${model?.id}`}>
                        <Button color="primary" outline>
                            {t('membersChildrenItem.messageSupport.button', 'Edit Bear Cub')}
                        </Button>
                    </LinkContainer>
                </div>

                <ConditionalFragment showIf={messageModalIsOpen}>
                    <StyledModal
                        isOpen={messageModalIsOpen}
                        toggle={toggleMessageModal}
                        size="lg"
                    >
                        <MessageModal
                            toggle={toggleMessageModal}
                        />
                    </StyledModal>
                </ConditionalFragment>
            </div >
        </>
    );
};;