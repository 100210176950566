import './layout.scss';
import * as React from 'react';
import { Header } from './header/Header';
import { Footer } from './footer/Footer';
import { Sidebar } from './sidebar/Sidebar';
import { LoadingIndicator } from '../../components/shared/loadingIndicator/LoadingIndicator';
import { RouteEntry } from '../../shared/routes';
import { BodyBackground } from './bodyBackground/BodyBackground';

export interface LayoutProps {
    appRoutes: Array<RouteEntry>,
    children: React.ReactNode
}

/**
 * Overall site layout.
 * @param props
 */
export const Layout = (props: LayoutProps) => {
    return (
        <div className="layout">
            <BodyBackground />
            <Header />
            <div className="layout-body">
                <Sidebar />
                <main className="main">
                    <React.Suspense fallback={<LoadingIndicator />}>
                        {props.children}
                    </React.Suspense>
                </main>
            </div>
            <Footer />
        </div>
    );
};
