import * as React from 'react';
import { useLazyQuery } from "@apollo/client";
import { gql } from 'graphql-tag';
import { Guid } from "guid-string";
import { mainApiConfig } from "../../../configure/mainApiConfig";
import { TermForAnonymousUserQuery, TermForAnonymousUserQueryVariables } from '../generated/graphql';
import { termFieldsFragment } from "../models/Term";
import { AsyncLoadOptions, AsyncLoadResult } from '../../../shared/abstractStore';

/**
 * Load a Term from the api.
 */
export function useTermForAnonymousUser(id: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<TermForAnonymousUserQuery> {
    const [load, { data, loading, error, refetch }] = useLazyQuery<TermForAnonymousUserQuery, TermForAnonymousUserQueryVariables>(
        gql`
        query termForAnonymousUserQuery ($id: ID!) {
            model: termForAnonymousUser(id: $id) {
                ...termFields
            }
        }

        ${termFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}