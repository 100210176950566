import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Alert, Button, Col, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useProcessChildAbsenceMutation } from "../../../api/main/childAbsences/useProcessChildAbsenceMutation";
import { useSaveChildAbsenceMutation } from "../../../api/main/childAbsences/useSaveChildAbsenceMutation";
import { ChildAbsence, childAbsenceDefaultValues } from "../../../api/main/models/ChildAbsence";
import { ClassLocation } from "../../../api/main/models/ClassLocation";
import { ScheduledClass } from "../../../api/main/models/ScheduledClass";
import { useConsumeScheduledClassToAddHolidayOrSickness } from "../../../api/main/scheduledClasses/viewModels/useConsumeScheduledClassToAddHolidayOrSickness";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { useChanges } from "../../../shared/useChanges";
import { getScheduledClassSummary } from "../../scheduledClass/getScheduledClassSummary";
import { Banner } from "../../shared/banner/Banner";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../../shared/stickyToolbar/StickyToolbar";

interface ScheduledClassWithLessonDates extends ScheduledClass {
    lessonDatesForCurrentTerm: Array<string> | undefined | null;
    classLocation: ClassLocation | undefined;
    currentClassStageId: string | undefined | null;
}

/**
 * Component that allows a Member to record an absence against a Child and a ScheduledClass
 */
export const AddHolidayOrSickness = () => {
    const { t } = useTranslation();
    const {
        scheduledClassId,
        childId,
    } = useParams<{ scheduledClassId: string | undefined, childId: string | undefined, }>();
    const navigate = useNavigate();

    // Load the data
    const {
        data: {
            model: _scheduledClass,
            child,
            childAbsenceTypes,
            classStages,
            classSubStages
        }, isLoading, errors: loadErrors
    } = useConsumeScheduledClassToAddHolidayOrSickness(scheduledClassId, childId);

    const scheduledClass = _scheduledClass as ScheduledClassWithLessonDates;


    // Model (ChildAbsence)
    const { model, change, changes } = useChanges({} as ChildAbsence, { ...childAbsenceDefaultValues(), childId: childId, scheduledClassId: scheduledClassId });
    const [saveChildAbsence, { errors: saveErrors }] = useSaveChildAbsenceMutation();

    // Model (CourtesyClassCredits) - We will first find all dates the class will be running, within the range of the absence, taking into account any termHolidays
    const [processChildAbsence] = useProcessChildAbsenceMutation();

    // Main model validation
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            startDate: () => !model?.startDate ? t('addHolidayOrSickness.errors.startDate', 'Start date is required') : '',
            endDate: () => !model?.endDate ? t('addHolidayOrSickness.errors.endDate', 'End date is required') : '',
            childAbsenceTypeId: () => !model?.childAbsenceTypeId ? t('addHolidayOrSickness.errors.childAbsenceType', 'Absence type is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Save everything
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        // Set the submitted date
        changes.submittedDate = moment();

        // Save the main model.
        await saveChildAbsence(model.id, { ...changes }, true);

        // Process the CourtesyClassCredits
        await processChildAbsence(model.id);

        // Return to the previous page.
        navigate(`/my/children/details/${childId}?tab=courtesyClassCredits`);
    }, [model, changes, navigate]);

    //const firstLessonDate = useMemo(() => {
    //    if (scheduledClass?.lessonDatesForCurrentTerm && scheduledClass.lessonDatesForCurrentTerm.length > 0) {
    //        return scheduledClass.lessonDatesForCurrentTerm[0];
    //    }
    //    return '';
    //}, [scheduledClass?.lessonDatesForCurrentTerm]);

    //const lastLessonDate = useMemo(() => {
    //    if (scheduledClass?.lessonDatesForCurrentTerm && scheduledClass.lessonDatesForCurrentTerm.length > 0) {
    //        return scheduledClass.lessonDatesForCurrentTerm[scheduledClass.lessonDatesForCurrentTerm.length - 1];
    //    }
    //    return '';
    //}, [scheduledClass?.lessonDatesForCurrentTerm]);

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>{t('addHolidayOrSickness.title', 'Holidays and sickness')}</h1>
                            <h3>{t('common.fullName', '{{firstName}} {{lastName}}', { firstName: child?.firstName, lastName: child?.lastName })}</h3>
                            <h3>{getScheduledClassSummary(scheduledClass, { classLocation: scheduledClass?.classLocation, classStage: classStages?.find(it => it.id === scheduledClass?.currentClassStageId), classSubStage: classSubStages?.find(it => it.id === scheduledClass?.currentClassSubStageId) })}</h3>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors
                    errors={[
                        loadErrors,
                        saveErrors,
                        saveFormErrors
                    ]}
                />

                <Alert color="success">
                    {t('addHolidayOrSickness.information.text', 'Let us know if your bear cub is going to miss a class, you can make up 2 classes in a term. An absence reported more than 24 hours before the start of the class will be issued a courtesy class. Credits can not be carried over to subsequent terms, and must be used before the term ends.')}
                </Alert>

                {/*<ConditionalFragment showIf={model?.startDate === firstLessonDate}>*/}
                {/*    <Alert color="warning">*/}
                {/*        {t('addHolidayOrSickness.firstLessonOfTerm', 'Courtesy class credits are not issued for the first lesson of the term. You can still log an absence for these dates.')}*/}
                {/*    </Alert>*/}
                {/*</ConditionalFragment>*/}

                {/*<ConditionalFragment showIf={model?.startDate === lastLessonDate}>*/}
                {/*    <Alert color="warning">*/}
                {/*        {t('addHolidayOrSickness.lastLessonOfTerm', 'Courtesy class credits are not issued for the last lesson of the term. You can still log an absence for these dates.')}*/}
                {/*    </Alert>*/}
                {/*</ConditionalFragment>*/}

                <Form onSubmit={e => { e.preventDefault(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="lessonDate">{t('addHolidayOrSickness.lessonDate.label', 'Lesson date')}</Label>
                                <Input type="select" value={model?.startDate ?? ''} onChange={e => change({ startDate: e.currentTarget.value, endDate: moment(e.currentTarget.value).add(1, 'days').toISOString() })}>
                                    <option value="">{t('addHolidayOrSickness.lessonDate.pleaseSelect', 'Please select a lesson date')}</option>
                                    {
                                        scheduledClass?.lessonDatesForCurrentTerm?.map(item => (
                                            <option key={item} value={item}>
                                                {moment(item).format('Do MMMM YYYY')}
                                            </option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="absenceType">{t('addHolidayOrSickness.absenceType.label', 'Absence type')}</Label>
                                <ValidatedInput
                                    name="absenceType"
                                    type="select"
                                    value={model?.childAbsenceTypeId ?? ''}
                                    onChange={e => change({ childAbsenceTypeId: e.currentTarget.value })}
                                    onBlur={e => validate('staffAbsenceTypeId')}
                                    validationErrors={validationErrors['staffAbsenceTypeId']}>
                                    {/* Options */}
                                    <option value="">{t('addHolidayOrSickness.absenceType.pleaseSelect', 'Please select an absence type')}</option>
                                    {
                                        childAbsenceTypes?.map(item => (

                                            <option key={item.id} value={item.id}>
                                                {t('addHolidayOrSickness.absenceType.value', '{{absenceTypeName}}', { absenceTypeName: item.name })}
                                            </option>
                                        ))
                                    }
                                </ValidatedInput>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <p className="text-center">{t('addHolidayOrSickness.warning.otherClasses', 'If {{firstName}} is registered in any other classes, you would need to record your absence against those too', { firstName: child?.firstName })}</p>
                        </Col>
                    </Row>

                    <ConditionalFragment showIf={!isLoading}>
                        <div className='text-center'>
                            <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                                executingChildren={<><Spinner size="sm" /> {t('common.submitting', 'Submitting request...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> </>
                                {t('addHolidayOrSickness.submitRequest.button', 'Submit request')}
                            </ButtonAsync>
                            <Button type="button" color="primary" outline onClick={e => navigate(-1)} style={{ marginLeft: '1rem' }}>
                                {t('common.cancel', 'Cancel')}
                            </Button>
                        </div>
                    </ConditionalFragment>
                </Form>
            </MainContainer>
        </>
    );
};