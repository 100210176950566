import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { useAsyncCallback } from "react-use-async-callback";
import { Alert, Badge, Button, Card, CardTitle, Col, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useToggleState } from "use-toggle-state";
import { PaymentState, paymentStateDisplayName } from "../../../../api/main/models/constants/PaymentState";
import { useLeaveScheduledClassMutation } from "../../../../api/main/scheduledClassChildren/useLeaveScheduledClassMutation";
import { AlertOnErrors } from "../../../../shared/alertOnErrors";
import { StyledModal } from "../../../shared/styledModal/StyledModal";
import { MessageModal } from "../../MessageModal";
import { PaymentWithDetails } from "./PaymentsTab";
import "./paymentsComponent.scss";

export interface PaymentComponentProps {
    payment: PaymentWithDetails | undefined | null;
    refresh: () => void;
}

/**
 * Component for displaying a single Payment for a Child
 */
export const PaymentComponent = (props: PaymentComponentProps) => {
    const { t } = useTranslation();
    const { payment, refresh } = props;

    // Handling toggling of the Send message modal
    const [messageModalIsOpen, _toggleMessageModal] = useToggleState();
    const toggleMessageModal = useCallback(() => {
        _toggleMessageModal();
    }, [_toggleMessageModal]);

    // Handling toggling of the Leave class modal
    const [leaveClassModalIsOpen, toggleLeaveClassModal] = useToggleState();

    // Get the type of payment
    const paymentType = useMemo(() => {
        if (!payment) return;

        // Payment name starts with either: 'Trial', 'Enrolment, or 'Re-enrolment'. So we can just split the name and get the first word.
        return payment.name.split(' ')[0];
    }, [payment]);

    // Leave a ScheduledClass
    const [leaveScheduledClassMutation, { errors: leaveScheduledClassMutationErrors }] = useLeaveScheduledClassMutation();
    const [leaveClass, { isExecuting: isLeavingClass, errors: leaveClassErrors }] = useAsyncCallback(async () => {
        if (!payment) {
            return;
        }

        // Leave the class
        await leaveScheduledClassMutation(payment?.scheduledClassChild?.id ?? '');

        // Close the modal
        refresh();
        toggleLeaveClassModal();
    }, [payment, leaveScheduledClassMutation]);

    const [isChangeClass, setIsChangeClass] = useState<boolean>(false);

    // Render the UI
    //
    return (
        <>
            <Card body className="payment-card text-center">
                <h3 className="payment-card-title">{payment?.term?.name} {paymentType}</h3>

                <Row>
                    <p className="payment-card-row">
                        <span className="payment-card-row-title">{t('common.bearCub.colon', 'Bear Cub:')}</span> {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: payment?.child?.firstName, lastName: payment?.child?.lastName })}
                    </p>
                </Row>

                <Row>
                    <p className="payment-card-row">
                        <span className="payment-card-row-title">{t('common.activity.colon', 'Activity:')}</span> {payment?.activity?.name}
                    </p>
                </Row>

                <Row>
                    <p className="payment-card-row">
                        <span className="payment-card-row-title">{t('common.classStage.colon', 'Class stage:')}</span> {payment?.classStage?.name} {payment?.classSubStage?.name}
                    </p>
                </Row>

                <Row>
                    <p className="payment-card-row">
                        <span className="payment-card-row-title">{t('common.classInfo.colon', 'Class info:')}</span> {t('common.time', '{{hours, 00}}:{{minutes, 00}}', { hours: payment?.scheduledClass?.startTimeHours, minutes: payment?.scheduledClass?.startTimeMinutes })} {payment?.location?.name}
                    </p>
                </Row>

                <Row>
                    <p className="payment-card-row">
                        <span className="payment-card-row-title">{t('common.termLength.colon', 'Term length:')}</span> {payment?.scheduledClass?.lessonsPerTerm} {t('common.weeks', 'weeks')}
                    </p>
                </Row>

                <Row>
                    <p className="payment-card-row">
                        <span className="payment-card-row-title">{t('common.cost.colon', 'Cost:')}</span>
                        <> </>
                        {t('common.euroCurrency', '€{{value}}', { value: payment?.totalAmountGross.toFixed(2) })}
                        <> </>

                        <ConditionalFragment showIf={payment?.paymentState === PaymentState.Paid}>
                            <Badge color="success" pill>
                                {paymentStateDisplayName(PaymentState.Paid, t)} on {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(payment?.paidDate) })}
                            </Badge>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={payment?.paymentState === PaymentState.Unpaid}>
                            <Badge color="warning" pill>
                                {paymentStateDisplayName(PaymentState.Unpaid, t)}
                            </Badge>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={payment?.paymentState === PaymentState.PartialPaid}>
                            <Badge color="warning" pill>
                                {paymentStateDisplayName(PaymentState.PartialPaid, t)}
                            </Badge>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={payment?.paymentState === PaymentState.Failed}>
                            <Badge color="danger" pill>
                                {paymentStateDisplayName(PaymentState.Failed, t)}
                            </Badge>
                        </ConditionalFragment>
                    </p>
                </Row>

                <Row className="payment-card-actions">
                    <Col xs={8} className="payment-card-actions-center">
                        {
                            payment?.paymentState === PaymentState.Paid ? (
                                <LinkContainer to={`/my/checkout-receipt/${payment?.id}`}>
                                    <Button color="success" outline>
                                        <FontAwesomeIcon icon="eye" />
                                        <> </>
                                        {t('common.viewPayment', 'View receipt')}
                                    </Button>
                                </LinkContainer>
                            ) : payment?.paymentState === PaymentState.Failed ? (
                                <LinkContainer to={`/my/checkout/${payment?.id}`}>
                                    <Button color="primary">
                                        <FontAwesomeIcon icon="credit-card" />
                                        <> </>
                                        {t('common.retryPayment', 'Retry payment')}
                                    </Button>
                                </LinkContainer>
                            ) : (
                                <>
                                    <Row>
                                        <Col xs={12}>
                                            <LinkContainer to={`/my/checkout/${payment?.id}`}>
                                                <Button color="primary">
                                                    <FontAwesomeIcon icon="credit-card" />
                                                    <> </>
                                                    {t('common.makePayment', 'Make payment')}
                                                </Button>
                                            </LinkContainer>
                                        </Col>

                                        <Col xs={12}>
                                            {/* This button should open a modal to confirm this action */}
                                            <Button color="danger" style={{ marginTop: '1rem' }} onClick={() => toggleLeaveClassModal()}>
                                                <FontAwesomeIcon icon="xmark" />
                                                <> </>
                                                {t('common.leaveClass', 'Leave class')}
                                            </Button>
                                        </Col>
                                        <ConditionalFragment showIf={!!payment?.name.startsWith("Re-enrolment") && payment?.paymentState === PaymentState.Paid}>
                                            <Col xs={12}>
                                                <> </>
                                                <Button color="secondary" style={{ marginTop: '1rem' }} onClick={() => { setIsChangeClass(true); toggleMessageModal(); }}>
                                                    {t('common.classChangeRequest', 'Class change request')}
                                                </Button>
                                            </Col>
                                        </ConditionalFragment>
                                    </Row>
                                </>
                            )
                        }
                    </Col>
                </Row>


                <Row className="payment-card-support">
                    <Col xs={8} className="payment-card-support-center">
                        <Button color="primary" outline onClick={() => toggleMessageModal()}>
                            <FontAwesomeIcon icon="comment" />
                            <> </>
                            {t('common.messageSupport', 'Message support')}
                        </Button>
                    </Col>
                </Row>
            </Card>

            <ConditionalFragment showIf={leaveClassModalIsOpen}>
                <StyledModal
                    isOpen={leaveClassModalIsOpen}
                    toggle={() => toggleLeaveClassModal()}
                    size="lg">
                    <ModalHeader toggle={() => toggleLeaveClassModal()}>
                        <CardTitle tag="h1">
                            {t('paymentComponent.modal.leaveClass', 'Leave class')}
                        </CardTitle>
                    </ModalHeader>

                    <ModalBody>
                        <AlertOnErrors errors={[leaveScheduledClassMutationErrors, leaveClassErrors]} />

                        <Alert color="warning">
                            {t('paymentComponent.modal.warning', 'Click "leave class" to cancel your class booking. This action will remove you from the class list and offer your space to someone else on the waitlist.')}
                        </Alert>

                        {payment?.name}
                    </ModalBody>

                    <ModalFooter>
                        <ButtonAsync color="danger" isExecuting={isLeavingClass} onClick={() => leaveClass()}
                            executingChildren={<><Spinner size="sm" /> {t('common.sendingMessage', 'Sending message...')}</>}>
                            <FontAwesomeIcon icon="xmark" />
                            <> </>
                            {t('paymentComponent.leaveClass.button', 'Leave class')}
                        </ButtonAsync>
                        <> </>
                        <Button color="primary" outline onClick={e => toggleLeaveClassModal()}>
                            {t('common.close', 'Close')}
                        </Button>
                    </ModalFooter>
                </StyledModal>
            </ConditionalFragment>

            <ConditionalFragment showIf={messageModalIsOpen}>
                <StyledModal
                    isOpen={messageModalIsOpen}
                    toggle={toggleMessageModal}
                    size="lg"
                >
                    <MessageModal
                        toggle={toggleMessageModal}
                        isChangeClass={isChangeClass}
                    />
                </StyledModal>
            </ConditionalFragment>
        </>
    );
};