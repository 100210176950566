import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "reactstrap";
import { usePreferredListViewMode } from "../../../globalState/preferredListViewMode/usePreferredListViewMode";

/**
 * Component that lets the user toggle between cards or table mode.
 */
export const CardsOrTableToggle = () => {
    const [viewMode, setViewMode] = usePreferredListViewMode();

    const { t } = useTranslation();

    return (
        <ButtonGroup>
            <Button color="secondary" outline={viewMode !== 'cards'} onClick={() => setViewMode('cards')}>
                <FontAwesomeIcon icon="th-large" />
                <span className="visually-hidden">{t('common.cards', 'Cards')}</span>
            </Button>
            <Button color="secondary" outline={viewMode !== 'table'} onClick={() => setViewMode('table')}>
                <FontAwesomeIcon icon="th-list" />
                <span className="visually-hidden">{t('common.list', 'List')}</span>
            </Button>
        </ButtonGroup>
        );
};