import * as React from "react";
import {  AsyncLoadResult, AsyncLoadOptions } from "../../shared/abstractStore";
import { useAsyncCallback } from "react-use-async-callback";
import { CompleteExternalLoginResult } from "./AccountService";
import { useAccountService } from "./useAccountService";

/**
 * Details of the external login that has just completed and invoked our callback.
 */
export function useCompletedExternalLoginDetails(options: AsyncLoadOptions = {}): AsyncLoadResult<CompleteExternalLoginResult> {
    const [data, setData] = React.useState<CompleteExternalLoginResult>({ claims: [], isNewUser: false, loginProvider: '', returnUrl: '' });

    const accountService = useAccountService();

    // Get the details from the external login off the query string.
    const { returnUrl, remoteError } = React.useMemo(() => {
        const params = new URLSearchParams(window.location.search);
        const returnUrl = params.get('returnUrl') ?? params.get('ReturnUrl') ?? '';
        const remoteError = params.get('remoteError') ?? params.get('RemoteError') ?? '';
        return { returnUrl, remoteError};
    }, []);

    const [refresh, { isExecuting: isLoading, errors }] = useAsyncCallback(async () => {
        let result = await accountService.completeExternalLogin(returnUrl, remoteError);
        setData(result);
    }, [accountService, returnUrl, remoteError, setData]);

    React.useEffect(() => {
        if (!options.lazy) {
            refresh();
        }
    }, [refresh, options.lazy]);

    // TODO handle options.pollInterval.

    return {
        data: data,
        refresh: refresh,
        isLoading: isLoading,
        errors: errors
    };
}
