import { Container, ContainerProps } from 'reactstrap';
import './mainContainer.scss'; 

export interface MainContainerProps extends ContainerProps {
    color?: 'main' | 'transparent',
    centerChildren?: 'none' | 'vertically' | 'horizontally',
}

/**
 * Main container that ensures 
 */
export const MainContainer = (props: MainContainerProps) => {
    const {
        className,
        children,
        color = 'main',
        centerChildren = 'none',
        ...rest } = props;

    return (
        <Container className={`main-container main-container-${color} main-container-${centerChildren} ${className ?? ''}`} {...rest}>
            {children}
        </Container>
    );
};